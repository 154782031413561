import MobileMenu from 'components/UI/MobileMenu';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import './appBodyScroll.scss';
import './applayout.scss';

const AppWapper = ({
  children,
  className = '',
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  useEffect(() => {
    document.getElementById('root')?.classList.add(className);
    return () => {
      document.getElementById('root')?.classList.remove(className);
    };
  }, []);

  return (
    <div className={`main-container h-100 ${className}`}>
      <div className='app_layout h-100'>
        <ResponsiveLayout width={991}>
          <SidebarMenu />
        </ResponsiveLayout>
        {children}
        <ResponsiveLayout width={991} isMobile>
          <MobileMenu />
        </ResponsiveLayout>
      </div>
    </div>
  );
};
const AppLayout = ({ className }: { className?: string }) => {
  return (
    <AppWapper className={className}>
      <div className='app-right-content'>
        <Outlet />
      </div>
    </AppWapper>
  );
};

export default AppLayout;
