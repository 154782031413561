// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Image from 'components/Image';
import VideoPlay from 'components/Players/VideoPlayer';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Swiper } from 'swiper';
import { Navigation, Zoom } from 'swiper/modules';

import { v4 } from 'uuid';
// import Swiper and modules styles
import { LOCAL_IMAGES, MEDIA_CROP_SIZES } from 'appconstants';
import { ChevronLeftIcon, ChevronRightIcon, Mp3Icon, PlayCircle, ResumeCircle } from 'assets/svgs';
import classNames from 'classnames';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import './swiperslider.scss';
// import 'swiper/css/pagination';
// import './swiperslider.scss';
// export type ISlideItem = {
//   url: string;
//   width?: number;
//   height?: number;
//   fallbackUrl?: string;
//   fallbackComponent?: ReactNode;
//   id?: number;
//   duration?: number;
//   name?: string;
//   type?: string;
//   thumb?: string;
// };
export type ISlideItem = Partial<IPost>;
export type ISwiperSliderProps<T> = {
  slides: T[];
  renderItem?: (slideItem?: T) => void;
  className?: string;
  sliderId?: string;
  initializeSlides: number;
  setInitialSlide?: React.Dispatch<React.SetStateAction<number>>;
  onClose?: (event?: string) => void;
  setCurrentSlide?: (e: number) => void;
  mediaUser?: IMediaUser | undefined;
};
const SwiperSlider = (props: ISwiperSliderProps<ISlideItem>, ref: any) => {
  const {
    onClose,
    initializeSlides,
    setInitialSlide,

    slides = [],
    renderItem,
    className,
    sliderId,
    mediaUser,
  } = props;
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const swiperElRef = useRef<Swiper>(null);
  const isMounted = useRef<boolean>(false);
  const initialize = useRef(false);
  const sliderIdRef = useRef(sliderId || v4());
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      swiperElRef.current = new Swiper('.myswiper', {
        modules: [Navigation, Zoom],
        direction: 'horizontal',
        loop: true,
        zoom: true,
        slidesPerView: 1,
        initialSlide: initializeSlides,
        virtual: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },

        on: {
          destroy(swiper) {
            initialize.current = false;
          },
          slideChange(swiper) {
            const sliderId = sliderIdRef.current;
            const swipercontainer = document.querySelector(`#slider_id${sliderId}`);
            const pIndex = swiper.previousRealIndex;
            if (swipercontainer) {
              const prevslide = swipercontainer.querySelector(`#slider_${pIndex}__${sliderId}`);
              const audioel = prevslide?.querySelector('audio');
              const videoel = prevslide?.querySelector('video');
              if (audioel) {
                audioel.pause();
              }
              if (videoel) {
                videoel.pause();
              }
            }
          },
        },
      });

      initialize.current = true;
      return () => {
        if (swiperElRef && !isMounted.current) {
          swiperElRef.current?.destroy();
          initialize.current = false;
        }
      };
    }
  }, [slides]);

  const handleSeek = (e) => {
    if (audioRef.current) {
      const progressBar = e.currentTarget;
      const rect = progressBar.getBoundingClientRect();
      const offsetX = e.clientX - rect.left;
      const newTime = (offsetX / progressBar.clientWidth) * duration;

      audioRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };
  const audioReff = useCallback((audio) => {
    audioRef.current = audio;
    audio?.addEventListener('timeupdate', () => {
      console.log(audio.currentTime);
      setCurrentTime(audio.currentTime);
    });

    audio?.addEventListener('durationchange', () => {
      setDuration(audio.duration);
    });
  }, []);
  const getSlides = () => {
    const sliderId = sliderIdRef.current;
    return slides?.map((sl, index) => {
      // const isImage = attrAccept({ name: sl.name, type: sl.type }, 'image/*');
      // const isAudio = attrAccept({ name: sl.name, type: sl.type }, 'audio/*');
      // const isVideo = attrAccept({ name: sl.name, type: sl.type }, 'video/*');
      const {
        image_media_item,
        video_media_item,
        audio_media_item,
        media_type_id,
        image_width: wwidth = MEDIA_CROP_SIZES.POST.width,
        image_height: hheight = MEDIA_CROP_SIZES.POST.height,
        ...rest
      } = sl;
      const { url: thumb = LOCAL_IMAGES.suggestion_placeholder, name = 'gallery_image' } =
        image_media_item || {};

      const isImage = media_type_id === 'image';
      const isAudio = media_type_id === 'audio';
      const isVideo = media_type_id === 'video';
      if (renderItem) {
        return renderItem({ slide: sl, isImage, isVideo: false, isAudio: false });
      }
      if (isVideo) {
        const { url } = video_media_item;
        if (!url) return null;
        return (
          <div
            key={url + index}
            id={`slider_${index}__${sliderId}`}
            className='swiper-slide video_slide swipe-slide'
          >
            <div
              className='swiper_overlay'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClose?.();
                setInitialSlide(0);

                swiperElRef.current?.destroy();
              }}
            />
            <div className='swipe-slide-holder h-100vh'>
              <VideoPlay url={url} controls={false} />
            </div>
          </div>
        );
      }
      if (isAudio) {
        const { url, name = 'gallery_audio' } = audio_media_item;
        if (!url) return null;
        const player = audioRef?.current;
        const time = new Date((duration || 0) * 1000).toISOString().substr(11, 8) || 0;
        return (
          <div
            key={url + index}
            id={`slider_${index}__${sliderId}`}
            className='swiper-slide audio_slide swipe-slide'
          >
            <div
              className='swiper_overlay'
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClose?.();
                swiperElRef.current?.destroy();
              }}
            />
            <div className='swipe-slide-holder h-100vh'>
              <Image
                wrapperClasses='slider_img_placeholder'
                fallbackComponent={
                  <div className='audio-fall-back'>
                    <Mp3Icon data-item={name} />
                  </div>
                }
                filters={MEDIA_CROP_SIZES.POST}
                src={thumb}
                alt='assets/images/post1.png'
              />
              <audio src={url} controls className='audio_player' ref={audioReff} />
              <div className='audio_player_content position-absolute'>
                <div className='audio_player_content__wrap'>
                  <div className='audio_player_content__left'>
                    <div className='player_button'>
                      {!isPlaying ? (
                        <span
                          className='play_icon'
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            player?.play();
                            setIsPlaying(true);
                          }}
                        >
                          <PlayCircle />
                        </span>
                      ) : (
                        <span
                          className='play_icon'
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            player?.pause();
                            setIsPlaying(false);
                          }}
                        >
                          <ResumeCircle />
                        </span>
                      )}
                    </div>
                    <div className='audio_title'>
                      <span className='title'>{name}</span>
                      <span className='username'>{`${mediaUser?.first_name} ${mediaUser?.last_name}`}</span>
                    </div>
                  </div>
                  <span className='audio_player_content_duration'>{time}</span>
                </div>
                <div className='audio_player_bar' onClick={handleSeek}>
                  <div
                    className='audio_player_bar_progress'
                    style={{ width: `${(currentTime / duration) * 100}%` }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          key={thumb + index}
          id={`slider_${index}__${sliderId}`}
          className='swiper-slide image_slide swipe-slide'
        >
          <div
            className='swiper_overlay'
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setInitialSlide(0);

              onClose?.();
              swiperElRef.current?.destroy();
            }}
          />
          <div className='swipe-slide-holder h-100vh'>
            <Image
              imgWrapperClass='swiper-zoom-container'
              wrapperClasses='slider_img_placeholder'
              placeholderFilters={{
                height: 100,
              }}
              src={thumb}
              alt={name}
            />
          </div>
        </div>
      );
    });
  };
  return (
    <div className={`${classNames(className, 'myswiper')}`} id={`slider_id${sliderIdRef.current}`}>
      {/* <div className='swiper_actions'>
        <div className='swiper-close'>
          <CrossIcon
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              swiperElRef.current?.destroy();
              onClose?.();
              // setInitialSlide(initializeSlides);
            }}
          />
        </div>
      </div> */}

      <div className='swiper-wrapper'>{getSlides()}</div>

      {/* <div className='swiper-pagination'></div> */}

      <div className='swiper-button-prev swiper-button'>
        <ChevronLeftIcon />
      </div>
      <div className='swiper-button-next swiper-button'>
        <ChevronRightIcon />
      </div>
      {/* <div className='swiper-footer-logo'>
        <Image
          filters={MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300)}
          rounded
          src={mediaUser?.profile_thumb?.url}
          fallbackComponent={<AvatarNameIcon text={mediaUser?.first_name || 'Inco'} />}
          alt='/assets/images/defaultIcon.png'
        />
      </div> */}
    </div>
  );
};
export default forwardRef(SwiperSlider);
