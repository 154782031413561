import { BackIcon } from 'assets/svgs';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
import './headerlayout.scss';
type IHeaderProps = {
  children: any;
  isboth?: boolean;
  width?: number;
  className?: string;
  backUrl?: string | null;
};

const HeaderLayout = (props: IHeaderProps) => {
  const { children, isboth = false, width = 992, className, backUrl } = props;

  let conent: any = null;
  if (backUrl) {
    conent = (
      <div className='page-back-link'>
        <BackIcon />
      </div>
    );
  }
  const isMobileView = useMediaQuery({ query: `(max-width: ${width}px)` });
  if (isboth) {
    if (isMobileView) {
      return (
        <>
          {conent}
          <div className={classNames(`component_header`, className)}>
            <div className={`block-head mobile_header`}>{children}</div>
          </div>
        </>
      );
    }
    return (
      <>
        {conent}
        <div className={classNames(`component_header`, className)}>
          <div className={`block-head `}>{children}</div>
        </div>
      </>
    );
  }
  return (
    <>
      {conent}
      {children}
    </>
  );
};

type IDeviceHeaderProps = {
  children: ReactNode;
  width?: number;
  className?: string;
};
export const MobileHeaderView = (props: IDeviceHeaderProps) => {
  const { children, width = 991, className } = props;
  const isMobileView = useMediaQuery({ query: `(max-width: ${width}px)` });
  if (!isMobileView) {
    return null;
  }
  return (
    <div className={classNames(`component_header`, className)}>
      <div className={`block-head mobile_header`}>{children}</div>
    </div>
  );
};
export const DesktopHeaderView = (props: IDeviceHeaderProps) => {
  const { children, width = 991, className } = props;
  const isMobileView = useMediaQuery({ query: `(max-width: ${width}px)` });
  if (isMobileView) {
    return null;
  }
  return (
    <div className={classNames(`component_header`, className)}>
      <div className={`block-head `}>{children}</div>
    </div>
  );
};
export default HeaderLayout;
