import { SOCKET_URL } from 'config';
import { AuthStatus } from 'contexts/authContext';
import { SocketState } from 'enums';
import useAuthModule from 'hooks/useAuthModule';
import useSocket from 'hooks/useSocket';
import { useEffect } from 'react';
import { useSocketStore } from 'store/stores/socket';
const SocketRc = () => {
  const { connect, disconnect } = useSocket();
  const socketState = useSocketStore.useState();

  const auth = useAuthModule();
  useEffect(() => {
    if (socketState !== SocketState.Open && auth?.authStatus === AuthStatus.SignedIn) {
      connect(SOCKET_URL!);
    }
    return () => {
      disconnect();
    };
  }, [auth?.authStatus]);
  return null;
};

export default SocketRc;
