import { getUser } from 'utils';
import { getSession } from './cognito';

export default class AppSocket {
  static socket: WebSocket | null = null;
  static loggedIn = false;
  static connect(
    host: string,
    onOpen: (e: Event) => void,
    onMessage: (e: MessageEvent) => void,
    onClose: (e: CloseEvent) => void,
  ) {
    if (this.socket?.readyState === WebSocket.OPEN) {
      this.socket.close();
    }

    this.socket = new WebSocket(host);
    let interval: NodeJS.Timeout;
    this.socket.onerror = (e) => {
      console.log('Socket error', e);
    };
    this.socket.onopen = (e) => {
      if (this.socket) {
        this.socket.onmessage = (e) => {
          onMessage(e);
        };
      }
      AppSocket.login();
      interval = setInterval(() => {
        if (this.socket) {
          this.socket.send(
            JSON.stringify({
              action: 'agent-ping',
              metadata: {
                user_id: getUser()?.id,
              },
            }),
          );
        }
      }, 100000);

      onOpen(e);
    };
    this.socket.onclose = (e) => {
      console.log('Socket closed', e);
      this.socket = null;
      onClose(e);
      clearInterval(interval);
    };
  }
  static async login(u: Partial<IUser> = {}) {
    const user = getUser() || u;
    // console.log(
    //   !this.loggedIn,
    //   user.slug,
    //   this.socket,
    //   this.socket?.readyState === WebSocket.OPEN
    // );
    const isIdExist = typeof user.id != null;
    if (!this.loggedIn && isIdExist && this.socket && this.socket?.readyState === WebSocket.OPEN) {
      const session = await getSession();
      this.loggedIn = true;
      this.socket.send(
        JSON.stringify({
          action: 'auth',
          // metadata: {
          // user_id: getUser()?.id,
          access_token: session?.getAccessToken()?.getJwtToken(),
          // },
        }),
      );
    }
  }

  static disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  static send(message: string) {
    if (this.socket) {
      this.socket.send(message);
    }
  }
}
