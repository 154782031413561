const TwitterIcon = (props: IConProps) => {
  const { width = 22, height = 22, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.24156 11.4016L0 0.916672H6.5279L11.6153 7.39705L17.0505 0.94585H20.6457L13.3537 9.61131L22 20.625H15.4916L9.98292 13.6169L4.10186 20.6056H0.487157L8.24156 11.4016ZM16.4402 18.6823L4.00269 2.85934H5.57852L18.0004 18.6823H16.4402Z'
        fill={stroke}
      />
    </svg>
  );
};

export default TwitterIcon;
