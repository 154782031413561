import {
  AuthenticationDetails,
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ICognitoUserAttributeData,
  ICognitoUserPoolData,
} from 'amazon-cognito-identity-js';
import { CLIENT_ID, USER_POOL_ID } from 'config';
import request from './request';

const poolData: ICognitoUserPoolData = {
  UserPoolId: `${USER_POOL_ID}`,
  ClientId: `${CLIENT_ID}`,
};
interface QueryParams {
  [key: string]: string;
}
const userPool: CognitoUserPool = new CognitoUserPool(poolData);
let currentUser = userPool.getCurrentUser();

export function getCurrentUser() {
  return currentUser;
}

export function getCognitoUser(username: string) {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new CognitoUser(userData);

  return cognitoUser;
}

export async function getSession() {
  if (!currentUser) {
    currentUser = userPool.getCurrentUser();
  }

  return new Promise<CognitoUserSession>(function (resolve, reject) {
    if (!currentUser) reject('No Session');
    currentUser?.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err) {
        reject(err);
      } else {
        resolve(session);
      }
    });
  }).catch((err: Error) => {
    throw err;
  });
}
export const setSection = async (
  section: {
    AccessToken: string;
    ExpiresIn: number;
    TokenType: string;
    RefreshToken: string;
    IdToken: string;
  },
  userData: { email: string },
) => {
  const cognitoIdToken = new CognitoIdToken({
    IdToken: section.IdToken,
  });
  const cognitoAccessToken = new CognitoAccessToken({
    AccessToken: section.AccessToken,
  });
  const cognitoRefreshToken = new CognitoRefreshToken({
    RefreshToken: section.RefreshToken,
  });
  const user = new CognitoUser({
    Username: userData?.email,
    Pool: userPool,
  });
  await user?.setSignInUserSession(
    new CognitoUserSession({
      AccessToken: cognitoAccessToken,
      IdToken: cognitoIdToken,
      RefreshToken: cognitoRefreshToken,
    }),
  );
};
export async function signUpUserWithEmail(
  username: string,
  email: string,
  submission_params: QueryParams,
) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ];
    request('/signup', {
      method: 'POST',
      data: {
        user_name: username,
        email,
        instant_access: true,
        submission_params,
      },
      public: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
    // userPool.signUp(username, password, attributeList, [], function (err, res) {
    //   if (err) {
    //     reject(err);
    //   } else {
    //     resolve(res);
    //   }
    // });
  }).catch((err) => {
    throw err;
  });
}
export async function signUpUserWithEmailOnly(email: string) {
  return new Promise(function (resolve, reject) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ];
    request('/signup', {
      method: 'POST',
      data: {
        email,
      },
      public: true,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
    // userPool.signUp(username, password, attributeList, [], function (err, res) {
    //   if (err) {
    //     reject(err);
    //   } else {
    //     resolve(res);
    //   }
    // });
  }).catch((err) => {
    throw err;
  });
}

export async function verifyCode(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function signInWithEmail(username: string, password: string) {
  return new Promise(function (resolve, reject) {
    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    currentUser = getCognitoUser(username);

    currentUser.authenticateUser(authenticationDetails, {
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function signOut(callback = () => {}) {
  if (currentUser) {
    currentUser.signOut(callback);
  }
}

export async function getAttributes() {
  return new Promise<CognitoUserAttribute[] | undefined>(function (resolve, reject) {
    currentUser?.getUserAttributes(function (err, attributes) {
      if (err) {
        reject(err);
      } else {
        resolve(attributes);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function setAttribute(attribute: ICognitoUserAttributeData) {
  return new Promise(function (resolve, reject) {
    const attributeList = [];
    const res = new CognitoUserAttribute(attribute);
    attributeList.push(res);

    currentUser?.updateAttributes(attributeList, (err, res) => {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  }).catch((err) => {
    throw err;
  });
}

export async function sendCode(username: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.forgotPassword({
      onSuccess: function (res) {
        resolve(res);
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  }).catch((err) => {
    throw err;
  });
}

export async function forgotPassword(username: string, code: string, password: string) {
  return new Promise(function (resolve, reject) {
    const cognitoUser = getCognitoUser(username);

    if (!cognitoUser) {
      reject(`could not find ${username}`);
      return;
    }

    cognitoUser.confirmPassword(code, password, {
      onSuccess: function () {
        resolve('password updated');
      },
      onFailure: function (err) {
        reject(err);
      },
    });
  });
}

export async function changePassword(oldPassword: string, newPassword: string) {
  return new Promise(function (resolve, reject) {
    currentUser?.changePassword(oldPassword, newPassword, function (err: any, res: any) {
      if (err) {
        reject(err);
      } else {
        resolve(res);
      }
    });
  });
}
export async function verifyAttribute(attributename: string, code: any) {
  return new Promise(function (resolve, reject) {
    currentUser?.verifyAttribute(attributename, code, {
      onSuccess: (success) => resolve(success),
      onFailure(err) {
        reject(err);
      },
    });
  });
}
