const ChevronLeftIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g>
        <path
          d='M15.6475 19.7647L9.88281 14'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.6475 8.23523L9.88281 13.9999'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default ChevronLeftIcon;
