const SubscriptionsIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g>
        <path
          fill={stroke}
          d='M18.541,8.166c0-1.48-0.576-2.872-1.623-3.918C15.872,3.201,14.48,2.625,13,2.625s-2.872,0.576-3.918,1.623
		C8.035,5.295,7.459,6.687,7.459,8.166c0,1.48,0.576,2.872,1.623,3.918c1.046,1.047,2.438,1.624,3.918,1.624
		s2.871-0.576,3.917-1.623C17.964,11.039,18.541,9.647,18.541,8.166z M15.68,10.847c-1.431,1.433-3.928,1.433-5.361,0
		c-0.715-0.716-1.11-1.668-1.11-2.681s0.394-1.965,1.11-2.681c0.716-0.715,1.668-1.11,2.681-1.11c1.012,0,1.964,0.395,2.682,1.11
		c0.715,0.716,1.109,1.668,1.109,2.681S16.396,10.131,15.68,10.847z'
        />
        <path
          fill={stroke}
          d='M11.249,16.625h-0.583c-1.479,0-2.871,0.576-3.918,1.623c-1.032,1.031-1.623,2.46-1.623,3.918V24.5
		c0,0.482,0.392,0.875,0.875,0.875s0.875-0.393,0.875-0.875v-2.334c0-0.998,0.404-1.975,1.11-2.68
		c0.717-0.717,1.669-1.111,2.681-1.111h0.583c0.483,0,0.875-0.393,0.875-0.875S11.732,16.625,11.249,16.625z'
        />
        <path
          fill={stroke}
          d='M25.279,18.73c-0.176-0.414-0.428-0.785-0.754-1.104c-1.195-1.168-3.194-1.296-4.521-0.306
		c-0.601-0.449-1.323-0.693-2.089-0.695c-0.913,0-1.777,0.35-2.432,0.984c-0.325,0.316-0.58,0.688-0.758,1.105
		c-0.178,0.417-0.268,0.858-0.269,1.311c-0.001,0.453,0.087,0.895,0.265,1.315c0.176,0.418,0.43,0.791,0.75,1.104l3.914,3.845
		c0.17,0.167,0.391,0.251,0.613,0.251c0.221,0,0.441-0.083,0.613-0.251l3.904-3.827c0.323-0.314,0.578-0.686,0.758-1.104
		c0.176-0.416,0.267-0.858,0.268-1.313C25.544,19.596,25.456,19.154,25.279,18.73z M23.664,20.674
		c-0.087,0.203-0.21,0.383-0.368,0.537l-3.295,3.229l-3.304-3.244c-0.156-0.152-0.278-0.332-0.363-0.532
		c-0.084-0.202-0.127-0.414-0.126-0.632c0.001-0.219,0.044-0.434,0.129-0.633c0.085-0.2,0.208-0.381,0.365-0.532
		c0.325-0.316,0.758-0.491,1.214-0.491c0.456,0.001,0.889,0.178,1.211,0.494l0.26,0.256c0.342,0.335,0.885,0.336,1.227,0.003
		l0.261-0.255c0.651-0.629,1.774-0.634,2.427,0.003c0.158,0.155,0.281,0.336,0.364,0.533c0.085,0.202,0.128,0.416,0.127,0.633
		C23.791,20.264,23.748,20.477,23.664,20.674z'
        />
      </g>
    </svg>
  );
};

export default SubscriptionsIcon;
