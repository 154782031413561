import { LOCAL_IMAGES, MEDIA_CROP_SIZES } from 'appconstants';
import './postmedia.scss';

import { Mp3Icon, OnlyClonesFullLogo, PlayIcon } from 'assets/svgs';

import Image from 'components/Image';
import { isMediaofType } from 'utils';

type Props = {
  post?: any;
  onMediaClick?: (e: any) => void;
  filters?: imageEditorProps;
};

import { useEffect, useRef, useState } from 'react';

function useResponsiveImageDimensions(width = 640, height = 360) {
  const postrEf = useRef<HTMLDivElement>(null);
  const originalWidth = width;
  const originalHeight = height;
  const aspectRatio = originalWidth / originalHeight;
  const computeDimensions = (windowWidth: number) => {
    let newWidth;
    // if (windowWidth >= 992 && windowWidth <= 1040) {
    //   newWidth = postrEf.current?.clientWidth || 640;
    // } else
    if (windowWidth > 640) {
      newWidth = postrEf.current?.clientWidth || 640;
    } else {
      newWidth = postrEf.current?.clientWidth || windowWidth; // Full width for sizes < 991
    }
    const newHeight = newWidth / aspectRatio;
    return {
      width: newWidth,
      height: newHeight,
    };
  };

  const [dimensions, setDimensions] = useState(computeDimensions(window.innerWidth));

  useEffect(() => {
    const handleResize = () => {
      setDimensions(computeDimensions(window.innerWidth));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { ...dimensions, postrEf };
}

const Index = (props: Props) => {
  const { filters } = props;
  const postDims = { ...MEDIA_CROP_SIZES.POST };
  const postFilters = {
    width: filters?.width || postDims.width,
    height: filters?.height || postDims.height,
  };

  const {
    media_type_id: type = '',
    image_media_item: img,
    image_width: wwidth = postFilters.width || 640,
    image_height: hheight = postFilters.height || 360,
  } = props?.post || {};

  const {
    height = MEDIA_CROP_SIZES.POST.height,
    width,
    postrEf,
  } = useResponsiveImageDimensions(wwidth || postFilters.width, hheight || postFilters.height);
  const getMedia = () => {
    const filterss = {
      width: filters?.width || wwidth || MEDIA_CROP_SIZES.POST.width,
      height: filters?.height || hheight || MEDIA_CROP_SIZES.POST.height,
    };

    if (isMediaofType({ name: img?.name, type: type }, 'image/*')) {
      return (
        <div className='thumb image_wrapper' key={img?.id}>
          <Image
            filters={filterss}
            src={img?.url}
            alt={img?.name}
            // fallbackUrl={LOCAL_IMAGES.suggestion_placeholder}
            fallbackComponent={
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <OnlyClonesFullLogo />
              </div>
            }
            height={filters?.height || height}
            width={filters?.width || width}
          />
        </div>
      );
    }
    if (isMediaofType({ name: img?.name, type: type }, 'video/*')) {
      return (
        <div className='thumb video_thumb_wrapper' key={img?.id}>
          <div className='post_play_btn'>
            <PlayIcon />
          </div>
          <Image
            filters={filterss}
            src={img?.url}
            alt={img?.name}
            fallbackUrl={LOCAL_IMAGES.suggestion_placeholder}
            height={filters?.height || height}
            width={filters?.width || width}
          />
        </div>
      );
    }
    return (
      <div className='thumb video_thumb_wrapper' key={img?.id}>
        <div className='post_play_btn'>
          <PlayIcon />
        </div>
        <div className='audio_thumb'>
          <span className={`img-audio ${!img?.url ? 'audio_fallback' : ''}`} data-item={img?.name}>
            <Image
              filters={filterss}
              src={img?.url}
              alt={img?.name}
              fallbackComponent={<Mp3Icon data-item={img?.name} />}
              height={filters?.height || height}
              width={filters?.width || width}
            />
          </span>
        </div>

        {/* <Image src={img?.url} alt={img?.name} /> */}
      </div>
    );
  };
  return (
    <div className='post_media' onClick={props.onMediaClick} ref={postrEf}>
      {getMedia()}
    </div>
  );
};

export default Index;
