import { CoinSmall, PlusIcon, PynkLogo } from 'assets/svgs';
import FiftyToken from 'assets/svgs/50Tokens';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SubscriptionButton from 'components/SubscriptionButton';
import toaster from 'lib/toaster';
import AcountModel from 'models/Account';
import React, { Fragment, ReactNode } from 'react';
import { useStoreBase } from 'store';
import useSWRMutation from 'swr/mutation';
interface Props {
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  children?: ReactNode;
  title?: string;
  btnText?: string;
}

const FirstStep: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  children,
  shouldCloseOnOverlayClick = false,
  title = 'CLAIM YOUR TOKENS',
  btnText = 'I AGREE',
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`claim-free-tokens confirmation-modal`}
      showHeaderClose={false}
      title={
        <div className='modal-header-wrap'>
          <CoinSmall />
          FREE TOKENS
        </div>
      }
      onOk={onClose}
      isOpen={isOpen}
      card-modal
      okText={'Close'}
      showHeader
      showFooter={false}
      showCancelBtn={false}
      onClose={() => {
        onClose();
      }}
    >
      <div className='header-img'>
        <img src='assets/images/50tokens-bg.jpg' alt='' />
      </div>
      <div className='modal-scroll-wrap' style={{ paddingTop: '20px' }}>
        <span
          className='subscription-heading'
          style={{
            paddingLeft: '18px',
            lineHeight: '22px',
            textAlign: 'center',
            marginBottom: '21px',
          }}
        >
          As a <b>Beta Participant</b> you qualify to receive <b>50 Free Tokens</b> to spend on{' '}
          <b>onlyclones.com</b> <br />
          <br />{' '}
          <p> You will be asked to complete a feedback survey at the end of your Beta test.</p>
          <br />
          Click the button below to continue.
        </span>

        <React.Fragment>
          <Fragment>
            <Button
              rounded
              block
              onClick={onClick}
              className={`btn-subscribe `}
              style={{
                border: 'none',
                padding: '22px',
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Fragment>
                <span style={{ position: 'absolute', left: 0, transform: 'translate(15px, 0)' }}>
                  <PynkLogo height={40} width={40} />{' '}
                </span>
                <span
                  className='subscription-btn-text'
                  style={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: '22px',
                  }}
                >
                  <span style={{ position: 'relative', top: 2 }}>CONTINUE</span>
                </span>
              </Fragment>
            </Button>
          </Fragment>
        </React.Fragment>
      </div>
    </Modal>
  );
};

export default FirstStep;
