// import 'cropperjs/dist/cropper.css';
import classNames from 'classnames';
import Button from 'components/Button';
// import 'rc-slider/assets/index.css';
import { CrossIcon } from 'assets/svgs';
import React, { CSSProperties, ReactNode } from 'react';
import Modal from 'react-modal';
import './style.scss';
Modal.setAppElement('#root');

interface IStyle {
  model?: CSSProperties;
  header?: CSSProperties;
  body?: CSSProperties;
  content?: CSSProperties;
  overlayStyle?: CSSProperties;
  footer?: CSSProperties;
  dialog?: CSSProperties;
}

const Model = ({
  isOpen,
  onClose,
  title,
  children,
  onOk,
  confirmLoading,
  isDisabled,
  showHeader = true,
  showFooter = true,
  okText = 'Save',
  styles = {},
  className,
  showHeaderClose = true,
  shouldCloseOnOverlayClick = true,
  overlayClassName,
  showSaveBtn = false,
  showCancelBtn = true,
}: {
  showSaveBtn?: boolean;
  showCancelBtn?: boolean;
  isOpen: boolean;
  onClose?: any;
  title?: string | React.ReactNode;
  onOk?: () => void;
  confirmLoading?: boolean;
  isDisabled?: boolean;
  children?: React.ReactNode;
  showHeader?: boolean;
  showFooter?: boolean;
  okText?: ReactNode;
  showHeaderClose?: boolean;
  styles?: IStyle;
  className?: string;
  overlayClassName?: string;
  shouldCloseOnOverlayClick?: boolean;
}) => {
  const { model: modelStyle, content: contentStyle, overlayStyle, body: bodyStyle } = styles;

  const onOkHanlder = async () => {
    if (onOk) {
      return await onOk();
    }
  };
  const onCloseHanlder = () => {
    onClose && onClose();
  };
  return (
    <Modal
      isOpen={isOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      overlayClassName={overlayClassName}
      onRequestClose={onClose}
      className={classNames(className, 'modal-dialog')}
      style={{
        content: { ...modelStyle },
        overlay: {
          zIndex: 12,
          backgroundColor: 'rgba(0,0,0,0.6)',
          ...overlayStyle,
        },
      }}
    >
      <div className='modal-content' style={contentStyle}>
        {showHeader && (
          <div className='modal-header'>
            <div className='modal-title'>{title}</div>
            {showHeaderClose && (
              <span
                className='modal-close'
                data-dismiss='modal'
                aria-label='Close'
                onClick={onCloseHanlder}
                id='sp_test_modal_close'
              >
                <CrossIcon />
              </span>
            )}
          </div>
        )}
        <div className='modal-body' style={bodyStyle}>
          {children}
        </div>
        {showFooter && (
          <div className='modal-footer text-right'>
            {/* we can remove the type for cancel button and chagne the type to primary for save button and remove the size from both ot them to go back to previous viwe */}
            {showCancelBtn && (
              <Button
                onClick={onCloseHanlder}
                disabled={false}
                variant='text'
                className='modal-btn-cancel'
              >
                Cancel
              </Button>
            )}
            {!showSaveBtn && (
              <Button onClick={onOkHanlder} variant='text' disabled={confirmLoading || isDisabled}>
                {okText}
              </Button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default Model;
