import { URLParts, isValidUrl } from 'utils';

const acceptedExtensions = ['jpeg', 'tiff', 'jpg', 'webp', 'png'];
function addQuality(edits: any, quality: number | undefined, filetype: string) {
  if (acceptedExtensions.includes(filetype)) {
    if (filetype === 'jpg') {
      filetype = 'jpeg';
    }
    edits[filetype] = { quality: Number(quality) };
  }
}
function handleResize(
  width: number | undefined,
  edits: any,
  height: number | undefined,
  resize: 'contain' | 'cover' | 'fill' | 'inside' | 'outside',
  position: imageEditorProps['position'],
) {
  edits.resize = {}; // Initialize the 'resize' property
  if (width) {
    edits.resize.width = Number(width);
  }
  if (height) {
    edits.resize.height = Number(height);
  }
  edits.resize.fit = resize;
  edits.resize.position = position;
}
function handleCrop(edits: any, crops: IImageEditor['crop']) {
  // edits.focal = { ...crops }; // Initialize the 'resize' property
  edits.crop = `${crops?.x}x${crops?.y}:${crops?.width}x${crops?.height}`; // Initialize the 'resize' property
}

function handleSmartCrop(
  edits: any,
  smartCropIndex: number | undefined,
  smartCropPadding: number | undefined,
) {
  edits.smartCrop = {};
  if (smartCropIndex) {
    edits.smartCrop.faceIndex = smartCropIndex;
  }
  if (smartCropPadding) {
    edits.smartCrop.padding = smartCropPadding;
  }
}

function hexToRgbA(hex: string, alpha = 1) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c: any = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    return {
      r: (c >> 16) & 255,
      g: (c >> 8) & 255,
      b: c & 255,
      alpha: Number(alpha),
    };
  }
  return false;
}

export function getPreviewImage(params: IImageEditor) {
  const {
    width,
    height,
    resizeMode,
    fillColor,
    backgroundColor,
    grayscale = false,
    blur,
    rotate,
    flip = false,
    flop = false,
    negative = false,
    flatten = false,
    normalise = false,
    tint,
    smartCrop,
    toFormat,
    smartCropIndex,
    smartCropPadding,
    imgOriginalBucket,
    src,
    apiEndpoint,
    crop,
    quality,
    position,
  } = params;
  if (!isValidUrl(src)) {
    return {
      key: src,
      encodedUrl: src,
    };
  }
  const edits: any = {
    flop,
    // position,
    negative,
    flatten,
    normalise,
    flip,
  };
  if (rotate != null) {
    edits.rotate = rotate;
  }
  if (blur != null) {
    edits.blur = Number(blur);
  }
  if (tint) {
    edits.tint = tint;
  }

  if (resizeMode) {
    handleResize(width, edits, height, resizeMode, position);
  }
  if (smartCrop) {
    handleSmartCrop(edits, smartCropIndex, smartCropPadding);
  }
  if (crop) {
    handleCrop(edits, crop);
  }
  if (fillColor) {
    const color = hexToRgbA(fillColor, 1);
    if (color) {
      if (!edits.resize) {
        edits.resize = {};
      }
      edits.resize.background = color;
    }
  }

  if (backgroundColor) {
    const color = hexToRgbA(backgroundColor, 1);
    if (color) {
      edits.flatten = { background: color };
    }
  }

  if (grayscale) {
    edits.grayscale = grayscale;
  }

  const urlParts = URLParts(src);
  if (toFormat) {
    edits.toFormat = toFormat;
  }
  if (quality != null) {
    const filetype = urlParts.pathname.split('.').pop() || '';
    addQuality(edits, quality, toFormat || filetype);
  }

  // ... Rest of the if conditions for edits ...
  const request: any = {
    bucket: imgOriginalBucket,
    key: urlParts.pathname.replace('/', ''),
  };

  if (Object.keys(request?.edits || {}).length == 0) {
    request.edits = edits;
  }

  const str = JSON.stringify(request);
  const enc = btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt(p1, 16));
    }),
  );

  const imgPreviewSrc = `${apiEndpoint}/${enc}`;

  return {
    key: urlParts.pathname,
    encodedUrl: imgPreviewSrc,
  };
}
export const getScaledDimenstions = (item: { width: number; height: number }, scaleWidth = 630) => {
  const ImageHeight = item?.height;
  const ImageWidth = item?.width;
  let thumbHeight = item?.height;
  const thumbWidth = scaleWidth;
  const isheightNumber = isNaN(ImageHeight) || isNaN(ImageWidth);
  if (!!ImageHeight && !!ImageWidth && !isheightNumber) {
    thumbHeight = scaleWidth * (ImageHeight / ImageWidth);
  }
  return { width: Math.ceil(thumbWidth), height: Math.ceil(thumbHeight) };
};
