type Props = IConProps & { count?: number };

const ProfileCardSkeleton = (props: Props) => {
  const { count, ...rest } = props;
  return (
    <>
      {Array(count)
        .fill(1)
        .map((m, index) => {
          return (
            <svg
              key={index}
              role='img'
              width='373'
              height='129'
              aria-labelledby='loading-aria'
              viewBox='0 0 373 129'
              preserveAspectRatio='none'
              {...rest}
            >
              <title id='loading-aria'>Loading...</title>
              <rect
                x='0'
                y='0'
                width='100%'
                height='100%'
                clipPath='url(#suggestions)'
                style={{ fill: 'url("#fill")' }}
              ></rect>
              <defs>
                <clipPath id='suggestions'>
                  <rect x='0' y='0' rx='10' ry='10' width='373' height='129' />
                  <rect x='55' y='143' rx='0' ry='0' width='54' height='23' />
                  <rect x='10' y='172' rx='0' ry='0' width='60' height='2' />
                </clipPath>
                <linearGradient id='fill'>
                  <stop offset='0.599964' stopColor='var(--border-color)' stopOpacity='1'>
                    <animate
                      attributeName='offset'
                      values='-2; -2; 1'
                      keyTimes='0; 0.25; 1'
                      dur='2s'
                      repeatCount='indefinite'
                    ></animate>
                  </stop>
                  <stop offset='1.59996' stopColor='var(--secondary-dark)' stopOpacity='1'>
                    <animate
                      attributeName='offset'
                      values='-1; -1; 2'
                      keyTimes='0; 0.25; 1'
                      dur='2s'
                      repeatCount='indefinite'
                    ></animate>
                  </stop>
                  <stop offset='2.59996' stopColor='var(--border-color)' stopOpacity='1'>
                    <animate
                      attributeName='offset'
                      values='0; 0; 3'
                      keyTimes='0; 0.25; 1'
                      dur='2s'
                      repeatCount='indefinite'
                    ></animate>
                  </stop>
                </linearGradient>
              </defs>
            </svg>
          );
        })}
    </>
  );
};

export default ProfileCardSkeleton;
