import { PynkLogo } from 'assets/svgs';
import Modal from 'components/Modal';
import SubscriptionButton from 'components/SubscriptionButton';
import React from 'react';
import './modal.scss';
interface Props {
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  callback?: () => void;
  shouldCloseOnOverlayClick?: boolean;
  post?: IPost;
}

const UnlockModel: React.FC<Props> = ({
  isOpen,
  onClose,
  className,
  callback,
  post,
  shouldCloseOnOverlayClick = true,
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`${className} confirmation-modal`}
      showHeaderClose={false}
      title={<div className='modal-header-wrap'>CONFIRM POST PURCHASE</div>}
      isOpen={isOpen}
      okText={'PAY'}
      card-modal
      showHeader
      showFooter={true}
      onClose={onClose}
      onOk={callback}
    >
      <div className='modal-scroll-wrap  pt-0'>
        <span className='subscription-heading'>
          You will be charged {post?.unlock_price || 0} credits
        </span>
        <SubscriptionButton
          label='UNLOCK PRICE'
          price={`${post?.unlock_price}`}
          icon={{ priceIcon: <PynkLogo /> }}
        />
      </div>
    </Modal>
  );
};

export default UnlockModel;
