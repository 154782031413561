import SkeletonWrapper from 'components/SkeletonWrapper';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { HeaderSK } from './HomePageFeedsSk';
import ListingItemSK from './ListingItemSK';
import ProfileCardSkeleton from './ProfileCardSkeleton';

const NotificatinsRouteSK = () => {
  return (
    <SkeletonWrapper className='page_skeleton  h-100'>
      <div className='skelton_middle_wrapper h-100'>
        <HeaderSK />
        <ListingItemSK />
      </div>
      <ResponsiveLayout>
        <div className='skelton_right_wrapper'>
          <div className='skelton_right_scroll_wrapper d-flex'>
            <ProfileCardSkeleton count={3} />
          </div>
        </div>
      </ResponsiveLayout>
    </SkeletonWrapper>
  );
};

export default NotificatinsRouteSK;
