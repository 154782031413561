const TwitchIcon = (props: IConProps) => {
  const { width = 14, height = 14, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M2.85714 0L0 2.85714V13.1429H3.42857V16L6.28571 13.1429H8.57143L13.7143 8V0H2.85714ZM12.5714 7.42857L10.2857 9.71429H8L6 11.7143V9.71429H3.42857V1.14286H12.5714V7.42857Z'
        fill={fill}
      />
      <path d='M10.8571 3.14286H9.71429V6.57143H10.8571V3.14286Z' fill={fill} />
      <path d='M7.71429 3.14286H6.57143V6.57143H7.71429V3.14286Z' fill={fill} />
    </svg>
  );
};

export default TwitchIcon;
