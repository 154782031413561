export const USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL;
export const CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT;

export const BASE_URL = process.env.REACT_APP_HTTP_API_URL;
export const CLOUD_FRONT_MEDIA_URL = process.env.REACT_APP_PUBLIC_CLOUD_FRONT_MEDIA_URL;
export const STRIPE_KEY = process.env.REACT_APP_PUBLIC_STRIPE_KEY;
export const SOCKET_URL = process.env.REACT_APP_SERVICE_ENDPOINT_WEBSOCKET;
export const MEDIA_BUCKET = process.env.REACT_APP_PUBLIC_MEDIDA_BUCKET;
// export const LOG_ROCKET_APP_ID = process.env.REACT_APP_PUBLIC_LOGROCKET_APP_ID;
export const LOG_ROCKET_APP_ID = 'wes30c/onlyclones';
