const AddCardIcon = (props: IConProps) => {
  const { width = 28, height = 28, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M0.625 9.66699C0.625 9.18399 1.017 8.79199 1.5 8.79199H22.5C22.983 8.79199 23.375 9.18399 23.375 9.66699C23.375 10.15 22.983 10.542 22.5 10.542H1.5C1.017 10.542 0.625 10.15 0.625 9.66699Z'
        fill={fill}
      />
      <path
        d='M5 19.291C4.304 19.291 3.636 19.016 3.144 18.523C2.651 18.03 2.375 17.363 2.375 16.666V7.33301C2.375 6.63701 2.651 5.96901 3.144 5.47701C3.636 4.98501 4.304 4.70801 5 4.70801H19C19.696 4.70801 20.363 4.98401 20.855 5.47701C21.348 5.97001 21.625 6.63701 21.625 7.33301V10.541H23.375V7.33301C23.375 6.17201 22.914 5.06001 22.094 4.23901C21.274 3.41801 20.16 2.95801 19 2.95801H5C3.84 2.95801 2.727 3.41901 1.906 4.23901C1.086 5.05901 0.625 6.17201 0.625 7.33301V16.666C0.625 17.827 1.086 18.939 1.906 19.76C2.727 20.58 3.84 21.041 5 21.041H15V19.291H5Z'
        fill={fill}
      />
      <path
        d='M19 19.375C19 18.892 19.392 18.5 19.875 18.5H26.875C27.358 18.5 27.75 18.892 27.75 19.375C27.75 19.858 27.358 20.25 26.875 20.25H19.875C19.392 20.25 19 19.858 19 19.375Z'
        fill={fill}
      />
      <path
        d='M23.375 15C23.858 15 24.25 15.392 24.25 15.875V22.875C24.25 23.358 23.858 23.75 23.375 23.75C22.892 23.75 22.5 23.358 22.5 22.875V15.875C22.5 15.392 22.892 15 23.375 15Z'
        fill={fill}
      />
    </svg>
  );
};

export default AddCardIcon;
