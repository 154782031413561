const ArrowLeft = (props: IConProps) => {
  const { width = 16, height = 10, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M15 5H1'
        stroke={stroke}
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.5 1.5L1 5L4.5 8.5'
        stroke={stroke}
        strokeWidth='1.75'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowLeft;
