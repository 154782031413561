import { AuthStatus } from 'contexts/authContext';
import { UserAccessType } from 'enums';
import useAuthModule from 'hooks/useAuthModule';
import { ReactNode, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClaimFreeTokensStore } from 'store/stores/ClaimFreeTokensStore';

type Props = {
  children?: ReactNode;
};

const GlobalUserCheckslayout = (props: Props) => {
  const { children } = props;
  const claimFreeTokensStore = useClaimFreeTokensStore();
  const [prevLocation, setPrevLocation] = useState<null | string>(null);

  const openClaimFreeTokensModal = () => claimFreeTokensStore.setIsOpen({ isOpen: true });

  const user = useAuthModule().user;
  const isSignIn = useAuthModule().authStatus === AuthStatus.SignedIn;
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  useLayoutEffect(() => {
    const isunapproedUser =
      isSignIn && user?.id && !(user?.access_type === UserAccessType.approved);
    if (isunapproedUser) {
      navigate('/pending-profile');
    } else if (
      user &&
      user['50_credit_used'] === 0
      // &&
      // !pathname.startsWith('/seller-profile') &&
      // !pathname.startsWith('/messages')
    ) {
      if (user && pathname !== '/' && user.onboarding_ux_type !== 2) {
        navigate('/');
      } else openClaimFreeTokensModal();
    }
    if (
      prevLocation &&
      !prevLocation.startsWith('/my-wallet') &&
      pathname.startsWith('/my-wallet')
    ) {
      window.location.reload(); // Refresh the page
    }
    setPrevLocation(pathname);
  }, [pathname, isSignIn, user?.id]);
  return <>{children}</>;
};

export default GlobalUserCheckslayout;
