const LockIcon = (props: IConProps) => {
  const { width = 88, height = 88, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 88 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <mask
        id='mask0_1_1522'
        //         style={'mask-type:alpha' as any}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='88'
        height='88'
      >
        <rect width='88' height='88' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_1_1522)'>
        <path
          d='M22 80.6667C19.9834 80.6667 18.257 79.9486 16.8209 78.5125C15.3847 77.0764 14.6667 75.35 14.6667 73.3334V36.6667C14.6667 34.65 15.3847 32.9236 16.8209 31.4875C18.257 30.0514 19.9834 29.3334 22 29.3334H25.6667V22C25.6667 16.9278 27.4542 12.6042 31.0292 9.02919C34.6042 5.45419 38.9278 3.66669 44 3.66669C49.0722 3.66669 53.3959 5.45419 56.9709 9.02919C60.5459 12.6042 62.3334 16.9278 62.3334 22V29.3334H66C68.0167 29.3334 69.7431 30.0514 71.1792 31.4875C72.6153 32.9236 73.3334 34.65 73.3334 36.6667V73.3334C73.3334 75.35 72.6153 77.0764 71.1792 78.5125C69.7431 79.9486 68.0167 80.6667 66 80.6667H22ZM22 73.3334H66V36.6667H22V73.3334ZM44 62.3334C46.0167 62.3334 47.7431 61.6153 49.1792 60.1792C50.6153 58.7431 51.3334 57.0167 51.3334 55C51.3334 52.9834 50.6153 51.257 49.1792 49.8209C47.7431 48.3847 46.0167 47.6667 44 47.6667C41.9834 47.6667 40.257 48.3847 38.8209 49.8209C37.3847 51.257 36.6667 52.9834 36.6667 55C36.6667 57.0167 37.3847 58.7431 38.8209 60.1792C40.257 61.6153 41.9834 62.3334 44 62.3334ZM33 29.3334H55V22C55 18.9445 53.9306 16.3472 51.7917 14.2084C49.6528 12.0695 47.0556 11 44 11C40.9445 11 38.3472 12.0695 36.2084 14.2084C34.0695 16.3472 33 18.9445 33 22V29.3334Z'
          fill='white'
          fillOpacity='0.05'
        />
      </g>
    </svg>
  );
};

export default LockIcon;
