const LogOutIcon = (props: IConProps) => {
  const { width = 26, height = 26, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g>
        <path
          fill={stroke}
          d='M21.822,5.261c-0.598-0.598-1.393-0.927-2.238-0.927H12c-0.552,0-1,0.448-1,1s0.448,1,1,1h7.584
		c0.311,0,0.604,0.121,0.824,0.341C20.625,6.892,20.75,7.193,20.75,7.5v13c0,0.311-0.122,0.604-0.342,0.825
		c-0.221,0.22-0.512,0.341-0.824,0.341H12c-0.552,0-1,0.447-1,1s0.448,1,1,1h7.584c0.846,0,1.641-0.329,2.239-0.928
		c0.598-0.598,0.927-1.393,0.927-2.238v-13C22.75,6.654,22.421,5.859,21.822,5.261z'
        />
        <path
          fill={stroke}
          d='M7.664,15h7.586c0.553,0,1-0.447,1-1c0-0.552-0.447-1-1-1H7.664l1.543-1.543
		c0.391-0.391,0.391-1.023,0-1.414s-1.023-0.391-1.414,0l-3.25,3.25c-0.087,0.087-0.148,0.189-0.197,0.296
		c-0.015,0.033-0.024,0.066-0.036,0.1c-0.026,0.08-0.042,0.161-0.047,0.245c-0.002,0.036-0.007,0.069-0.005,0.104
		c0.004,0.105,0.022,0.208,0.06,0.31c0.004,0.01,0.004,0.021,0.008,0.031c0,0.001,0,0.002,0.001,0.003
		c0.046,0.112,0.117,0.208,0.196,0.293c0.009,0.01,0.011,0.022,0.021,0.031l3.25,3.25C7.988,18.152,8.244,18.25,8.5,18.25
		s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L7.664,15z'
        />
      </g>
    </svg>
  );
};

export default LogOutIcon;
