const Email = (props: IConProps) => {
  const {
    width = 30,
    height = 30,
    fill = 'currentColor',
    stroke = 'currentColor',
    ...rest
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_12_182)'>
        <path
          d='M10.5 16.3333C10.5 18.2665 13.6337 19.8333 17.5 19.8333C21.3663 19.8333 24.5 18.2665 24.5 16.3333C24.5 14.4002 21.3663 12.8333 17.5 12.8333C13.6337 12.8333 10.5 14.4002 10.5 16.3333Z'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5 16.3333V21C10.5 22.932 13.6337 24.5 17.5 24.5C21.3663 24.5 24.5 22.932 24.5 21V16.3333'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5 7C3.5 8.25067 4.83467 9.40567 7 10.031C9.16533 10.6563 11.8347 10.6563 14 10.031C16.1653 9.40567 17.5 8.25067 17.5 7C17.5 5.74933 16.1653 4.59433 14 3.969C11.8347 3.34367 9.16533 3.34367 7 3.969C4.83467 4.59433 3.5 5.74933 3.5 7Z'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5 7V18.6667C3.5 19.7027 4.40067 20.3583 5.83333 21'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.5 12.8333C3.5 13.8693 4.40067 14.525 5.83333 15.1667'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_12_182'>
          <rect width='28' height='28' fill={stroke} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Email;
