import {
  BagGiftIcon,
  BottleGiftIcon,
  FlowerGiftIcon,
  HeartGiftIcon,
  LikeGiftIcon,
  RoseGiftIcon,
} from 'assets/svgs';
import { getScaledDimenstions } from 'utils/imageEditor';
export const MEDIA_UPLOAD_STATUSES = {
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
  ENCODING: 'ENCODING',
  CANCELLED: 'CANCELLED',
} as const;
export const SOCIAL_LINKS = {
  TIKTOK: 'https://www.tiktok.com/@',
  YOUTUBE: 'https://www.youtube.com/channel/',
  INSTA: 'https://www.instagram.com/',
  TWITCH: 'https://www.twitch.tv/',
} as const;
export const SUBSDURATION = {
  weekly: 'weekly',
  monthly: 'monthly',
  yearly: 'yearly',
  lifetime: 'lifetime',
} as const;
export const MEMEBER_TYPE = {
  MEMBER_ONLY: 'members_only',
  NON_MEMBER_TEXT: 'non_member_text',
  NON_MEMBER_ALL: 'non_member_all',
};
export const LIKES_COUNTER_MAPE_TO_TYPE: Record<keyof IPostLikesTypes, ILikesMetaTypes> = {
  bell_like_count: 'bell',
  free_like_count: 'free',
  gift_bag_like_count: 'gift_bag',
  heart_like_count: 'heart',
  pink_flower_like_count: 'pink_flower',
  red_flower_like_count: 'red_flower',
};
export const LIKES_TYPE_MAPE_TO_COUNTER: Record<ILikesMetaTypes, keyof IPostLikesTypes> = {
  bell: 'bell_like_count',
  free: 'free_like_count',
  gift_bag: 'gift_bag_like_count',
  heart: 'heart_like_count',
  pink_flower: 'pink_flower_like_count',
  red_flower: 'red_flower_like_count',
};
export const LikeEmojisApi = {
  heart: <HeartGiftIcon />,
  red_flower: <RoseGiftIcon />,
  bell: <BottleGiftIcon />,
  gift_bag: <BagGiftIcon />,
  // pink_flower: <Image src='/assets/svgs/flower.svg' />,
  pink_flower: <FlowerGiftIcon />,
  free: <LikeGiftIcon />,
};
export const AppLikesMap = [
  {
    id: 1,
    type: 'free',
    price: 0,
    created_at: '2023-07-08T21:11:44.000Z',
    updated_at: '2023-07-08T21:11:44.000Z',
  },
  {
    id: 2,
    type: 'heart',
    price: 1,
    created_at: '2023-07-08T18:07:10.000Z',
    updated_at: '2023-07-08T18:07:10.000Z',
  },
  {
    id: 3,
    type: 'pink_flower',
    price: 3,
    created_at: '2023-07-08T18:07:25.000Z',
    updated_at: '2023-07-08T18:07:25.000Z',
  },
  {
    id: 4,
    type: 'bell',
    price: 5,
    created_at: '2023-07-08T18:07:36.000Z',
    updated_at: '2023-07-08T18:07:36.000Z',
  },
  {
    id: 5,
    type: 'red_flower',
    price: 10,
    created_at: '2023-07-08T18:07:52.000Z',
    updated_at: '2023-07-08T18:07:52.000Z',
  },
  {
    id: 6,
    type: 'gift_bag',
    price: 50,
    created_at: '2023-07-08T18:08:03.000Z',
    updated_at: '2023-07-08T18:08:03.000Z',
  },
];

export const LedgerTypes: any = {
  message_like: 'Gift to',
  post_like: 'Gift to',
  gallery_unlock: 'Unlock Post to',
  message_unlock: 'Unlock Message to',
  post_tip: 'Post Tip to',
  message_tip: 'Message Tip to',
  credits: 'Credits added',
};

export const MEDIA_CROP_SIZESObj = {
  POST: { width: 768, height: 1152 },
  PROFILE_THUMB: { width: 768, height: 768 },
  PROFILE_HEADER: { width: 640, height: 190 },
  CHAT_IMAGE: { width: 768, height: 768 },
};
export const MEDIA_CROP_SIZES = {
  POST: MEDIA_CROP_SIZESObj.POST,
  POST_SCALE: (widthScale = MEDIA_CROP_SIZESObj.POST.width) =>
    getScaledDimenstions(MEDIA_CROP_SIZESObj.POST, widthScale),
  PROFILE_THUMB: MEDIA_CROP_SIZESObj.PROFILE_THUMB,
  PROFILE_THUMB_SCALE: (scalefactor = MEDIA_CROP_SIZESObj.PROFILE_THUMB.width) =>
    getScaledDimenstions(MEDIA_CROP_SIZESObj.PROFILE_THUMB, scalefactor),
  PROFILE_HEADER: MEDIA_CROP_SIZESObj.PROFILE_HEADER,
  PROFILE_HEADER_SCALE: (scalefactor = MEDIA_CROP_SIZESObj.PROFILE_HEADER.width) =>
    getScaledDimenstions(MEDIA_CROP_SIZESObj.PROFILE_HEADER, scalefactor),
  CHAT_IMAGE: MEDIA_CROP_SIZESObj.CHAT_IMAGE,
  CHAT_IMAGE_SCALE: (scalefactor = MEDIA_CROP_SIZESObj.CHAT_IMAGE.width) =>
    getScaledDimenstions(MEDIA_CROP_SIZESObj.CHAT_IMAGE, scalefactor),
};

// export const SITE_NAMES_MAPS = {
//   pynk: 'pynk_site',
//   puresynth: 'puresynth_site',
// };

// export const SITE_NAMES_MAPS_SITE_IDS: Record<string, number> = {
//   pynk: 1,
//   puresynth: 2,
// };

export const LOCAL_IMAGES = {
  suggestion_placeholder: '/assets/svgs/suggestion_placeholder.svg',
  smallLogo: '/assets/svgs/onlyclone_small_logo.svg',
};

export const WALLET_EVENT_TYPES = {
  gallery_unlock: 'gallery_unlock',
  subscription: 'subscription',
  message_unlock: 'message_unlock',
  post_tip: 'post_tip',
  message_tip: 'message_tip',
  tokens_added: 'tokens_added',
  forever_tokens_added: 'forever_tokens_added',
  tokens_expired: 'tokens_expired',
  message_like: 'message_like',
  post_like: 'post_like',
  credits: 'credits',
  private_chat: 'private_chat',
  gift: 'gift',
};
