const OnlyFansIcon = (props: IConProps) => {
  const { width = 24, height = 16, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 24 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.99998 0C6.41774 0 4.87102 0.469191 3.55543 1.34824C2.23984 2.22729 1.21447 3.47671 0.608966 4.93852C0.0034663 6.40032 -0.15496 8.00885 0.153721 9.5607C0.462402 11.1125 1.22433 12.538 2.34314 13.6568C3.46196 14.7756 4.88742 15.5376 6.43926 15.8462C7.99111 16.1549 9.59964 15.9965 11.0614 15.391C12.5232 14.7855 13.7727 13.7601 14.6517 12.4445C15.5308 11.1289 16 9.58223 16 7.99998C16 5.87825 15.1571 3.84343 13.6568 2.34314C12.1565 0.842852 10.1217 0 7.99998 0ZM7.99998 10.4C7.52531 10.4 7.0613 10.2592 6.66662 9.9955C6.27194 9.73179 5.96433 9.35696 5.78268 8.91842C5.60103 8.47988 5.5535 7.99732 5.6461 7.53176C5.73871 7.06621 5.96729 6.63857 6.30293 6.30293C6.63858 5.96728 7.06621 5.73871 7.53177 5.6461C7.99732 5.5535 8.47988 5.60103 8.91842 5.78267C9.35696 5.96432 9.73179 6.27194 9.99551 6.66662C10.2592 7.06129 10.4 7.52531 10.4 7.99998C10.4004 8.31527 10.3386 8.62755 10.2181 8.91892C10.0977 9.21029 9.92092 9.47503 9.69798 9.69797C9.47503 9.92092 9.21029 10.0977 8.91892 10.2181C8.62755 10.3386 8.31527 10.4004 7.99998 10.4Z'
        fill='#00AEEF'
      />
      <path
        d='M16.992 5.99998C19.0246 6.58494 21.424 5.99998 21.424 5.99998C20.7276 9.03998 18.5197 10.944 15.3357 11.1757C14.7183 12.6087 13.6944 13.8295 12.3906 14.6869C11.0869 15.5443 9.56043 16.0008 8 16L10.4 8.37182C12.8672 0.531198 14.1318 0 19.9827 0H24C23.328 2.95999 21.0118 5.22111 16.992 5.99998Z'
        fill='#008CCF'
      />
    </svg>
  );
};

export default OnlyFansIcon;
