import SkeletonWrapper from 'components/SkeletonWrapper';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import ProfileCardSkeleton from './ProfileCardSkeleton';

type Props = IConProps & { count?: number };

const HomePageFeedsSk = (props: Props) => {
  const { count, ...rest } = props;
  return (
    <>
      {Array(count)
        .fill(1)
        .map((m, index) => {
          return (
            <svg
              key={index}
              role='img'
              width='630'
              height='500'
              aria-labelledby='loading-aria'
              viewBox='0 0 630 500'
              preserveAspectRatio='none'
              {...rest}
            >
              <title id='loading-aria'>Loading...</title>
              <rect
                x='0'
                y='0'
                width='100%'
                height='100%'
                clipPath='url(#posts_item)'
                style={{ fill: 'url("#post_fill")' }}
              ></rect>
              <defs>
                <clipPath id='posts_item'>
                  <circle cx='37' cy='30' r='30' />
                  <rect x='81' y='15' rx='6' ry='6' width='91' height='10' />
                  <rect x='81' y='31' rx='6' ry='6' width='54' height='6' />
                  <rect x='558' y='13' rx='6' ry='6' width='34' height='10' />
                  <rect x='8' y='67' rx='2' ry='2' width='614' height='7' />
                  <rect x='8' y='78' rx='2' ry='2' width='145' height='7' />
                  <rect x='7' y='95' rx='6' ry='6' width='614' height='396' />
                </clipPath>
                <linearGradient id='post_fill'>
                  <stop offset='0.599964' stopColor='var(--border-color)' stopOpacity='1'>
                    <animate
                      attributeName='offset'
                      values='-2; -2; 1'
                      keyTimes='0; 0.25; 1'
                      dur='2s'
                      repeatCount='indefinite'
                    ></animate>
                  </stop>
                  <stop offset='1.59996' stopColor='var(--secondary-dark)' stopOpacity='1'>
                    <animate
                      attributeName='offset'
                      values='-1; -1; 2'
                      keyTimes='0; 0.25; 1'
                      dur='2s'
                      repeatCount='indefinite'
                    ></animate>
                  </stop>
                  <stop offset='2.59996' stopColor='var(--border-color)' stopOpacity='1'>
                    <animate
                      attributeName='offset'
                      values='0; 0; 3'
                      keyTimes='0; 0.25; 1'
                      dur='2s'
                      repeatCount='indefinite'
                    ></animate>
                  </stop>
                </linearGradient>
              </defs>
            </svg>
          );
        })}
    </>
  );
};

export default HomePageFeedsSk;
export const HeaderSK = () => {
  return (
    <svg
      role='img'
      width='630'
      height='57'
      aria-labelledby='loading-aria'
      viewBox='0 0 630 57'
      preserveAspectRatio='none'
    >
      <title id='loading-aria'>Loading...</title>
      <rect
        x='0'
        y='0'
        width='100%'
        height='100%'
        clipPath='url(#feeds_headersk)'
        style={{ fill: 'url("#fill")' }}
      ></rect>
      <defs>
        <clipPath id='feeds_headersk'>
          <rect x='6' y='2' rx='0' ry='0' width='587' height='56' />
        </clipPath>
        <linearGradient id='fill'>
          <stop offset='0.599964' stopColor='var(--border-color)' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='-2; -2; 1'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='1.59996' stopColor='var(--border-color)' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='-1; -1; 2'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='2.59996' stopColor='var(--border-color)' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='0; 0; 3'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export const HomePageRouteSK = () => {
  return (
    <SkeletonWrapper className='page_skeleton  h-100'>
      <div className='skelton_middle_wrapper h-100'>
        <HeaderSK />
        <HomePageFeedsSk count={3} />
      </div>
      <ResponsiveLayout>
        <div className='skelton_right_wrapper'>
          <div className='skelton_right_scroll_wrapper d-flex'>
            <ProfileCardSkeleton count={3} />
          </div>
        </div>
      </ResponsiveLayout>
    </SkeletonWrapper>
  );
};
