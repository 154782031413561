import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { ReactNode } from 'react';
import { resetters } from 'store';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  isOpen?: boolean;
  title?: ReactNode;
  onSuccessCallback?: (...args: any) => Promise<any> | void;
  onErrorCallback?: (...args: any) => Promise<any> | void;
  isOpenTokenModel?: boolean;
};

type Actions = {
  setIsOpen(props?: {
    isOpen?: boolean;
    title?: ReactNode;
    isOpenTokenModel?: boolean;
    onSuccessCallback?: (...args: any) => Promise<any> | void;
    onErrorCallback?: (...args: any) => Promise<any> | void;
  }): void;
  resetCardStore(): void;
};
export const useStripCardStoreBase = create<State & Actions>()(
  devtools(
    immer<State & Actions>((set) => {
      resetters.push(() =>
        set({
          isOpen: false,
          isOpenTokenModel: false,
          onErrorCallback: undefined,
          onSuccessCallback: undefined,
          title: undefined,
        }),
      );
      return {
        isOpen: false,
        isOpenTokenModel: false,
        setIsOpen: (props) => {
          set((prev) => {
            prev.isOpen = props?.isOpen;
            prev.isOpenTokenModel = props?.isOpenTokenModel;
            prev.onErrorCallback = props?.onErrorCallback;
            prev.onSuccessCallback = props?.onSuccessCallback;
            prev.title = props?.title;
          });
        },
        resetCardStore: () => {
          set((prev) => {
            prev.isOpen = false;
            prev.isOpenTokenModel = false;
            prev.onErrorCallback = undefined;
            prev.onSuccessCallback = undefined;
            prev.title = undefined;
          });
        },
      };
    }),
  ),
);

export const useCardsStore = createSelectorHooks<State & Actions>(useStripCardStoreBase);
