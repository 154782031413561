import Account from 'models/Account';
import AddCreditsModel from 'models/AddCredits';
import AppLikesTypes from 'models/AppLikesTypes';
import BookMarks from 'models/BookMarks';
import BuyerLedger from 'models/BuyerLedger';
import CloneYourself from 'models/cloneYourself';
import Collections from 'models/Collections';
import ContentLike from 'models/ContentLike';
import ContentTip from 'models/ContentTip';
import ContentUnlock from 'models/ContentUnlock';
import Double from 'models/Double';
import GiftModel from 'models/Gifts';
import GumroadCreditsModel from 'models/GumroadCredits';
import HomePageFeed from 'models/HomePageFeed';
import LikeAndUnlike from 'models/LikeAndUnlike';
import MediaGallery from 'models/MediaGallery';
import MessageSection from 'models/MessageSection';
import MessageUnlock from 'models/MessageUnlock';
import Mute from 'models/Mute';
import NotificationModel from 'models/Notification';
import Post from 'models/Post';
import PostComments from 'models/PostComments';
import PostReportModel from 'models/PostReport';
import PrivateChat from 'models/PrivateChat';
import PrivateChatMessagesModel from 'models/PrivateChatMessages';
import PrivateChatPurchase from 'models/PrivateChatPurchase';
import Seller from 'models/Seller';
import SellerMedia from 'models/SellerMedia';
import SellerMediaGallery from 'models/SellerMediaGallery';
import Stripe from 'models/Stripe';
import Subscriptions from 'models/Subscriptions';
import Tokens from 'models/Tokens';
import TrainingPhoto from 'models/TrainingPhoto';
import UnviewPost from 'models/UnviewPost';
export const ApiModels = {
  Seller: 'seller',
  Gumroad: 'Gumroad',
  CloneYourself: 'cloneyourself',
  Gift: 'gift',
  Subscriptions: 'subscriptions',
  SellerMedia: 'seller-media',
  Collection: 'collection',
  MediaGallery: 'mediaGallery',
  HomeFeed: 'homepage-feed',
  MessageSection: 'message-section',
  LikeAndUnlike: 'like-unlike',
  SellerMediaGAllery: 'seller-media-gallery',
  ContentLike: 'content-like',
  ContentUnlock: 'content-unlock',
  MessageUnlock: 'message-unlock',
  AddCredits: 'add-credits',
  User: 'user-account',
  Notification: 'notification',
  BuyerLedger: 'buyer-ledger',
  ApplikeTypes: 'app_like_types',
  PostReport: 'post-report',
  Stripe: 'stripe',
  Double: 'double',
  TrainingPhoto: 'training-Photo',
  PrivateChat: 'private-chat',
  PrivateChatMessages: 'private-chat-messages',
  PrivateChatPurchase: 'private-chat-purchase',
  ContentTip: 'content-tip',
  BookMarks: 'book-marks',
  PostComments: 'post-comments',
  Post: 'post',
  Mute: 'mute',
  UnViewPost: 'unviewed-post',
  Tokens: 'tokens_credits',
} as const;
export const ThreePAppSubModels = {
  ThreePAppConnection: '3p-app-connections',
} as const;
export const ApiModelMapping = {
  [ApiModels.Stripe]: {
    model: Stripe,
  },
  [ApiModels.CloneYourself]: {
    model: CloneYourself,
  },
  [ApiModels.Gumroad]: {
    model: GumroadCreditsModel,
  },
  [ApiModels.Gift]: {
    model: GiftModel,
  },
  [ApiModels.UnViewPost]: {
    model: UnviewPost,
  },
  [ApiModels.Mute]: {
    model: Mute,
  },
  [ApiModels.Post]: {
    model: Post,
  },
  [ApiModels.BookMarks]: {
    model: BookMarks,
  },
  [ApiModels.ContentTip]: {
    model: ContentTip,
  },
  [ApiModels.LikeAndUnlike]: {
    model: LikeAndUnlike,
  },
  [ApiModels.PostComments]: {
    model: PostComments,
  },
  [ApiModels.PrivateChatMessages]: {
    model: PrivateChatMessagesModel,
  },
  [ApiModels.PrivateChatPurchase]: {
    model: PrivateChatPurchase,
  },
  [ApiModels.PrivateChat]: {
    model: PrivateChat,
  },
  [ApiModels.MediaGallery]: {
    model: MediaGallery,
  },
  [ApiModels.Seller]: {
    model: Seller,
  },
  [ApiModels.User]: {
    model: Account,
  },
  [ApiModels.ApplikeTypes]: {
    model: AppLikesTypes,
  },
  [ApiModels.MessageUnlock]: {
    model: MessageUnlock,
  },
  [ApiModels.ContentUnlock]: {
    model: ContentUnlock,
  },
  [ApiModels.ContentLike]: {
    model: ContentLike,
  },
  [ApiModels.SellerMediaGAllery]: {
    model: SellerMediaGallery,
  },
  [ApiModels.SellerMedia]: {
    model: SellerMedia,
  },
  [ApiModels.Subscriptions]: {
    model: Subscriptions,
  },
  [ApiModels.Collection]: {
    model: Collections,
  },
  [ApiModels.HomeFeed]: {
    model: HomePageFeed,
  },
  [ApiModels.MessageSection]: {
    model: MessageSection,
  },
  [ApiModels.AddCredits]: {
    model: AddCreditsModel,
  },
  [ApiModels.Notification]: {
    model: NotificationModel,
  },
  [ApiModels.BuyerLedger]: {
    model: BuyerLedger,
  },
  [ApiModels.PostReport]: {
    model: PostReportModel,
  },
  [ApiModels.Double]: {
    model: Double,
  },
  [ApiModels.TrainingPhoto]: {
    model: TrainingPhoto,
  },
  [ApiModels.Tokens]: {
    model: Tokens,
  },
} as const;
export const ThreePAppSubModelMapping = {
  // [ThreePAppSubModels.ThreePAppConnection]: {
  //   model: ThreePAppConnectionModel,
  // },
};
export type ApiModelDataTypes = {
  [ApiModels.Seller]: ISeller;
  [ApiModels.SellerMedia]: IPost;
  [ApiModels.Subscriptions]: any;
  [ApiModels.Mute]: any;
  [ApiModels.PrivateChatMessages]: any;
  [ApiModels.Collection]: any;
  [ApiModels.HomeFeed]: any;
  [ApiModels.MessageSection]: ISubscriptionItem;
  [ApiModels.Stripe]: IStripeCard;
  [ApiModels.SellerMediaGAllery]: any;
  [ApiModels.ContentLike]: any;
  [ApiModels.ContentUnlock]: any;
  [ApiModels.MessageUnlock]: any;
  [ApiModels.AddCredits]: any;
  [ApiModels.User]: IUser;
  [ApiModels.Notification]: any;
  [ApiModels.BuyerLedger]: any;
  [ApiModels.ApplikeTypes]: any;
  [ApiModels.PostReport]: any;
  [ApiModels.UnViewPost]: any;
  [ApiModels.Double]: any;
  [ApiModels.TrainingPhoto]: any;
  [ApiModels.MediaGallery]: any;
  [ApiModels.PrivateChat]: PrivateChat;
  [ApiModels.PrivateChatPurchase]: any;
  [ApiModels.LikeAndUnlike]: any;
  [ApiModels.ContentTip]: any;
  [ApiModels.BookMarks]: any;
  [ApiModels.PostComments]: IPostComment;
  [ApiModels.Post]: IPost;
  [ApiModels.Tokens]: ITokens;
  [ApiModels.Gift]: IGift;
  [ApiModels.Gumroad]: any;
  [ApiModels.CloneYourself]: any;
};
export type RequestOptions = {
  query?: Record<string, any>;
  path?: string | undefined;
};
