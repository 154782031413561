import AddCardModal from 'components/AddCardModal';
import ClaimFreeTokens from 'components/ClaimFreeTokensModal';
import PostGallery from 'components/Galleries/PostGallery';
import InitializeGlobalData from 'components/InitializeGlobalData';
import PostMediaGallery from 'components/PostFeedSlider/PostFeedSlider';
import SocketRc from 'components/Socket';
import GlobalModelOverlay from 'components/models/GlobalModel';
import TokenModel from 'components/models/TokenModel';
import { LOG_ROCKET_APP_ID } from 'config';
import AuthProvider from 'contexts/authContext';
import useTheme from 'hooks/useTheme';
import LogRocket from 'logrocket';
import { Toaster } from 'react-hot-toast';
import Routes from 'routes';

LogRocket.init(LOG_ROCKET_APP_ID || '');

function App() {
  useTheme();
  return (
    <AuthProvider>
      <SocketRc />
      <Routes />
      <InitializeGlobalData />
      <ClaimFreeTokens />
      <AddCardModal />
      <TokenModel />

      <PostGallery />
      <PostMediaGallery />
      <Toaster position='top-right' />
      <GlobalModelOverlay />
    </AuthProvider>
  );
}

export default App;
