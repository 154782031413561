const CloseEyeIcon = (props: IConProps) => {
  const { width = 24, height = 24, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_613_4756)'>
        <path
          d='M10.5846 10.5869C10.2096 10.9621 9.99893 11.4708 9.99902 12.0013C9.99912 12.5317 10.2099 13.0404 10.5851 13.4154C10.9602 13.7904 11.469 14.0011 11.9994 14.001C12.5299 14.0009 13.0386 13.7901 13.4136 13.4149'
          stroke={fill}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.681 16.673C15.2782 17.5507 13.6547 18.0109 12 18C8.4 18 5.4 16 3 12C4.272 9.88003 5.712 8.32203 7.32 7.32603M10.18 6.18003C10.779 6.05876 11.3888 5.99845 12 6.00003C15.6 6.00003 18.6 8.00003 21 12C20.334 13.11 19.621 14.067 18.862 14.87'
          stroke={fill}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3 3L21 21'
          stroke={fill}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_613_4756'>
          <rect width='24' height='24' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CloseEyeIcon;
