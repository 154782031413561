import dayjs from 'dayjs';
import reativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

dayjs.extend(reativeTime);
dayjs.extend(utc);

export const fromNow = (date: string | Date, withoutSuffix?: boolean) => {
  return dayjs(date).fromNow(withoutSuffix);
};
export const utcToLocal = (date: string) => {
  return dayjs.utc(date).local();
};
export const localToUtc = (date: string | Date) => {
  return dayjs(date).utc();
};
