import * as cognito from 'utils/cognito';

import {
  CognitoUserAttribute,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';
import React, { useContext, useEffect, useState } from 'react';

import Loader from 'components/Loader/SiteLoader';
import { ApiModels } from 'queries/apiModelMapping';
import useGetItemM from 'queries/useGetItemM';
import { Navigate, useNavigate } from 'react-router-dom';
import { resetAllSlices, useStoreBase } from 'store';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils';

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export type IAuth = {
  user?: IUser;
  sessionInfo?: {
    username?: string;
    email?: string;
    sub?: string;
    accessToken?: string;
    refreshToken?: string;
  };
  attrInfo?: any;
  authStatus?: AuthStatus;
  signInWithEmail?: (username: string, password: string) => Promise<any>;
  verifyAttributeCode?: (attribute: string, code: any) => Promise<any>;
  signUpWithEmail?: (
    username: string,
    email: string,
    submission_params?: QueryParams,
  ) => Promise<any>;
  signOut?: (callback?: () => void) => void | Promise<any>;
  verifyCode?: (username: string, code: string, passwprd: string) => void;
  getSession?: () => Promise<CognitoUserSession>;
  sendCode?: (username: string) => Promise<void>;
  forgotPassword?: (username: string, code: string, password: string) => void;
  changePassword?: (oldPassword: string, newPassword: string) => void;
  getAttributes?: () => Promise<CognitoUserAttribute[] | undefined>;
  setAttribute?: (_: ICognitoUserAttributeData) => void | Promise<any>;
};

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
  user: getLocalStorage('auth_user'),
};

type Props = {
  children?: React.ReactNode;
};

export const AuthContext = React.createContext(defaultState);

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);
  if (authStatus === AuthStatus.Loading) {
    return <Loader loading />;
  }
  return <>{authStatus === AuthStatus.SignedIn ? children : <Navigate to={'/signup'} />}</>;
};
interface QueryParams {
  [key: string]: string;
}
export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext);
  if (authStatus === AuthStatus.Loading) {
    return <Loader loading />;
  }
  return <>{authStatus === AuthStatus.SignedOut ? children : <Navigate to={'/my-account'} />}</>;
};
const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading);
  const [isFetchingUser, setIsFetchingUser] = useState(false);
  const [isFetchingSession, setIsFetchingSession] = useState(false);
  const [sessionInfo, setSessionInfo] = useState<{ accessToken?: string; refreshToken?: string }>(
    {},
  );
  const [attrInfo, setAttrInfo] = useState<CognitoUserAttribute[] | undefined>([]);
  const User = useGetItemM({
    modelName: ApiModels.User,
    queryKey: 'user-account',
  });
  const navigate = useNavigate();

  const storeBase = useStoreBase();

  const getUser = () => {
    // if (!isFetchingUser && !storeBase.user) {
    setIsFetchingUser(true);
    User.trigger({
      slug: '',
      options: {},
    })
      .then((data) => {
        if (data?.data) {
          console.log({ user: data.data });
          storeBase.setUser(data.data || {});
          storeBase.setCredit(data?.data?.available_balance || 0);
          setLocalStorage('auth_user', data.data);
        }

        setIsFetchingUser(false);
      })
      .catch((e: any) => {
        setIsFetchingUser(false);
      });
    // }
  };
  async function getSessionInfo() {
    try {
      const session = await getSession();
      setSessionInfo({
        accessToken: `${session.getAccessToken().getJwtToken()}`,
        refreshToken: `${session.getRefreshToken().getToken()}`,
      });
      getUser();
      await setAttribute({
        Name: 'website',
        Value: window.location.href,
      });

      const attr = await getAttributes();
      setAttrInfo(attr);
      setAuthStatus(AuthStatus.SignedIn);
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
    }
  }

  useEffect(() => {
    if (!isFetchingSession) {
      setIsFetchingSession(true);
      getSessionInfo();
    }
  }, [authStatus]);

  async function signInWithEmail(username: string, password: string) {
    try {
      await cognito.signInWithEmail(username, password);
      await getSessionInfo();
      setAuthStatus(AuthStatus.SignedIn);
      return true;
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut);
      throw err;
    }
  }

  async function signUpWithEmail(username: string, email: string, submission_params?: QueryParams) {
    try {
      await cognito.signUpUserWithEmail(username, email, submission_params || {});
    } catch (err) {
      throw err;
    }
  }
  async function signUpWithEmailOnly(email: string) {
    try {
      await cognito.signUpUserWithEmailOnly(email);
    } catch (err) {
      throw err;
    }
  }

  function signOut(callback?: () => void) {
    cognito.signOut(() => {
      console.log('signout');
      removeLocalStorage('auth_user');
      removeLocalStorage('accessToken');
      removeLocalStorage('refreshToken');
      resetAllSlices();
      setAuthStatus(AuthStatus.SignedOut);
      setIsFetchingUser(false);
      callback?.();
      setIsFetchingSession(false);
    });
  }

  async function verifyCode(username: string, code: string, password: string) {
    try {
      await cognito.verifyCode(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession();
      return session;
    } catch (err) {
      throw err;
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes();
      return attr;
    } catch (err) {
      throw err;
    }
  }

  async function setAttribute(attr: ICognitoUserAttributeData) {
    try {
      const res = await cognito.setAttribute(attr);
      return res;
    } catch (err) {
      throw err;
    }
  }

  async function sendCode(username: string) {
    try {
      await cognito.sendCode(username);
    } catch (err) {
      throw err;
    }
  }

  async function forgotPassword(username: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(username, code, password);
    } catch (err) {
      throw err;
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (err) {
      throw err;
    }
  }

  async function verifyAttributeCode(attribute: string, code: any) {
    try {
      await cognito.verifyAttribute(attribute, code);
    } catch (err) {
      throw err;
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    setAttribute,
    verifyAttributeCode,
    user: storeBase.user || getLocalStorage('auth_user'),
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
