import './image.scss';

import {
  ComponentPropsWithoutRef,
  MutableRefObject,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { URLParts, isValidUrl } from 'utils';

// import RoundPlaceholder from 'components/skeletons/PlaceholderSvg';
import { LOCAL_IMAGES } from 'appconstants';
import { ImageCenceredPlaceholder, OnlyClonesFullLogo } from 'assets/svgs';
import usePostImageScaleOptions from 'hooks/usePostImageScaleOptions';
import { useStoreBase } from 'store';
import { getPreviewImage } from 'utils/imageEditor';
import { v4 } from 'uuid';

export type ImageType = ComponentPropsWithoutRef<'img'> & {
  fallbackUrl?: string;
  onImageLoad?: (...args: any[]) => void;
  onError?: (...args: any[]) => void;
  breakCache?: boolean;
  fallbackComponent?: JSX.Element | null;
  rounded?: boolean;
  wrapperClasses?: string;
  filters?: imageEditorProps;
  placeholderFilters?: imageEditorProps;
  cencerodUrl?: JSX.Element | string | null;
  imgWrapperClass?: string;
};
const Image = (props: ImageType, ref: any) => {
  const {
    fallbackUrl = '',
    className = '',
    imgWrapperClass = '',
    onImageLoad,
    breakCache = false,
    onError: onImageError,
    loading: loadStrategy = 'lazy',
    wrapperClasses,
    src = '',
    rounded = false,
    fallbackComponent: FallbackComponent = null,
    filters,
    height,
    width,
    // cencerodUrl: CenceredComponentUrl = LOCAL_IMAGES.smallLogo,
    cencerodUrl: CenceredComponentUrl = null,
    placeholderFilters,
    ...rest
  } = props;
  const imageId = useRef(`image_${v4()}`);
  const isCencoredImages = useStoreBase().user?.is_censored;
  const imageRef = useRef<HTMLImageElement>(ref);
  const isFAllbace = !props.src && !props.fallbackUrl && !!FallbackComponent;
  const [imgSrc, setImgSrc] = useState('');
  // src || fallbackUrl || isFAllbace ? '' : '/assets/svgs/onlyclone_small_logo.svg',
  const [pImage, setPImage] = useState('');
  const isCencered = isCencoredImages != undefined && !!isCencoredImages;
  const iscenceredString = CenceredComponentUrl && typeof CenceredComponentUrl === 'string';
  const [imgWrapperClasses, setImgWrapperClasses] = useState({
    loadingState: props.src ? 'initializing' : 'loaded',
    defaultImage: '',
    error: '',
    classses: '',
    isFallBackComponent: !isFAllbace,
    placeholderInitialize: false,
  });
  const { imageScaleRef, placeholderScaleRef } = usePostImageScaleOptions({
    filters,
    height,
    width,
    placeholderFilters,
  });
  const onStateChange = (state?: Partial<typeof imgWrapperClasses>) => {
    setImgWrapperClasses((s) => ({ ...s, ...state }));
  };

  const onAddSrc = (src = props.src) => {
    if (src) {
      if (imageRef.current) {
        imageRef.current.src = src;
      }
      if (ref?.current) {
        ref.current.src = src;
      }
      const isValidU = isValidUrl(src);
      onStateChange({ loadingState: isValidU ? 'loading' : 'loaded', isFallBackComponent: false });
      setImgSrc(src);
    }
  };

  const onLoad = useCallback(
    (e: any) => {
      onStateChange({ loadingState: 'loaded', error: '' });
      onImageLoad?.({ e, propSrc: imgSrc, fallbackUrl });
      setTimeout(() => {
        setPImage('');
      }, 400);
    },

    [fallbackUrl, imgWrapperClasses.loadingState, imgWrapperClasses.error],
  );

  const onError = useCallback(
    (e: any) => {
      const { error } = { ...imgWrapperClasses };
      if (!error && imageRef && !!fallbackUrl && imageRef?.current) {
        onAddSrc(fallbackUrl);
      } else {
        onStateChange({
          loadingState: !!FallbackComponent ? 'loaded' : 'loaded',
          error: 'error',
          defaultImage: 'defaultImage',
          isFallBackComponent: !!FallbackComponent,
        });

        if (!FallbackComponent) {
          setImgSrc(LOCAL_IMAGES.smallLogo);
        }
      }
      onImageError?.({ e, props });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fallbackUrl, imageRef, imgWrapperClasses.error, imgWrapperClasses.loadingState],
  );
  const checkFallbackUrlandComponent = () => {
    if (fallbackUrl) {
      onAddSrc(fallbackUrl);
    } else if (!!FallbackComponent) {
      onStateChange({
        loadingState: 'loaded',
        isFallBackComponent: true,
        defaultImage: 'defaultImage',
        error: '',
      });
    }
  };

  useEffect(() => {
    const imageSrc: any = src || '';

    if (isCencered) {
      if (iscenceredString) {
        onAddSrc(CenceredComponentUrl);
      } else {
        // !CenceredComponentUrl &&
        //   onAddSrc(
        //     `https://placehold.co/${width || imageScaleRef.current?.width}x${
        //       height || imageScaleRef.current?.height
        //     }/001021/FFF`,
        //   );
      }
      onStateChange({
        loadingState: 'loaded',
        isFallBackComponent: false,
        defaultImage: 'defaultImage iscencored_image',
        error: '',
      });
      return;
    } else if (!!imageSrc.trim()) {
      imageScaleRef.current.src = props.src || '';
      const result = getPreviewImage(imageScaleRef.current);
      onAddSrc(result.encodedUrl || '');
      if (isValidUrl(imageSrc)) {
        placeholderScaleRef.current.src = props.src || '';
        const result = getPreviewImage(placeholderScaleRef.current);
        setPImage(result.encodedUrl);
      }
      return;
    }
    checkFallbackUrlandComponent();
  }, [props.src, isCencoredImages, CenceredComponentUrl]);

  useEffect(() => {
    if (breakCache) {
      if (isValidUrl(src as string)) {
        const { url } = URLParts(src as string);
        onAddSrc(`${url}?${new Date().getTime()}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breakCache]);
  const onPlaceholderLoad = useCallback(
    (e: any) => {
      // console.log('placeholderLoaded');
      onStateChange({ placeholderInitialize: true });
    },

    [fallbackUrl, imgWrapperClasses.loadingState, imgWrapperClasses.error],
  );
  const onPlaceholderError = useCallback(
    (e: any) => {
      onStateChange({ placeholderInitialize: true });
      // console.log('placeholderError');
    },

    [fallbackUrl, imgWrapperClasses.loadingState, imgWrapperClasses.error],
  );

  const getComponent = () => {
    const { isFallBackComponent, placeholderInitialize } = imgWrapperClasses;
    if (isCencered) {
      if (!CenceredComponentUrl) {
        return (
          <ImageCenceredPlaceholder
            fill='var(--placeholder-img)'
            isround={rounded}
            className='cencored_img_placeholder'
            text={`${width || imageScaleRef.current?.width}x${
              height || imageScaleRef.current?.height
            }`}
          />
        );
      }
      if (!iscenceredString) {
        return CenceredComponentUrl;
      }
    }
    if (isFallBackComponent) {
      return FallbackComponent;
    }

    if (!imgSrc) {
      return null;
    }
    const isPlaceholder = pImage;
    return (
      <>
        {isPlaceholder ? (
          <>
            {!placeholderInitialize ? (
              <OnlyClonesFullLogo width={'100%'} height={'100%'} className='intial_loading_logo' />
            ) : null}

            <img
              {...rest}
              loading={loadStrategy}
              // ref={imageRef}
              onLoad={onPlaceholderLoad}
              src={pImage}
              alt={props?.alt || 'Image'}
              onError={onPlaceholderError}
              className={`${className} imgplaceholder_img`}
            />
          </>
        ) : null}

        <img
          {...rest}
          loading={loadStrategy}
          ref={imageRef}
          onLoad={onLoad}
          src={imgSrc}
          alt={props?.alt || 'Image'}
          onError={onError}
          className={`${className} original_image`}
        />
      </>
    );
  };
  return (
    <div
      id={imageId.current}
      className={`image-comp ${rounded ? ' rounded_img ' : ''} ${wrapperClasses}  ${Object?.values(
        imgWrapperClasses || {},
      )?.join(' ')}`}
    >
      <div
        className={`image-comp-inner ${imgWrapperClass}`}
        style={{
          width: width || '100%',
          height: height || '100%',
        }}
      >
        {getComponent()}
      </div>
    </div>
  );
};

export default forwardRef<MutableRefObject<HTMLImageElement>, ImageType>(Image);
