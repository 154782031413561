import { CLOUD_FRONT_MEDIA_URL, MEDIA_BUCKET } from 'config';
import { useRef } from 'react';
import { getScaledDimenstions } from 'utils/imageEditor';

type Props = {
  filters?: imageEditorProps;
  height?: number | string;
  width?: number | string;

  placeholderFilters?: imageEditorProps;
};

const usePostImageScaleOptions = (props: Props) => {
  const { filters, width, height, placeholderFilters } = props;
  const { height: fheight, width: fwidth, ...restt } = filters || {};
  const { height: pheight, width: pwidth, ...prestt } = placeholderFilters || {};
  const hHeight = Number(fheight || height);
  const wWidth = Number(fwidth || width);
  const phHeight = Number(pheight || hHeight);
  const pwWidth = Number(pwidth || wWidth);
  const filtersRef = useRef<IImageEditor>({
    height: hHeight,
    width: wWidth,
    quality: 100,
    resizeMode: 'cover',
    ...restt,
    imgOriginalBucket: MEDIA_BUCKET || '',
    src: '',
    // apiEndpoint: 'https://d2xje0gq0dmw8w.cloudfront.net',
    apiEndpoint: CLOUD_FRONT_MEDIA_URL || '',
  });
  // const scalePoint = wWidth * 0.3;
  const scalePoint = pwWidth <= 100 ? 50 : 90;
  const getDims = getScaledDimenstions(
    { width: pwWidth || 100, height: phHeight || 50 },
    scalePoint,
  );

  const filtersPlaceholderRef = useRef<IImageEditor>({
    // quality: 20,
    // blur: 30,
    resizeMode: 'cover',

    imgOriginalBucket: MEDIA_BUCKET || '',
    src: '',
    // apiEndpoint: 'https://d2xje0gq0dmw8w.cloudfront.net',
    apiEndpoint: CLOUD_FRONT_MEDIA_URL || '',
    height: phHeight ? getDims.height : undefined,
    width: pwWidth ? getDims.width : undefined,
    ...prestt,
  });
  return { hHeight, fwidth, placeholderScaleRef: filtersPlaceholderRef, imageScaleRef: filtersRef };
};

export default usePostImageScaleOptions;
