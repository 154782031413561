const TikTok = (props: IConProps) => {
  const { width = 16, height = 16, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 17'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M5.84201 6.69405V6.03226C5.61528 6.00197 5.38478 5.98304 5.14671 5.98304C2.30884 5.97931 0 8.29006 0 11.1341C0 12.8776 0.869123 14.4207 2.19926 15.3548C1.34147 14.4358 0.816218 13.1991 0.816218 11.8451C0.816218 9.04266 3.06082 6.75835 5.84201 6.69405Z'
        fill='#25F4EE'
      />
      <path
        d='M5.9667 14.1975C7.23261 14.1975 8.26798 13.1878 8.31335 11.9322L8.31714 0.711013H10.3652C10.3236 0.480311 10.301 0.242048 10.301 0H7.50467L7.50088 11.2211C7.45557 12.4768 6.42014 13.4865 5.15426 13.4865C4.76127 13.4865 4.39095 13.3882 4.06219 13.2142C4.48542 13.808 5.18072 14.1975 5.9667 14.1975Z'
        fill='#25F4EE'
      />
      <path
        d='M14.1934 4.51947V3.89548C13.4112 3.89548 12.6856 3.66476 12.0735 3.26385C12.6176 3.88791 13.3545 4.34176 14.1934 4.51947Z'
        fill='#25F4EE'
      />
      <path
        d='M12.0735 3.26381C11.4764 2.5793 11.1174 1.68675 11.1174 0.710999H10.3693C10.5619 1.77752 11.1968 2.69277 12.0735 3.26381Z'
        fill='#FE2C55'
      />
      <path
        d='M5.15039 8.77797C3.85427 8.77797 2.79999 9.83316 2.79999 11.1304C2.79999 12.0343 3.3139 12.8209 4.0621 13.2142C3.78247 12.8285 3.61621 12.3558 3.61621 11.8414C3.61621 10.5442 4.67049 9.48898 5.96664 9.48898C6.20847 9.48898 6.44273 9.53057 6.66191 9.59866V6.73953C6.43516 6.70924 6.20468 6.69031 5.96664 6.69031C5.92505 6.69031 5.88726 6.69409 5.84569 6.69409V8.88765C5.62274 8.81956 5.39224 8.77797 5.15039 8.77797Z'
        fill='#FE2C55'
      />
      <path
        d='M14.1931 4.51947V6.6941C12.7421 6.6941 11.3968 6.22894 10.3009 5.44227V11.1342C10.3009 13.9744 7.99213 16.289 5.15045 16.289C4.0546 16.289 3.03433 15.941 2.19922 15.3549C3.14013 16.3646 4.48161 17 5.9667 17C8.80454 17 11.1172 14.6892 11.1172 11.8452V6.15328C12.213 6.93995 13.5582 7.40511 15.0093 7.40511V4.60649C14.7259 4.60649 14.4538 4.5762 14.1931 4.51947Z'
        fill='#FE2C55'
      />
      <path
        d='M10.3008 11.1341V5.44222C11.3967 6.2289 12.742 6.69405 14.193 6.69405V4.51943C13.3541 4.34172 12.6173 3.88786 12.0731 3.26381C11.1964 2.69277 10.5654 1.77752 10.3651 0.710999H8.317L8.31321 11.9321C8.2679 13.1877 7.23247 14.1975 5.96662 14.1975C5.1806 14.1975 4.48908 13.808 4.05831 13.218C3.3101 12.8209 2.79619 12.038 2.79619 11.1341C2.79619 9.83691 3.85047 8.78172 5.14659 8.78172C5.38844 8.78172 5.62272 8.82331 5.84189 8.8914V6.69784C3.0607 6.75836 0.816101 9.04266 0.816101 11.8451C0.816101 13.1991 1.34135 14.432 2.19914 15.3548C3.03425 15.941 4.05452 16.289 5.15037 16.289C7.98826 16.289 10.3008 13.9744 10.3008 11.1341Z'
        fill='white'
      />
    </svg>
  );
};

export default TikTok;
