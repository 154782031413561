import { CSSProperties, useCallback, useState } from 'react';

const ImageCenceredPlaceholder = (
  props: IConProps & { text: string; textColor?: string; isround?: boolean },
) => {
  const {
    width = '100%',
    height = '100%',
    fill = '#000000',
    text,
    textColor = '#FFFFFF',
    isround,
    ...rest
  } = props;
  const [fontSize, setFontSize] = useState(16);
  const styles: CSSProperties = { backgroundColor: fill };
  if (isround) {
    styles.borderRadius = '100%';
  }
  const ref = useCallback((ref: SVGSVGElement) => {
    if (ref) {
      let newFontsize = (ref.clientWidth / 16) * 1.5;
      newFontsize = newFontsize >= 16 ? newFontsize : newFontsize + 8;
      setFontSize(newFontsize);
    }
  }, []);

  return (
    <svg
      ref={ref}
      width={width}
      height={height}
      style={styles}
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <text
        x='50%'
        y='50%'
        dominantBaseline='middle'
        textAnchor='middle'
        fill={textColor}
        fontSize={fontSize}
      >
        {text}
      </text>
    </svg>
  );
};

export default ImageCenceredPlaceholder;
