import './style.scss';

import React from 'react';

import Modal from 'components/Modal';
import { useCardsStore } from 'store/stores/AddCardStore';
import AddCard from './AddCard';

interface Props {
  isOpen?: boolean;
  className?: string;
  onClose?: () => void;
  onSubmit?: (value: any) => void;
  onErrorCallback?: (value: any) => void;
  shouldCloseOnOverlayClick?: boolean;
}

const AddCardModal: React.FC<Props> = ({
  isOpen,
  onClose,
  className,
  onSubmit,
  onErrorCallback,
  shouldCloseOnOverlayClick = true,
}) => {
  const cardsStore = useCardsStore();

  const handleClose = () => {
    cardsStore.resetCardStore();
    onClose?.();
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`${className} card-modal`}
      title={<div className='modal-header-wrap'>ADD PAYMENT METHOD</div>}
      isOpen={!!isOpen || !!cardsStore.isOpen}
      card-modal
      showHeader
      showHeaderClose={false}
      showFooter={false}
      onClose={handleClose}
    >
      {/* <div id='first-billing-method-form' style={{ display: !!visibility ? 'block' : 'none' }}>
        <div className='card-form pb-0 creditcard add-style mb-35 creditcard-alt'>
          <div className='max-holder'>
            <Form form={form} onSubmit={handleSubmit}>
              <div className='input-wrap mb-15'>
                <Input
                  hasLabel={false}
                  style={{ paddingTop: 5, fontSize: '14px' }}
                  placeholder='Name on Card'
                  name='name_on_card'
                  validations={[{ noMultipeSpace: true }, { type: 'alpha' }]}
                />
                F
              </div>
           
              <div className='mb-20 input-wrap'>
                <div className='text-input'>
                  <Input
                    hasLabel={false}
                    style={{ paddingTop: 5, fontSize: '14px' }}
                    placeholder='Credit Card Number'
                    name='card_number'
                    validations={[{ noSpace: true }]}
                  />
                </div>
              </div>
              <div className='mb-20 input-wrap cols'>
                <div className='text-input'>
                  <Input
                    hasLabel={false}
                    style={{ paddingTop: 5, fontSize: '14px' }}
                    placeholder='Exp Date (MM/YY)'
                    name='expiration'
                    validations={[{ noSpace: true }]}
                  />
                </div>
                <div className='text-input'>
                  <Input
                    hasLabel={false}
                    style={{ paddingTop: 5, fontSize: '14px' }}
                    placeholder='CVC'
                    type='number'
                    name='cvc'
                    validations={[{ noSpace: true }]}
                  />

                  <div className='img'>
                    <img src='/assets/images/svg/ico-card02.svg' alt='img description' />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='billing-card-field'>
                    <div id='card-errors' role='alert'></div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <NewButton type='submit' className='mb-20' disabled={isSubmitting}>
                  SAVE
                </NewButton>
              </div>
            </Form>
          </div>
        </div>
      </div> */}

      <AddCard
        onSubmit={onSubmit || cardsStore.onSuccessCallback}
        onError={onErrorCallback || cardsStore.onErrorCallback}
        onCancel={handleClose}
      />
    </Modal>
  );
};

export default AddCardModal;
