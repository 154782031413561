import request from 'utils/request';
import Model from '.';

class AccountModel extends Model<IUser> {
  constructor() {
    super('/user-account', 'public-1');
  }
  async requestCredit() {
    const res = await request('/add-beta-credits', {
      method: 'POST',
      data: {},
    });

    return res;
  }
}

export default new AccountModel();
