const CommentsIcon = (props: IConProps) => {
  const { width = 24, height = 24, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_1_119)'>
        <path
          d='M3 20L4.3 16.1C1.976 12.663 2.874 8.22803 6.4 5.72603C9.926 3.22503 14.99 3.43003 18.245 6.20603C21.5 8.98303 21.94 13.472 19.274 16.707C16.608 19.942 11.659 20.922 7.7 19L3 20Z'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default CommentsIcon;
