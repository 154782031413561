import SwiperGallery from 'components/Swiper/SwiperGallery';
import useSwiper from 'hooks/useSwiper';
import { ApiModels } from 'queries/apiModelMapping';
import useCreateItem from 'queries/useCreateItem';
import useListItemsM from 'queries/useListItemsM';
import { useEffect, useState } from 'react';
type Props = {
  onClose?: (galleryId?: string) => void;
};

const PostGallery = (props: Props) => {
  const { onClose, ...rest } = props;
  const swiper = useSwiper();
  const [initialSlide, setInitialSlide] = useState<number>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSecondary, setIsOpenSecondary] = useState<boolean>(false);
  const [files, setFiles] = useState<IPost[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const galleryItems = useListItemsM({
    queryKey: `${swiper?.galleryId}/swiper_gallery`,
    modelName: ApiModels.SellerMediaGAllery,
  });
  const unlock = useCreateItem({
    modelName: ApiModels.ContentUnlock,
    queryKey: 'content-unlock',
  });
  const getGallary = () => {
    swiper.callback?.();
    setIsLoading(true);
    galleryItems
      .trigger({
        options: {
          path: swiper.galleryId + '',
        },
      })
      .then(async (d) => {
        if (d.data?.length) {
          setFiles(d.data);
          setIsLoading(false);
          setIsOpen(true);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log({ e });
      });
  };
  useEffect(() => {
    if (swiper?.galleryId != null) {
      if (swiper.mediaId != null) {
        if (swiper.type === 'post') {
          unlock
            .trigger({
              data: { post_id: swiper.mediaId },
            })
            .then((data) => {
              // console.log(data, 'unlock gallery success');

              getGallary();
            })
            .catch((e) => {
              // console.log(e, 'error on unlocked gallery success');
            });
        } else {
          getGallary();
        }
      } else {
        getGallary();
      }
    }
  }, [swiper?.galleryId]);
  useEffect(() => {
    if (!!swiper?.mediaItems?.length) {
      setFiles(swiper?.mediaItems as IPost[]);
      setIsOpen(true);
    }
  }, [swiper?.mediaItems]);
  return (
    <SwiperGallery
      mediaUser={swiper.mediaUser}
      slides={files}
      initializeSlides={initialSlide}
      setInitialSlide={setInitialSlide}
      onClose={(event) => {
        if (event === 'secondaryClose') {
          setIsOpenSecondary(false);
          return;
        }
        setIsOpen(false);
        setIsOpenSecondary(false);
        swiper.resetGallery();
        setFiles([]);
      }}
      isLoading={isloading}
      onItemClick={(slideIndex: number) => {
        setIsOpenSecondary(true);
        setInitialSlide(slideIndex);
      }}
      isOpen={isOpen}
      isOpenSecondary={isOpenSecondary}
    />
  );
};

export default PostGallery;
