type Props = IConProps;

const ListingItemSK = (props: Props) => {
  return (
    <svg
      role='img'
      width='630'
      height='229'
      aria-labelledby='loading-aria'
      viewBox='0 0 630 229'
      preserveAspectRatio='none'
      {...props}
    >
      <title id='loading-aria'>Loading...</title>
      <rect
        x='0'
        y='0'
        width='100%'
        height='100%'
        clipPath='url(#generic_listing_item_clip_path)'
        style={{ fill: 'url("#generic_listing_item")' }}
      ></rect>
      <defs>
        <clipPath id='generic_listing_item_clip_path'>
          <circle cx='-36' cy='65' r='34' />
          <circle cx='-35' cy='164' r='34' />
          <circle cx='47' cy='36' r='27' />
          <rect x='87' y='22' rx='6' ry='6' width='119' height='10' />
          <rect x='89' y='38' rx='6' ry='6' width='85' height='7' />
          <rect x='0' y='75' rx='0' ry='0' width='611' height='1' />
          <circle cx='45' cy='112' r='27' />
          <rect x='85' y='98' rx='6' ry='6' width='119' height='10' />
          <rect x='87' y='114' rx='6' ry='6' width='85' height='7' />
          <rect x='-2' y='151' rx='0' ry='0' width='611' height='1' />
          <circle cx='46' cy='188' r='27' />
          <rect x='86' y='174' rx='6' ry='6' width='119' height='10' />
          <rect x='88' y='190' rx='6' ry='6' width='85' height='7' />
          <rect x='-1' y='227' rx='0' ry='0' width='611' height='1' />
        </clipPath>
        <linearGradient id='generic_listing_item'>
          <stop offset='0.599964' stopColor='var(--border-color)' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='-2; -2; 1'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='1.59996' stopColor='var(--secondary-dark)' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='-1; -1; 2'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
          <stop offset='2.59996' stopColor='var(--border-color)' stopOpacity='1'>
            <animate
              attributeName='offset'
              values='0; 0; 3'
              keyTimes='0; 0.25; 1'
              dur='2s'
              repeatCount='indefinite'
            ></animate>
          </stop>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default ListingItemSK;
