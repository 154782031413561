const VideoIcon = (props: IConProps) => {
  const { width = 16, height = 16, stroke = 'currentColor', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_1_1255)'>
        <path
          d='M10.3335 6.44441L13.8747 4.67419C13.9933 4.61495 14.125 4.58699 14.2574 4.59295C14.3898 4.59891 14.5184 4.63861 14.6312 4.70826C14.7439 4.77792 14.837 4.87523 14.9016 4.99096C14.9662 5.10669 15.0001 5.237 15.0002 5.36953V10.6304C15.0001 10.7629 14.9662 10.8933 14.9016 11.009C14.837 11.1247 14.7439 11.222 14.6312 11.2917C14.5184 11.3613 14.3898 11.401 14.2574 11.407C14.125 11.413 13.9933 11.385 13.8747 11.3257L10.3335 9.55553V6.44441Z'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1 4.88893C1 4.47637 1.16389 4.08071 1.45561 3.78899C1.74733 3.49726 2.143 3.33337 2.55556 3.33337H8.77778C9.19034 3.33337 9.586 3.49726 9.87772 3.78899C10.1694 4.08071 10.3333 4.47637 10.3333 4.88893V11.1112C10.3333 11.5237 10.1694 11.9194 9.87772 12.2111C9.586 12.5028 9.19034 12.6667 8.77778 12.6667H2.55556C2.143 12.6667 1.74733 12.5028 1.45561 12.2111C1.16389 11.9194 1 11.5237 1 11.1112V4.88893Z'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default VideoIcon;
