import { AuthStatus } from 'contexts/authContext';
import { UserAccessType } from 'enums';
import useAuthModule from 'hooks/useAuthModule';
import LogRocket from 'logrocket';
import { ApiModels } from 'queries/apiModelMapping';
import useListItemsM from 'queries/useListItemsM';
import { useEffect } from 'react';
import { useStoreBase } from 'store';
import { v4 } from 'uuid';

const InitializeGlobalData = () => {
  const authStatus = useAuthModule()?.authStatus;
  const store = useStoreBase();
  const user = store.user;
  const trigger = useListItemsM({
    queryKey: 'app_like_types',
    modelName: ApiModels.ApplikeTypes,
  });

  const userCards = useListItemsM({
    queryKey: 'user_stripe_list',
    modelName: ApiModels.Stripe,
  });
  const fetchIntialData = () => {
    const isSignedIn = authStatus === AuthStatus.SignedIn;
    const isAllowToFetchData = isSignedIn && user?.access_type === UserAccessType.approved;
    if (isAllowToFetchData) {
      userCards
        .trigger({
          options: {
            path: 'cards',
          },
        })
        .then((data: any) => {
          if (!!data?.data?.length) {
            store.setCards(data?.data || []);
          }
        })
        .catch((e) => {
          console.log({ e });
        });

      trigger
        .trigger({
          options: {},
        })
        .then((d) => {
          if (d?.data) {
            store.setLikeTypes(d?.data || []);
          }
        })
        .catch((e) => {
          console.log({ e });
        });
    }
  };
  useEffect(() => {
    fetchIntialData();

    const id = store?.user?.id + '' || v4();
    LogRocket.identify(id, {
      name: store?.user?.user_name || '',
      email: store?.user?.email || '',

      // // Add your own custom user variables here, ie:
      // subscriptionType: 'pro',
    });
    // return () => {};
  }, [store?.user?.id, authStatus]);

  return null;
};

export default InitializeGlobalData;
