import './style.scss';

import { forwardRef, useRef, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import CardForm from 'components/AddCard/CardForm';
import { default as Button } from 'components/Button';
import useStripeHooks from 'hooks/useStripeHooks';
import { ApiModels } from 'queries/apiModelMapping';
import useCreateItem from 'queries/useCreateItem';
import { useStoreBase } from 'store';

interface Props {
  className?: string;
  onSubmit?: (value: any) => void;
  onError?: (value: any) => void;
  visibility?: boolean;
  showEmailField?: boolean;
  onBeforeSubmit?: (value: any) => void;
  onCancel?: () => void;
}
type IAddCardProps = {
  onSubmit?: (value: any) => void;
  onBeforeSubmit?: (value: any) => void;
  onError?: (value: any) => void;
  onCancel?: () => void;
};
const AddCard = (props: IAddCardProps) => {
  const { onSubmit, onError, onCancel, onBeforeSubmit } = props;
  const { stripe } = useStripeHooks();

  return (
    <Elements stripe={stripe} options={{ appearance: { theme: 'night', labels: 'floating' } }}>
      <AddCardComponent
        onBeforeSubmit={onBeforeSubmit}
        onSubmit={onSubmit}
        onError={onError}
        onCancel={onCancel}
      />
    </Elements>
  );
};
const AddCardComponent = forwardRef(
  ({ onError, onSubmit, showEmailField = false, onCancel, onBeforeSubmit }: Props, ref) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const stripeRef = useRef<any>();
    const createOrUpdate = useCreateItem({
      queryKey: 'create_card_customer',
      modelName: ApiModels.Stripe,
    });
    const user = useStoreBase()?.user;
    const createOrUpdateCustomerCard = (
      token: Record<string, any>,
      values: Record<string, any>,
      apiPath = 'customer',
    ) => {
      const billingMethodData = {
        stripe_token: token?.id,
      };
      createOrUpdate
        .trigger({
          data: { ...billingMethodData } as any,
          options: {
            path: apiPath,
          },
        })
        .then((e: any) => {
          setIsSubmitting(false);
          onSubmit?.(e.data);
        })
        .catch((e) => {
          setIsSubmitting(false);
          onError?.(e);
        });
    };

    const handleSubmit = async (values: any) => {
      const stRef = stripeRef.current;
      if (stRef) {
        try {
          onBeforeSubmit?.(true);
          setIsSubmitting(true);
          const { token, error }: any = await stRef.fetchStripeToken();
          if (error !== undefined) {
            throw error.message;
          }
          if (!user?.stripe_customer_id) {
            createOrUpdateCustomerCard(token, values, 'customer');
            return;
          }
          createOrUpdateCustomerCard(token, values, 'card');
        } catch (error: any) {
          onError?.(error);
        }
      }
    };
    // const onSaveCard = () => {
    //   console.log('onSave');
    // };

    return (
      <CardForm
        ref={stripeRef}
        totalCards={0}
        mode='dark'
        isSubmitting={isSubmitting}
        submitHandler={handleSubmit}
        // functions={{
        //   onSave: () => {
        //     //   onClose();
        //   },
        // }}
        showEmailField={showEmailField}
        visibility={true}
        elementsProps={{
          Save: () => (
            <div className='modal-footer text-right has-space'>
              <Button
                variant='text'
                className='modal-btn-cancel'
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type='submit' variant='text' size='small' rounded disabled={isSubmitting}>
                SUBMIT
              </Button>
            </div>
          ),
        }}
      />
    );
  },
);

export default AddCard;
