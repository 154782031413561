const TickCircle = (props: IConProps) => {
  const { width = 18, height = 18, fill = 'currentColor', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fill={fill}
        d='M8.2,12.1c-0.3,0-0.5-0.1-0.7-0.3L5.4,9.7C5,9.3,5,8.7,5.4,8.3s1-0.4,1.4,0l1.4,1.4l3.5-3.5
				c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4l-4.2,4.2C8.7,12,8.4,12.1,8.2,12.1z'
      />
      <path
        fill={fill}
        d='M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,1.6C4.9,1.6,1.6,4.9,1.6,9
		c0,4.1,3.3,7.4,7.4,7.4c4.1,0,7.4-3.3,7.4-7.4C16.4,4.9,13.1,1.6,9,1.6z'
      />
    </svg>
  );
};

export default TickCircle;
