const SendMessageIcon = (props: IConProps) => {
  const { width = 18, height = 18, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g opacity='0.8' clipPath='url(#clip0_74_8599)'>
        <path
          d='M7.5 10.5L15.75 2.25'
          stroke={fill}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.7504 2.25L10.8754 15.75C10.8425 15.8218 10.7897 15.8827 10.7232 15.9253C10.6567 15.968 10.5794 15.9907 10.5004 15.9907C10.4214 15.9907 10.3441 15.968 10.2776 15.9253C10.2112 15.8827 10.1583 15.8218 10.1254 15.75L7.50042 10.5L2.25042 7.875C2.17861 7.84209 2.11776 7.78926 2.0751 7.72279C2.03244 7.65631 2.00977 7.57899 2.00977 7.5C2.00977 7.42101 2.03244 7.34369 2.0751 7.27721C2.11776 7.21074 2.17861 7.15791 2.25042 7.125L15.7504 2.25Z'
          stroke={fill}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_74_8599'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SendMessageIcon;
