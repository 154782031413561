import './index.scss';

import classNames from 'classnames';

export default function Loader(props: { loading?: boolean }) {
  // const loading = useAppSelector((state) => state.global?.loading);
  const loading = false;

  return (
    <div
      className={classNames('pre-loader', {
        loading: props.loading ?? loading,
      })}
    >
      <div className='pre-loader-holder'>
        <strong className='loading-logo'>
          <div className='loader loader3'>
            <div className='spinner'>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
            <svg>
              <defs>
                <filter id='goo'>
                  <feGaussianBlur in='SourceGraphic' stdDeviation='8' result='blur' />
                  <feColorMatrix
                    in='blur'
                    values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 50 -8'
                    result='goo'
                  />
                  <feBlend in='SourceGraphic' in2='goo' />
                </filter>
              </defs>
            </svg>
          </div>
        </strong>
      </div>
    </div>
  );
}
type RequestLoaderPorps = IConProps & {
  children?: any;
  isLoading: boolean;
};
export const RequestLoader = (props: RequestLoaderPorps) => {
  const {
    width = 28,
    height = 28,
    children,
    isLoading,
    className = '',

    ...rest
  } = props;
  return isLoading ? (
    <div
      className={classNames(`request_loader ${className}`, {
        loading: isLoading,
      })}
      // wwidth={width}
      // hheight={height}
    >
      <div className='loader loader3'>
        <div className='spinner'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <svg>
          <defs>
            <filter id='goo'>
              <feGaussianBlur in='SourceGraphic' stdDeviation='8' result='blur' />
              <feColorMatrix
                in='blur'
                values='1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 50 -8'
                result='goo'
              />
              <feBlend in='SourceGraphic' in2='goo' />
            </filter>
          </defs>
        </svg>
      </div>
      {/* <svg
        className='pl'
        viewBox='0 0 200 200'
        width={width}
        height={height}
        xmlns='http://www.w3.org/2000/svg'
        {...rest}
      >
        <defs>
          <linearGradient id='pl-grad1' x1='1' y1='0.5' x2='0' y2='0.5'>
            <stop offset='0%' stopColor='var(--primary)' />
            <stop offset='100%' stopColor='hsl(0,0%,20%)' />
          </linearGradient>
          <linearGradient id='pl-grad2' x1='0' y1='0' x2='0' y2='1'>
            <stop offset='0%' stopColor='var(--primary)' />
            <stop offset='100%' stopColor='#064a78' />
          </linearGradient>
        </defs>
        <circle
          className='pl__ring'
          cx='100'
          cy='100'
          r='82'
          fill='none'
          stroke='url(#pl-grad1)'
          strokeWidth='36'
          strokeDasharray='0 257 1 257'
          strokeDashoffset='0.01'
          strokeLinecap='round'
          transform='rotate(-90,100,100)'
        />
        <line
          className='pl__ball'
          stroke='url(#pl-grad2)'
          x1='100'
          y1='18'
          x2='100.01'
          y2='182'
          strokeWidth='36'
          strokeDasharray='1 165'
          strokeLinecap='round'
        />
      </svg> */}
    </div>
  ) : children ? (
    children
  ) : null;
};

type RequestCircularLoaderPorps = IConProps & {
  children?: any;
  isLoading: boolean;
};
export const RequestCircularLoader = (props: RequestLoaderPorps) => {
  const {
    width = 28,
    height = 28,
    children,
    isLoading,
    className = '',

    ...rest
  } = props;
  return isLoading ? (
    <div
      className={classNames(`circular-loader ${className}`, {
        loading: isLoading,
      })}
    >
      <div className='loader loader1'>
        <span className='loader-box' style={{ width: `${width}px`, height: `${height}px` }}></span>
      </div>
    </div>
  ) : children ? (
    children
  ) : null;
};
