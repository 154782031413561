const Mp3Icon = (props: IConProps) => {
  const { width = 175, height = 129, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 175 129'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M71.5909 27.9934L44.5693 49.2253H15.9091V79.7747H44.5693L71.5909 101.007V27.9934ZM38.8898 95.0494H7.95454C5.84487 95.0494 3.8216 94.2448 2.32983 92.8125C0.838065 91.3802 0 89.4376 0 87.4121V41.5879C0 39.5624 0.838065 37.6198 2.32983 36.1875C3.8216 34.7552 5.84487 33.9506 7.95454 33.9506H38.8898L81.0011 0.86557C81.5838 0.406926 82.2902 0.116625 83.038 0.0284649C83.7859 -0.0596952 84.5444 0.0579122 85.2253 0.367596C85.9063 0.67728 86.4816 1.1663 86.8843 1.77772C87.287 2.38914 87.5005 3.09781 87.5 3.82123V125.179C87.5005 125.902 87.287 126.611 86.8843 127.222C86.4816 127.834 85.9063 128.323 85.2253 128.632C84.5444 128.942 83.7859 129.06 83.038 128.972C82.2902 128.883 81.5838 128.593 81.0011 128.134L38.8977 95.0494H38.8898ZM146.411 126.622L135.148 115.808C142.688 109.366 148.721 101.462 152.848 92.6184C156.975 83.7744 159.103 74.191 159.091 64.5C159.101 54.3125 156.747 44.2507 152.201 35.0456C147.655 25.8404 141.031 17.723 132.809 11.2829L144.105 0.437879C153.797 8.31616 161.583 18.1297 166.919 29.194C172.255 40.2583 175.013 52.3071 175 64.5C175 89.1152 163.975 111.256 146.411 126.622ZM118.228 99.5631L106.917 88.7028C110.779 85.8518 113.906 82.1861 116.056 77.9893C118.206 73.7926 119.323 69.1771 119.318 64.5C119.318 53.5786 113.352 43.9937 104.364 38.5941L115.81 27.6039C121.835 31.8594 126.733 37.4168 130.106 43.8266C133.479 50.2363 135.234 57.3185 135.227 64.5C135.227 78.568 128.625 91.1544 118.228 99.5631Z'
        fill='#A3A5BA'
        fillOpacity='0.11'
      />
    </svg>
  );
};
export default Mp3Icon;
