const PlusIcon = (props: IConProps) => {
  const { width = 22, height = 22, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_2_62)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11 3.89581C11.3797 3.89581 11.6875 4.20362 11.6875 4.58331V17.4166C11.6875 17.7963 11.3797 18.1041 11 18.1041C10.6203 18.1041 10.3125 17.7963 10.3125 17.4166V4.58331C10.3125 4.20362 10.6203 3.89581 11 3.89581Z'
          fill={fill}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.89583 11C3.89583 10.6203 4.20364 10.3125 4.58333 10.3125H17.4167C17.7964 10.3125 18.1042 10.6203 18.1042 11C18.1042 11.3797 17.7964 11.6875 17.4167 11.6875H4.58333C4.20364 11.6875 3.89583 11.3797 3.89583 11Z'
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id='clip0_2_62'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlusIcon;
