const Tick = (props: IConProps) => {
  const { width = 14, height = 10, fill = 'currentColor', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 10'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fill={fill}
        d='M4.2,8.4C4,8.4,3.8,8.3,3.6,8.2L0.3,4.8c-0.3-0.3-0.3-0.9,0-1.2s0.9-0.3,1.2,0l2.7,2.7l6-6
        c0.3-0.3,0.9-0.3,1.2,0c0.3,0.3,0.3,0.9,0,1.2L4.8,8.2C4.7,8.3,4.4,8.4,4.2,8.4z'
      />
    </svg>
  );
};

export default Tick;
