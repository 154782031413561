const PersonCrossIcon = (props: IConProps) => {
  const { width = 16, height = 16, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_1_2178)'>
        <path
          d='M5.33334 4.66667C5.33334 5.37391 5.61429 6.05219 6.11439 6.55229C6.61449 7.05238 7.29277 7.33333 8.00001 7.33333C8.70725 7.33333 9.38553 7.05238 9.88563 6.55229C10.3857 6.05219 10.6667 5.37391 10.6667 4.66667C10.6667 3.95942 10.3857 3.28115 9.88563 2.78105C9.38553 2.28095 8.70725 2 8.00001 2C7.29277 2 6.61449 2.28095 6.11439 2.78105C5.61429 3.28115 5.33334 3.95942 5.33334 4.66667Z'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4 14V12.6667C4 11.9594 4.28095 11.2811 4.78105 10.781C5.28115 10.281 5.95942 10 6.66667 10H9'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.6667 14.6667L11.3333 11.3333'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.3333 14.6667L14.6667 11.3333'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_2178'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PersonCrossIcon;
