const HorizontalDotsIcon = (props: IConProps) => {
  const { width = 17, height = 5, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 17 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M2.05553 0.888915C3.19078 0.888915 4.11108 1.80922 4.11108 2.94447C4.11108 4.07972 3.19078 5.00003 2.05553 5.00003C0.920276 5.00003 -2.87497e-05 4.07972 -2.87001e-05 2.94447C-2.86505e-05 1.80922 0.920276 0.888915 2.05553 0.888915Z'
        fill={fill}
      />
      <path
        d='M8.05553 0.888916C9.19078 0.888916 10.1111 1.80922 10.1111 2.94447C10.1111 4.07972 9.19078 5.00003 8.05553 5.00003C6.92028 5.00003 5.99997 4.07972 5.99997 2.94447C5.99997 1.80922 6.92028 0.888916 8.05553 0.888916Z'
        fill={fill}
      />
      <path
        d='M14.0555 0.888916C15.1908 0.888916 16.1111 1.80922 16.1111 2.94447C16.1111 4.07972 15.1908 5.00003 14.0555 5.00003C12.9203 5.00003 12 4.07972 12 2.94447C12 1.80922 12.9203 0.888916 14.0555 0.888916Z'
        fill={fill}
      />
    </svg>
  );
};

export default HorizontalDotsIcon;
