import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { resetters } from 'store';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  subscriptionPackages: ITokens[];
  isLoading: boolean;
};

type Actions = {
  setSubscriptionPackages(newSubscriptionPackages: ITokens[]): void;
  setIsLoading(isLoading: boolean): void;
};
export const useStoreBase = create<State & Actions>()(
  devtools(
    immer<State & Actions>((set) => {
      resetters.push(() => set({ subscriptionPackages: [] }));
      const store = {
        subscriptionPackages: [],
        isLoading: true,
        setSubscriptionPackages: (newSubscriptionPackages: ITokens[]) => {
          set((prev) => {
            prev.subscriptionPackages = newSubscriptionPackages;
            prev.isLoading = false;
          });
        },
        setIsLoading: (isLoading: boolean) => {
          set((prev) => {
            prev.isLoading = isLoading;
          });
        },
      };

      return store;
    }),
  ),
);

export const useSubscriptionPackagesStore = createSelectorHooks<State & Actions>(useStoreBase);
