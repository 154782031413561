import { MEDIA_CROP_SIZES } from 'appconstants';
import { BackIcon, CrossIcon } from 'assets/svgs';
import SlickSlider from 'components/3d-slider';
import Image from 'components/Image';
import { RequestLoader } from 'components/Loader/SiteLoader';
import Model from 'components/Modal';
import HeaderLayout from 'components/headerlayout';
import { useEffect, useState } from 'react';
import SwiperSlider, { ISlideItem, ISwiperSliderProps } from '.';
import './swiperslider.scss';

type Props<T> = ISwiperSliderProps<T> & {
  galleryId?: string;
  isOpen?: boolean;
  isLoading?: boolean;
  isOpenSecondary?: boolean;
  slides?: T[];
  onClose?: (event?: string) => void;
  onItemClick: (index: number) => void;
  initializeSlides: number;
  setInitialSlide?: React.Dispatch<React.SetStateAction<number>>;
  mediaUser?: IMediaUser | undefined;
};

const SwiperGallery = (props: Props<ISlideItem>) => {
  const {
    galleryId,
    isLoading = false,
    onClose,
    isOpen = false,
    slides,
    isOpenSecondary,
    onItemClick,
    initializeSlides,
    setInitialSlide,
    mediaUser,
    ...rest
  } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  useEffect(() => {
    if (window.innerWidth - document.body.clientWidth >= 15 && (isOpen || isOpenSecondary)) {
      document.body.classList.add('content-scroll-preventing');
    }
    return () => {
      document.body.classList.remove('content-scroll-preventing');
    };
  }, [isOpen]);
  if (isLoading) {
    return (
      <Model
        showFooter={false}
        showHeader={!isLoading && !slides?.length}
        isOpen={isLoading}
        onClose={onClose}
        overlayClassName='swiper-custom-modal-overlay'
        className='swiper-custom-modal'
        shouldCloseOnOverlayClick={true}
        showHeaderClose={!slides?.length}
      >
        <RequestLoader isLoading={isLoading} />
      </Model>
    );
  }

  return isOpen || isOpenSecondary ? (
    <Model
      showFooter={false}
      showHeader={false}
      isOpen
      onClose={() => {
        document.body.classList.remove('content-scroll-preventing');
        onClose?.();
      }}
      overlayClassName='swiper-custom-modal-overlay'
      className='swiper-custom-modal'
      shouldCloseOnOverlayClick={true}
    >
      {!!slides.length ? (
        isOpenSecondary ? (
          <SwiperSlider
            initializeSlides={initializeSlides}
            slides={slides}
            setCurrentSlide={setCurrentSlide}
            mediaUser={mediaUser}
            setInitialSlide={setInitialSlide}
            sliderId={galleryId}
            onClose={onClose}
            {...rest}
          />
        ) : (
          <SlickSlider
            mediaUser={mediaUser}
            currentSlide={currentSlide}
            initializeSlides={initializeSlides}
            onItemClick={onItemClick}
            slides={slides}
            onClose={onClose}
            sliderId={galleryId}
            {...rest}
          />
        )
      ) : isLoading ? (
        <RequestLoader isLoading={isLoading} />
      ) : (
        <>
          <HeaderLayout>
            <div className='block-head'>
              <div className='header-left-items'>
                <div className='swiper-back'>
                  <BackIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      onClose?.();
                    }}
                  />
                </div>
                <div className='header-profile-details'>
                  <div className='profile-image'>
                    <Image
                      rounded
                      filters={MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300)}
                      src={mediaUser?.profile_thumb?.url}
                      alt={mediaUser?.profile_thumb?.url}
                    />
                  </div>
                  <div className='profile-name'>{`${mediaUser?.first_name} ${mediaUser?.last_name}`}</div>
                </div>
              </div>
              <div className='swiper-close'>
                <CrossIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onClose?.();
                  }}
                />
              </div>
            </div>
          </HeaderLayout>
          <div className='empty_notification'>
            <div className='empty_notification_wrap'>No Media...</div>
          </div>
        </>
      )}
    </Model>
  ) : null;
};

export default SwiperGallery;
