import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const PrivateLayout = () => {
  const isLoggedIn = true;
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, []);

  return (
    // <div className='private_layout h-100'>
    <Outlet />
    // </div>
  );
};

export default PrivateLayout;
