import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { resetters } from 'store';

type State = {
  seller: ISeller | null;
  sellerPosts: IPost[];
  sellerPostsObj: Record<number, IPost>;
  sellerPostIds: number[];
  sellerMediaGalleriesIds: number[];
  unViewdCount: number;
  sellerMedia: IPost[];
  sellerMediaObj: Record<number, IPost>;
  sellerMediaIds: number[];
  cursor?: { date: string; postId: number };
  sellerLockPosts: IPost[];
  sellerLockPostsIds: number[];
  sellerLockPostsObj: Record<number, IPost>;
};

type Actions = {
  setSeller(seller: Partial<ISeller>): void;
  setSellerPosts(post: IPost[]): void;
  setPaginateSellerPosts(post: IPost[]): void;
  setSellerMedia(post: IPost[]): void;
  setSellerLockPost(post: IPost[]): void;
  setPost(postId: number, post: Partial<IPost>): void;
  setMedia(postId: number, post: Partial<IPost>): void;
  addMediaFromPosts(postId: number): void;
  setCursor(cursror: { date: string; postId: number }): void;
  setPostCounter(counter: number): void;
  updateSellerLockPost(postId: number, post: Partial<IPost>): void;
};
const INITIAL_STATE = {
  sellerPostIds: [],
  sellerMediaGalleriesIds: [],
  sellerPostsObj: {},
  cursor: undefined,
  seller: null,
  sellerPosts: [],
  sellerLockPosts: [],
  sellerLockPostsIds: [],
  sellerLockPostsObj: {},
  sellerMedia: [],
  unViewdCount: 0,
  sellerMediaObj: {},
  sellerMediaIds: [],
};
export const useStoreBase = create<State & Actions>()(
  devtools(
    immer<State & Actions>((set, get) => {
      resetters.push(() => set(INITIAL_STATE));
      return {
        ...INITIAL_STATE,
        setSeller: (seller: Partial<ISeller>) => {
          set((prev) => {
            prev.seller = { ...prev.seller, ...(seller || {}) };
          });
        },
        setPost: (postId: number, post: Partial<IPost>) => {
          set((prev) => {
            const prevPosts = get().sellerPostsObj;

            if (prevPosts[postId]) {
              prev.sellerPostsObj[postId] = { ...prevPosts[postId], ...(post || {}) };
            }
          });
        },
        setMedia: (postId: number, post: Partial<IPost>) => {
          set((prev) => {
            const prevPosts = get().sellerMediaObj;

            if (prevPosts[postId]) {
              prev.sellerMediaObj[postId] = { ...prevPosts[postId], ...(post || {}) };
            }
          });
        },
        addMediaFromPosts: (postId: number) => {
          set((prev) => {
            const prevPosts = get().sellerPostsObj;
            const post = prevPosts[postId];
            if (post) {
              const prevMedia = get().sellerMediaObj;
              const newSellerMediaObj = { ...prevMedia, [postId]: { ...post } };
              prev.sellerMediaObj = newSellerMediaObj;

              prev.sellerMedia = Object.values(newSellerMediaObj);
              prev.sellerMediaIds = Object.keys(newSellerMediaObj).map(Number);
            }
          });
        },
        setCursor: (cursor) => {
          set((prev) => {
            prev.cursor = cursor;
          });
        },
        setSellerPosts: (posts: IPost[]) => {
          set((prev) => {
            const postsObj: Record<number, IPost> = {};
            const postIdd: number[] = [];
            const mediaGalleriesIds: number[] = [];
            posts.forEach((med) => {
              postsObj[med.content_post_id] = med;
              postIdd.push(med.content_post_id);
              mediaGalleriesIds.push(med.media_galleries_id);
            });
            prev.sellerMediaGalleriesIds = mediaGalleriesIds;
            prev.sellerPostsObj = postsObj;
            prev.sellerPostIds = postIdd;
            prev.sellerPosts = posts;
          });
        },
        setPaginateSellerPosts: (posts: IPost[]) => {
          set((prev) => {
            const postsObj: Record<number, IPost> = { ...(get().sellerPostsObj || {}) } || {};
            const postIdd: number[] = [...(get().sellerPostIds || [])] || [];
            const sellerPosts = [...(get().sellerPosts || [])];
            posts.forEach((med) => {
              postsObj[med.content_post_id] = med;
              postIdd.push(med.content_post_id);
              sellerPosts.push(med);
            });
            prev.sellerPostsObj = postsObj;
            prev.sellerPostIds = postIdd;
            prev.sellerPosts = sellerPosts;
          });
        },
        setSellerMedia: (posts: IPost[]) => {
          set((prev) => {
            const postsObj: Record<number, IPost> = {};
            const postIdd: number[] = [];
            posts.forEach((med) => {
              postsObj[med.content_post_id] = med;
              postIdd.push(med.content_post_id);
            });
            prev.sellerMediaObj = postsObj;
            prev.sellerMediaIds = postIdd;
            prev.sellerMedia = posts;
          });
        },
        setPostCounter: (counter: number) => {
          set((prev) => {
            prev.unViewdCount = counter;
          });
        },
        setSellerLockPost: (posts: IPost[]) => {
          set((prev) => {
            const postsObj: Record<number, IPost> = {};
            const postIdd: number[] = [];
            posts.forEach((med) => {
              postsObj[med.content_post_id] = med;
              postIdd.push(med.content_post_id);
            });
            prev.sellerLockPostsIds = postIdd;
            prev.sellerLockPostsObj = postsObj;
            prev.sellerLockPosts = posts;
          });
        },
        updateSellerLockPost: (postId: number, post: Partial<IPost>) => {
          set((prev) => {
            const prevPosts = get().sellerLockPostsObj;

            if (prevPosts[postId]) {
              prev.sellerLockPostsObj[postId] = { ...prevPosts[postId], ...(post || {}) };
            }
          });
        },
      };
    }),
  ),
);

export const useSellerDetailStore = createSelectorHooks<State & Actions>(useStoreBase);
