const PlayIcon = (props: IConProps) => {
  const { width = 60, height = 60, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path d='M45 30L22.5 42.9904L22.5 17.0096L45 30Z' fill={fill} fillOpacity='0.9' />
    </svg>
  );
};

export default PlayIcon;
