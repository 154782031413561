const PynkLogo = (props: IConProps) => {
  const { width = 26, height = 26, fill = '#fff', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 26 26'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g>
        <path
          d='M26 12.998C26 20.182 20.181 26 13.002 26C5.819 26 0 20.182 0 12.998C0 5.819 5.819 0 13.002 0C20.181 0 26 5.819 26 12.998Z'
          fill={fill}
        />
        <path
          d='M20.758 6.90906C20.705 6.84106 20.654 6.77706 20.612 6.72806C20.591 6.70406 20.576 6.68506 20.564 6.67106C20.56 6.66806 20.558 6.66506 20.556 6.66306L20.538 6.64706L20.516 6.62006C17.114 2.48806 11.016 1.81206 6.78998 5.14106C6.32098 5.51006 5.88698 5.92106 5.49298 6.36906C2.39298 9.87606 2.14198 15.2021 5.13898 19.0101C8.51298 23.2951 14.722 24.0321 19.007 20.6571C19.509 20.2611 19.971 19.8181 20.388 19.3331L20.397 19.3241L20.406 19.3141C20.846 18.8491 21.191 18.3411 21.427 17.9461C21.544 17.7501 21.633 17.5821 21.692 17.4671C21.721 17.4081 21.743 17.3631 21.757 17.3341L21.772 17.3011L21.774 17.2991C22.11 16.4651 22.034 15.7091 21.511 15.1251C20.807 14.3381 19.599 14.2711 18.813 14.9751C18.814 14.9731 18.813 14.9751 18.811 14.9781C18.801 14.9891 18.774 15.0211 18.725 15.0891C18.669 15.1671 18.605 15.2661 18.539 15.3691C18.408 15.5751 18.287 15.7831 18.244 15.8591C17.849 16.5721 17.317 17.1971 16.678 17.6991C14.978 19.0371 12.789 19.3271 10.889 18.6681C9.78598 18.2851 8.81798 17.5961 8.09698 16.6781C6.00998 14.0271 6.46598 10.1841 9.11798 8.09606C10.681 6.86706 12.656 6.52206 14.44 6.98406C15.475 7.18706 16.348 7.82106 16.943 8.36906C17.247 8.64906 17.491 8.91706 17.661 9.11806C17.747 9.21806 17.813 9.30206 17.86 9.36206C17.882 9.39106 17.9 9.41506 17.913 9.43106C18.627 10.1551 19.787 10.1981 20.552 9.51406C21.209 8.92506 21.364 7.98306 20.984 7.22706C20.978 7.21406 20.954 7.17606 20.908 7.11006C20.863 7.04806 20.811 6.97806 20.758 6.90906Z'
          fill='var(--primary)'
        />
        <path
          d='M12.753 15.926C14.4496 15.926 15.825 14.5506 15.825 12.854C15.825 11.1574 14.4496 9.78198 12.753 9.78198C11.0564 9.78198 9.68103 11.1574 9.68103 12.854C9.68103 14.5506 11.0564 15.926 12.753 15.926Z'
          fill='var(--primary)'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_418'>
          <rect width='26' height='26' fill={fill} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PynkLogo;
