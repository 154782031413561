export enum SocketState {
  Open = 'open',
  Close = 'close',
}
export enum SockeType {
  message = 'message',
  typing = 'typing',
  private_message = 'private_message',
  private_typing = 'private_typing',
  post_view = 'private_typing',
}
export enum SockeStatusesType {
  typing_start = 'typing_start',
  stop = 'stop',
  trying_on_outfits = 'Trying on outfits',
}
export enum UserAccessType {
  unapproved = 'unapproved',
  approved = 'approved',
}
