const PrivateChat = (props: IConProps) => {
  const { width = 16, height = 16, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M12.296 11.9187C12.296 11.9187 12.353 11.8779 12.4441 11.8083C13.8093 10.7527 14.6663 9.22312 14.6663 7.52015C14.6663 4.34534 11.6812 1.76904 7.99967 1.76904C4.31819 1.76904 1.33301 4.34534 1.33301 7.52015C1.33301 10.6965 4.31819 13.1839 7.99967 13.1839C8.31375 13.1839 8.8293 13.1631 9.54634 13.1216C10.4812 13.729 11.8456 14.2276 13.0397 14.2276C13.4093 14.2276 13.5834 13.9239 13.3463 13.6142C12.9863 13.1727 12.49 12.4653 12.2975 11.9179L12.296 11.9187Z'
        stroke={stroke}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default PrivateChat;
