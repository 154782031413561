import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import AuthWithPost from '../AuthWithPost';
import '../SignIn.scss';

const AuthWrapper = () => {
  useEffect(() => {
    document.getElementById('root')?.classList.add('body-default-scroll');
    document.getElementById('root')?.classList.add('login-pages-scroll');
    return () => {
      document.getElementById('root')?.classList.remove('body-default-scroll');
      document.getElementById('root')?.classList.remove('login-pages-scroll');
    };
  }, []);
  return (
    <AuthWithPost>
      <Outlet />
    </AuthWithPost>
  );
};

export default AuthWrapper;
