const PauseIcon = (props: IConProps) => {
  const { width = 24, height = 24, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 512 512'
      fill={fill}
      xmlns='http://www.w3.org/2000/svg'
      xmlSpace='preserve'
      {...rest}
    >
      <path
        d='M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M224,320
	c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z M352,320
	c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z'
      />
    </svg>
  );
};

export default PauseIcon;
