import {
  AddCardIcon,
  BellIcon,
  CollectionsIcon,
  HomeIcon,
  LogOutIcon,
  MessagesIcon,
  ProfileIcon,
  PynkLogo,
  SettingIcon,
  SubscriptionsIcon,
} from 'assets/svgs';
import classNames from 'classnames';
import AppNavLink from 'components/AppNavLink';
import Button from 'components/Button';
import Scrollbar from 'components/Scrollbar';
import useAuthModule from 'hooks/useAuthModule';
import { ReactNode, SVGProps, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import Item from './Item';
import './sidebar.scss';
import { useStoreBase } from 'store';

import useOpenClose from 'hooks/useOpenClose';
import { useClaimFreeTokensStore } from 'store/stores/ClaimFreeTokensStore';
export type ILinkType = {
  title?: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
  path: string;
  counter?: number;
};

const LINKS = [
  // { title: 'Home', icon:/ HomeIcon, path: '/', counter: 4 },
  { title: 'Notifications', icon: BellIcon, path: '/notifications' },
  // { title: 'Your Double', icon: TwoPersonIcon, path: '/double' },
  { title: 'Messages', icon: MessagesIcon, path: '/messages' },
  { title: 'Collections', icon: CollectionsIcon, path: '/collections' },
  { title: 'Subscriptions', icon: SubscriptionsIcon, path: '/subscriptions' },
  { title: 'My Wallet', icon: AddCardIcon, path: '/my-wallet' },
  { title: 'My Account', icon: ProfileIcon, path: '/my-account' },
  // { title: 'Account', icon: ProfileIcon, path: '/my-account' },
  // { title: 'Preferences', icon: PreferencesIcon, path: '/my-profile/profile' },
  { title: 'Settings', icon: SettingIcon, path: '/settings' },
  { title: 'Logout', icon: LogOutIcon, path: '/signin', clickable: true },
];
type ISidebarProps = {
  children?: ReactNode;
  className?: string;
};
const Sidebar = (props: ISidebarProps) => {
  const { children, className } = props;
  const { signOut } = useAuthModule();
  const claimFreeTokensStore = useClaimFreeTokensStore();
  const openClaimFreeTokensModal = () => claimFreeTokensStore.setIsOpen({ isOpen: true });
  const isMobileView = useMediaQuery({ query: `(min-width: ${1024}px)` });
  const user = useStoreBase().user;
  const [isOpen, onOpen, onClose] = useOpenClose(false);

  const navigate = useNavigate();
  const handleLogout = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    signOut?.(() => {
      navigate('/signin');
    });
  };
  const tokensBtnText = useMemo(() => {
    if (user) {
      return user['50_credit_used'] === 0
        ? 'GET 50 FREE TOKENS'
        : user.available_balance
        ? `${user.available_balance} TOKENS`
        : 'GET TOKENS';
    } else return '';
  }, [user]);
  const getTokensBtn = () => {
    if (user) {
      // return user['50_credit_used'] === 0
      //   ? 'GET 50 FREE TOKENS'
      //   : user.available_balance
      //   ? `${user.available_balance} TOKENS`
      //   : 'GET TOKENS';
      if (user['50_credit_used'] === 0)
        return (
          <Button
            block
            rounded
            onClick={openClaimFreeTokensModal}
            leftIcon={{
              Icon: <PynkLogo />,
            }}
          >
            <span className='btn-text'>GET 50 FREE TOKENS</span>
          </Button>
        );
      else if (user.available_balance)
        return (
          <Link to={'/my-wallet'}>
            <Button
              block
              rounded
              leftIcon={{
                Icon: <PynkLogo />,
              }}
            >
              {/* <span className='btn-text'>{user.available_balance} TOKENS</span> */}
              <span className='btn-text'>GET TOKENS</span>
            </Button>
          </Link>
        );
      else
        return (
          <Button
            block
            rounded
            leftIcon={{
              Icon: <PynkLogo />,
            }}
          >
            <span className='btn-text'>GET TOKENS</span>
          </Button>
        );
    } else
      return (
        <Button
          block
          rounded
          leftIcon={{
            Icon: <PynkLogo />,
          }}
        >
          <span className='btn-text'>GET TOKENS</span>
        </Button>
      );
  };
  // const tokensBtnText = user?.['50_credit_used'] == 0 ? 'GET 50 FREE TOKENS' : 'GET TOKENS';
  return (
    // <div className={styles.app_sidebar}>
    <>
      <div
        className={classNames('app_sidebar h-100', className, {
          open_sidebar: isMobileView,
          close_sidebar: !isMobileView,
        })}
      >
        <Scrollbar>
          <div className='app_sidebar_scroll-wrapper'>
            <ul className='side-menu-list'>
              <li>{children}</li>
              <li className='list-item'>
                <AppNavLink to={'/'} activeClassName='active_nav_item'>
                  {/* <Item title='Discover' icon={HomeIcon} path='/' counter={unViewdCount} /> */}
                  <Item title='Discover' icon={HomeIcon} path='/' />
                </AppNavLink>
              </li>
              {LINKS.map((link) => {
                const objects = link.clickable ? { onClick: handleLogout } : {};
                return (
                  <li key={link.path} className='list-item'>
                    <AppNavLink to={link.path} {...objects} activeClassName='active_nav_item'>
                      <Item {...link} />
                    </AppNavLink>
                  </li>
                );
              })}
            </ul>
            <div className='sidebar-extra-content'>
              {getTokensBtn()}
              {/* <Link to={'/my-wallet'}>
                <Button
                  block
                  rounded
                  leftIcon={{
                    Icon: <PynkLogo />,
                  }}
                >
                  <span className='btn-text'>{tokensBtnText}</span>
                </Button>
              </Link> */}
            </div>
            {/* <Switch
            isOn={isDarkMode}
            handleToggle={() => {
              onToggleTheme();
            }}
          /> */}
          </div>
        </Scrollbar>
      </div>
      {/* <PrivateChatModal isOpen={isOpen} onClose={onClose} lastName={''} firstName={''} /> */}
    </>
  );
};

export default Sidebar;
