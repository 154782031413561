import { CoinSmall, PlusIcon, PynkLogo } from 'assets/svgs';
import FiftyToken from 'assets/svgs/50Tokens';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SubscriptionButton from 'components/SubscriptionButton';
import toaster from 'lib/toaster';
import AcountModel from 'models/Account';
import React, { Fragment, useState, forwardRef, useRef, useEffect } from 'react';
import { useStoreBase } from 'store';
import useSWRMutation from 'swr/mutation';
import request from 'utils/request';

import './model.scss';
import useOpenClose from 'hooks/useOpenClose';

import { Elements } from '@stripe/react-stripe-js';
import CardForm from 'components/AddCard/CardForm';
import useStripeHooks from 'hooks/useStripeHooks';
import { ApiModels } from 'queries/apiModelMapping';
import useCreateItem from 'queries/useCreateItem';
import Checkbox from './Checkbox';
import { Link, useLocation } from 'react-router-dom';
import { useClaimFreeTokensStore } from 'store/stores/ClaimFreeTokensStore';
import FirstStep from './FirstStep';
import LastStep from './LastStep';

const ClaimFreeTokens: React.FC = () => {
  const [isDescriptionOpen, onDescriptionOpen, onDescriptionClose] = useOpenClose(true);
  const claimFreeTokensStore = useClaimFreeTokensStore();

  const [isSuccessOpen, onSuccessOpen, onSuccessClose] = useOpenClose(false);
  const { stripe } = useStripeHooks();
  const cardList = useStoreBase()?.stripeCards || [];
  const openClaimFreeTokensModal = () => claimFreeTokensStore.setIsOpen({ isOpen: true });
  const closeClaimFreeTokensModal = () => claimFreeTokensStore.setIsOpen({ isOpen: false });
  const location = useLocation();
  const user = useStoreBase().user;
  // useEffect(() => {
  //   const pathname = location.pathname;
  //   if (
  //     user &&
  //     user['50_credit_used'] === 0 &&
  //     !pathname.startsWith('/seller-profile') &&
  //     !pathname.startsWith('/messages')
  //   ) {
  //     openClaimFreeTokensModal();
  //   }
  // }, [location]);
  const { trigger: requestCredit, isMutating } = useSWRMutation(['user', 'credit'], async () => {
    return AcountModel.requestCredit();
  });
  const setUser = useStoreBase().setUser;
  const setCredit = useStoreBase()?.setCredit;
  const availableCredits = useStoreBase()?.availableCredits || 0;
  const setCards = useStoreBase()?.setCards || [];
  const isOpen = claimFreeTokensStore.isOpen;
  const shouldCloseOnOverlayClick = user?.onboarding_ux_type === 2;

  const markTheScreen = async (screen: number) => {
    await request('/user/store-events', {
      method: 'POST',
      data: {
        user_id: user?.id,
        attribute_key: `50_screen_${screen}`,
        attribute_value: 'complete',
      },
      public: true,
    }).catch((err: any) => {
      console.log({ err });
    });
  };
  useEffect(() => {
    if (isOpen) {
      markTheScreen(1);
      claimFreeTokensStore.setModalState({
        onSuccessCallback(args) {
          const newCard = { ...args, isPrimary: true };
          claimFreeTokensStore.resetCardStore();
          const newCardList = [...cardList];
          const primaryCardIndex = newCardList.findIndex((c) => c.isPrimary);
          if (primaryCardIndex !== -1) {
            newCardList[primaryCardIndex] = { ...newCardList[primaryCardIndex], isPrimary: false };
          }
          setCards([...(newCardList || []), newCard]);
          if (newCard.customer) {
            setUser({ ...user, stripe_customer_id: newCard.customer, '50_credit_used': 1 });
          } else {
            setUser({ ...user, '50_credit_used': 1 });
          }
          setCredit(availableCredits + 50);

          toaster.success('Card added successfully!');
          toaster.success(`You received 50 credits`);
        },
        onErrorCallback() {
          toaster.error('Error occurred while adding the card!');
        },
      });
    }
  }, [isOpen]);
  // const handleInitializeStoreState = () => {
  //   claimFreeTokensStore.setModalState({
  //     onSuccessCallback(args) {
  //       const newCard = { ...args, isPrimary: true };
  //       claimFreeTokensStore.resetCardStore();
  //       const newCardList = [...cardList];
  //       const primaryCardIndex = newCardList.findIndex((c) => c.isPrimary);
  //       if (primaryCardIndex !== -1) {
  //         newCardList[primaryCardIndex] = { ...newCardList[primaryCardIndex], isPrimary: false };
  //       }
  //       setCards([...(newCardList || []), newCard]);
  //       if (newCard.customer) {
  //         setUser({ stripe_customer_id: newCard.customer });
  //       }
  //       toaster.success('Card added successfully!');
  //     },
  //     onErrorCallback() {
  //       toaster.error('Error occurred while adding the card!');
  //     },
  //   });
  // };
  const handleClose = () => {
    claimFreeTokensStore.resetCardStore();
    closeClaimFreeTokensModal();
    onDescriptionOpen();
  };
  const handleSubscribeSuccess = () => {
    claimFreeTokensStore.onSuccessCallback?.();
    markTheScreen(3);
    onSuccessOpen();
  };
  const openCardModal = () => {
    onDescriptionClose();
    markTheScreen(2);
  };
  const handleLastStep = () => {
    handleClose();
    window.location.reload();
  };
  if (isOpen && isDescriptionOpen)
    return (
      <FirstStep
        isOpen={isDescriptionOpen}
        onClick={openCardModal}
        onClose={handleClose}
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      >
        <span className='subscription-heading' style={{ paddingLeft: '18px', lineHeight: '22px' }}>
          As a Beta Participant you qualify to receive 50 free tokens to spend on the site. <br />
          <br /> In order to participate you must agree to abide by the terms of service of our beta
          program and to provide feedback through a survey at the end of your Beta test. <br />
          <br />
          Click the button below to agree and to claim your tokens.
        </span>
      </FirstStep>
    );
  if (isOpen && isSuccessOpen)
    return (
      <LastStep
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        isOpen={isSuccessOpen}
        onClick={handleLastStep}
        onClose={handleLastStep}
        title='TOKENS ADDED'
        btnText='RETURN TO SITE '
      ></LastStep>
    );

  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`card-modal claim-free-tokens-modal claim-free-tokens`}
      title={
        <div className='modal-header-wrap'>
          {' '}
          <CoinSmall />
          CONFIRM YOUR AGE
        </div>
      }
      isOpen={!!isOpen}
      card-modal
      showHeader
      showHeaderClose={false}
      showFooter={false}
      onClose={handleClose}
    >
      <p className='sub-title'>
        Please add your card below and <br />
        confirm you are at least 18 years of age or older.
      </p>
      <Elements stripe={stripe} options={{ appearance: { theme: 'night', labels: 'floating' } }}>
        <AddCardComponent
          onSuccess={handleSubscribeSuccess}
          onSubmit={claimFreeTokensStore.onSuccessCallback}
          // onBeforeSubmit={onBeforeSubmit}
          // onError={onError}
          // onCancel={onCancel}
          onBeforeSubmit={() => {
            console.log('');
          }}
          onError={() => {
            claimFreeTokensStore.onErrorCallback;
          }}
          onCancel={() => {
            console.log('');
          }}
        />
      </Elements>
    </Modal>
  );
};

interface CardProps {
  className?: string;
  onSubmit?: (value: any) => void;
  onSuccess?: () => void;
  onError?: (value: any) => void;
  visibility?: boolean;
  showEmailField?: boolean;
  onBeforeSubmit?: (value: any) => void;
  onCancel?: () => void;
}
const AddCardComponent = forwardRef(
  (
    { onError, onSubmit, showEmailField = false, onCancel, onBeforeSubmit, onSuccess }: CardProps,
    ref,
  ) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const stripeRef = useRef<any>();
    const [checkboxes, setCheckboxes] = useState({ is_adult: true, agree_to_terms: true });
    const setUser = useStoreBase().setUser;

    const createOrUpdate = useCreateItem({
      queryKey: 'create_card_customer',
      modelName: ApiModels.Stripe,
    });
    const user = useStoreBase()?.user;
    const createOrUpdateCustomerCard = (
      token: Record<string, any>,
      values: Record<string, any>,
      apiPath = 'customer',
    ) => {
      const billingMethodData = {
        stripe_token: token?.id,
      };
      createOrUpdate
        .trigger({
          data: { ...billingMethodData, is_50_beta_trial: true } as any,
          options: {
            path: apiPath,
          },
        })
        .then((e: any) => {
          setIsSubmitting(false);
          onSubmit?.(e.data);
        })
        .catch((e) => {
          setIsSubmitting(false);
          toaster.error(e?.data?.message || '');

          onError?.(e);
        });
    };

    const handleSubmit = async (values: any) => {
      if (Object.values(checkboxes).some((value) => value === false)) return;
      const stRef = stripeRef.current;
      if (stRef) {
        setIsSubmitting(true);
        try {
          onBeforeSubmit?.(true);
          const { token, error }: any = await stRef.fetchStripeToken();
          if (error !== undefined) {
            throw error.message;
          }
          createOrUpdateCustomerCard(token, values, 'customer');
          setIsSubmitting(false);
          onSuccess?.();
        } catch (error: any) {
          onError?.(error);
          toaster.error(error && typeof error === 'string' ? error : 'Error while adding card.');
          setIsSubmitting(false);
        }
      }
    };
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setCheckboxes({ ...checkboxes, [e.target.value]: e.target.checked });
    };
    return (
      <CardForm
        ref={stripeRef}
        totalCards={0}
        mode='dark'
        isSubmitting={isSubmitting}
        submitHandler={handleSubmit}
        // functions={{
        //   onSave: () => {
        //     //   onClose();
        //   },
        // }}
        showEmailField={showEmailField}
        visibility={true}
        elementsProps={{
          Save: () => (
            <div className='modal-footer text-right has-space' style={{ gap: '35px' }}>
              <Checkbox
                value={'is_adult'}
                isActive={checkboxes.is_adult}
                onChange={handleCheckboxChange}
              >
                I certify that I am at least <span className='terms-link'>18 years or older.</span>
              </Checkbox>
              <Checkbox
                value={'agree_to_terms'}
                isActive={checkboxes.agree_to_terms}
                onChange={handleCheckboxChange}
              >
                I agree to to abide by all of the relevant{' '}
                <Link to={'/terms'} className='terms-link'>
                  terms of service.
                </Link>
              </Checkbox>
              <Button
                rounded
                block
                type='submit'
                disabled={
                  isSubmitting || Object.values(checkboxes).some((value) => value === false)
                }
                loading={isSubmitting}
                className={`btn-subscribe `}
                style={{
                  border: 'none',
                  padding: '22px',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'relative',
                  borderRadius: '50px',
                }}
              >
                <Fragment>
                  {/* <span style={{ position: 'absolute', left: 0, transform: 'translate(15px, 0)' }}>
                    <PynkLogo height={40} width={40} />{' '}
                  </span> */}
                  <span
                    className='subscription-btn-text'
                    style={{
                      display: 'flex',
                      gap: '10px',
                      fontSize: '22px',
                    }}
                  >
                    <span style={{ position: 'relative', top: 2, color: 'white' }}>
                      YES, I AM AT LEAST 18 YEARS OLD
                    </span>
                  </span>
                </Fragment>
              </Button>
              {/* <Button
                variant='text'
                className='modal-btn-cancel'
                onClick={onCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button type='submit' variant='text' size='small' rounded disabled={isSubmitting}>
                SUBMIT
              </Button> */}
            </div>
          ),
        }}
      />
    );
  },
);
export default ClaimFreeTokens;
