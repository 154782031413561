import { Tick } from 'assets/svgs';
import './index.scss';

import { ReactNode } from 'react';
import { v4 } from 'uuid';

type IRadioProps = {
  className?: string;
  text?: ReactNode;
  isActive?: boolean;
  id?: string;
  onChange?: (e: any) => void;
  value: number | string;
};

const Checkbox = (props: IRadioProps) => {
  const { className, text, isActive, id = v4(), onChange, value } = props;
  return (
    <label className={`custom-checkbox-label ${className}`} htmlFor={id}>
      <input type='checkbox' value={`${value}`} checked={isActive} onChange={onChange} id={id} />
      <span className='custom-fake-checkbox'>
        <Tick />
      </span>
      {!!text ? <span className='custom-checkbox-text'>{text}</span> : null}
    </label>
  );
};

export default Checkbox;
