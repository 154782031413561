import { ReactNode } from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';

type Props = NavLinkProps & {
  children: ReactNode;
  activeClassName?: string;
};

const Index = (props: Props) => {
  const { children, activeClassName = '', className, ...rest } = props;
  return (
    <NavLink
      {...rest}
      className={({ isActive, isPending }) => {
        if (typeof className === 'function') {
          return className({ isActive, isPending });
        }
        return isPending
          ? `${pending} ${className}`
          : isActive
          ? `${activeClassName} ${className} active`
          : '';
      }}
    >
      {children}
    </NavLink>
  );
};

export default Index;
