import { CrossIcon, OnlyClonesmallLogo, ProfileTickIcon } from 'assets/svgs';
import { Link } from 'react-router-dom';
type IMenuHeader = {
  id?: string;
  name: string;
  username: string;
  fans?: number;
  following?: number;
  isProfileVerified?: boolean;
  img?: string;
  onClose?: () => void;
  onProfileImageClick?: (item: any) => void;
};
const MobileMenuHeader = (props: IMenuHeader) => {
  const {
    name = 'Gat Parker',
    username = 'gat_arker',
    isProfileVerified = true,
    fans = 2,
    following = 24,
    img = '/assets/images/pynk_default.png',
    onClose,
    onProfileImageClick,
  } = props;
  return (
    <div className='mobile_menu_header'>
      <div className='mobile_menu_header_wrap'>
        {/* <div className='mobile_image_user' onClick={() => onProfileImageClick?.(props)}>
          <Image filters={MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300)} src={img} />
        </div> */}
        <strong className='side-logo'>
          <Link to='/'>
            {/* <AppLogo text='onlyClone' className='' /> */}
            <OnlyClonesmallLogo className='' />
          </Link>
        </strong>
        <div className='menu-close' onClick={onClose}>
          <CrossIcon />
        </div>
      </div>
      <div className='menu_name'>
        <strong className='menu_name_holder'>{name}</strong>{' '}
        {isProfileVerified ? (
          <span className='name-verified'>
            <ProfileTickIcon />
          </span>
        ) : null}
      </div>
      <div className='menu_username'>@{username}</div>
      <ul className='menu_fans_following'>
        <li>{fans} Fans</li>
        <li>{following} Following</li>
      </ul>
    </div>
  );
};

export default MobileMenuHeader;
