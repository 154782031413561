import CrossIcon from 'assets/svgs/CrossIcon';
import { RequestLoader } from 'components/Loader/SiteLoader';
import Model from 'components/Modal';
import usePostSwiper from 'hooks/usePostSwiper';
import { ApiModels } from 'queries/apiModelMapping';
import useCreateItem from 'queries/useCreateItem';
import useListItemsM from 'queries/useListItemsM';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import SwiperSlider from '.';

type Props = {
  onClose?: (galleryId?: string) => void;
};

const PostFeedSlider = (props: Props) => {
  const { onClose, ...rest } = props;
  const swiper = usePostSwiper();
  const [initialSlide, setInitialSlide] = useState<number>(0);

  // sellerPostIds
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const [isloading, setIsLoading] = useState(false);
  const sliderIdRef = useRef(v4());

  const galleryItems = useListItemsM({
    queryKey: `${swiper?.galleryId}/swiper_gallery`,
    modelName: ApiModels.SellerMediaGAllery,
  });

  const unlock = useCreateItem({
    modelName: ApiModels.ContentUnlock,
    queryKey: 'content-unlock',
  });
  const getGallary = () => {
    swiper.callback?.();
    setIsLoading(true);
    galleryItems
      .trigger({
        options: {
          path: swiper.galleryId + '',
        },
      })
      .then(async (d) => {
        if (d.data?.length) {
          // setFiles(d.data);
          // setFiles([
          //   {
          //     media_gallery_id: 4,
          //     media_gallery_item_id: 8,
          //     is_cover: 1,
          //     sort_order: 1,
          //     media_type_id: 'image',
          //     image_media_item: {
          //       id: 'bd27ef2e-d692-4b3f-ae4d-51b0fc5c461c',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/Pure-Synth2/uploads/1-bd27ef2e-d692-4b3f-ae4d-51b0fc5c461c.jpg',
          //       name: '1.jpg',
          //       size: 168761,
          //       type: 'image/jpeg',
          //       status: 'completed',
          //       originalFile: {
          //         path: '1.jpg',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     image_width: 768,
          //     image_height: 1152,
          //     audio_media_item: null,
          //     video_media_item: null,
          //     media_duration: null,
          //   },
          //   {
          //     media_gallery_id: 4,
          //     media_gallery_item_id: 9,
          //     is_cover: null,
          //     sort_order: 2,
          //     media_type_id: 'video',
          //     image_media_item: null,
          //     image_width: null,
          //     image_height: null,
          //     audio_media_item: null,
          //     video_media_item: {
          //       id: '6229c769-0ee9-4c3e-adf3-00b4ed4458cc',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/Pure-Synth2/uploads/v2-6229c769-0ee9-4c3e-adf3-00b4ed4458cc.mp4',
          //       name: 'v2.mp4',
          //       size: 6796739,
          //       type: 'video/mp4',
          //       status: 'completed',
          //       originalFile: {
          //         path: 'v2.mp4',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     media_duration: '8',
          //   },
          //   {
          //     media_gallery_id: 4,
          //     media_gallery_item_id: 10,
          //     is_cover: null,
          //     sort_order: 3,
          //     media_type_id: 'image',
          //     image_media_item: {
          //       id: '6520a6f1-7bbf-44fc-8eb1-a5779ebf40a8',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/Pure-Synth2/uploads/1-6520a6f1-7bbf-44fc-8eb1-a5779ebf40a8.jpg',
          //       name: '1.jpg',
          //       size: 168761,
          //       type: 'image/jpeg',
          //       status: 'completed',
          //       originalFile: {
          //         path: '1.jpg',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     image_width: 768,
          //     image_height: 1152,
          //     audio_media_item: null,
          //     video_media_item: null,
          //     media_duration: null,
          //   },
          //   {
          //     media_gallery_id: 4,
          //     media_gallery_item_id: 11,
          //     is_cover: null,
          //     sort_order: 4,
          //     media_type_id: 'video',
          //     image_media_item: null,
          //     image_width: null,
          //     image_height: null,
          //     audio_media_item: null,
          //     video_media_item: {
          //       id: 'da7dd047-fc00-4376-bbc2-9a2ad93a6c8b',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/file_example_MP4_640_3MG-da7dd047-fc00-4376-bbc2-9a2ad93a6c8b.mp4',
          //       name: 'file_example_MP4_640_3MG.mp4',
          //       size: 3114374,
          //       type: 'video/mp4',
          //       status: 'completed',
          //       originalFile: {
          //         path: 'file_example_MP4_640_3MG.mp4',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     media_duration: '30',
          //   },
          // ]);
          // setFiles([
          //   {
          //     media_gallery_id: 576,
          //     media_gallery_item_id: 1926,
          //     is_cover: 1,
          //     sort_order: 0,
          //     media_type_id: 'image',
          //     image_media_item: {
          //       id: 'baee87d3-7d64-496c-b6bc-6c3b3917ad15',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/30bdd12b388c-18bf-f07b-a83d9fe7b82b.jpeg',
          //       name: '30bdd12b-388c-18bf-f07b-a83d9fe7b82b.jpeg',
          //       size: 2089443,
          //     },
          //     image_width: 640,
          //     image_height: 842,
          //     audio_media_item: [],
          //     video_media_item: [],
          //     media_duration: '',
          //   },
          //   {
          //     media_gallery_id: 7,
          //     media_gallery_item_id: 18,
          //     is_cover: 1,
          //     sort_order: null,
          //     media_type_id: 'audio',
          //     image_media_item: {
          //       id: '3ede828a-2f9f-4fa9-a2c8-03a2fd052067',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/2-3ede828a-2f9f-4fa9-a2c8-03a2fd052067.jpg',
          //       name: '2.jpg',
          //       size: 193866,
          //       type: 'image/jpeg',
          //       status: 'completed',
          //       originalFile: {
          //         path: '2.jpg',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     image_width: null,
          //     image_height: null,
          //     audio_media_item: {
          //       id: 'efa6c0bb-4ba0-45f6-b420-1ea77a8841df',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/file_example_MP3_700KB-efa6c0bb-4ba0-45f6-b420-1ea77a8841df.mp3',
          //       name: 'file_example_MP3_700KB.mp3',
          //       size: 733645,
          //       type: 'audio/mpeg',
          //       status: 'completed',
          //       originalFile: {
          //         path: 'file_example_MP3_700KB.mp3',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     video_media_item: null,
          //     media_duration: '42',
          //   },
          //   {
          //     media_gallery_id: 7,
          //     media_gallery_item_id: 19,
          //     is_cover: null,
          //     sort_order: 2,
          //     media_type_id: 'video',
          //     image_media_item: null,
          //     image_width: null,
          //     image_height: null,
          //     audio_media_item: null,
          //     video_media_item: {
          //       id: 'aaf5ed1c-d401-45f0-913e-9efbc900b83a',
          //       url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/v2-aaf5ed1c-d401-45f0-913e-9efbc900b83a.mp4',
          //       name: 'v2.mp4',
          //       size: 6796739,
          //       type: 'video/mp4',
          //       status: 'completed',
          //       originalFile: {
          //         path: 'v2.mp4',
          //       },
          //       uploadingProgress: 100,
          //     },
          //     media_duration: '8',
          //   },
          // ]);
          setIsLoading(false);
          setIsOpen(true);
        }
      })
      .catch((e) => {
        setIsLoading(false);
        // console.log({ e });
      });
  };
  useEffect(() => {
    if (swiper?.galleryId != null) {
      const initialSlideIndex = swiper.mediaItems?.findIndex(
        (item) => item.media_galleries_id === swiper.galleryId,
      );
      if (typeof initialSlideIndex === 'number') setInitialSlide(initialSlideIndex);
      else setInitialSlide(0);
      if (swiper.mediaId != null) {
        if (swiper.type === 'post') {
          unlock
            .trigger({
              data: { post_id: swiper.mediaId },
            })
            .then((data) => {
              // console.log(data, 'unlock gallery success');

              getGallary();
            })
            .catch((e) => {
              // console.log(e, 'error on unlocked gallery success');
            });
        } else {
          getGallary();
        }
      } else {
        getGallary();
      }
    }
  }, [swiper?.galleryId]);
  console.log({ swiper });
  useEffect(() => {
    console.log({ mediaItems: swiper?.mediaItems });
    if (!!swiper?.mediaItems?.length) {
      setFiles(swiper?.mediaItems as IPost[]);
      setIsOpen(true);
    }
  }, [swiper?.mediaItems]);
  useEffect(() => {
    if (window.innerWidth - document.body.clientWidth >= 15 && isOpen) {
      document.body.classList.add('content-scroll-preventing');
    }
    return () => {
      document.body.classList.remove('content-scroll-preventing');
    };
  }, [isOpen]);
  if (isloading) {
    return (
      <Model
        showFooter={false}
        showHeader={!isloading && !files?.length}
        isOpen={isloading}
        onClose={onClose}
        overlayClassName='swiper-custom-modal-overlay'
        className='swiper-custom-modal'
        shouldCloseOnOverlayClick={true}
        showHeaderClose={!files?.length}
      >
        <RequestLoader isLoading={isloading} />
      </Model>
    );
  }

  return (
    <div>
      {' '}
      <Model
        showFooter={false}
        showHeader={false}
        isOpen={isOpen}
        onClose={() => {
          console.log('closed');
        }}
        overlayClassName='swiper-custom-modal-overlay feeds-slider-item'
        className=''
        shouldCloseOnOverlayClick={true}
      >
        <div className='swiper_actions'>
          <div className='swiper-close'>
            <CrossIcon
              onClick={(e) => {
                onClose?.();
                swiper.resetGallery();
                setFiles([]);
                setIsOpen(false);
              }}
            />
          </div>
        </div>
        <SwiperSlider
          slides={files}
          sliderId={sliderIdRef.current}
          initializeSlides={initialSlide}
          setInitialSlide={setInitialSlide}
          onClose={() => {
            onClose?.();
            swiper.resetGallery();
            setFiles([]);
            setIsOpen(false);
          }}
          {...rest}
        />
      </Model>
    </div>
  );
};

export default PostFeedSlider;
