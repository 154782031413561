const CollectionsIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g>
        <path
          fill={stroke}
          d='M26.473,18.421c-0.078-0.235-0.22-0.442-0.402-0.595c-0.192-0.163-0.426-0.268-0.67-0.303l-2.377-0.344
		l-1.063-2.142c-0.109-0.226-0.279-0.413-0.492-0.546c-0.42-0.261-0.974-0.265-1.406,0c-0.209,0.13-0.377,0.314-0.489,0.54
		L18.51,17.18l-2.373,0.344c-0.25,0.033-0.482,0.139-0.664,0.291c-0.191,0.16-0.336,0.371-0.416,0.615
		c-0.076,0.233-0.086,0.482-0.023,0.737c0.06,0.235,0.184,0.451,0.361,0.626l1.721,1.67l-0.407,2.365
		c-0.041,0.248-0.011,0.501,0.084,0.723c0.095,0.23,0.252,0.428,0.44,0.563c0.203,0.151,0.442,0.239,0.789,0.26
		c0.215,0,0.43-0.052,0.617-0.151l2.129-1.115l2.123,1.113c0.191,0.102,0.407,0.155,0.625,0.155c0.02,0,0.07-0.002,0.09-0.002
		c0.25-0.019,0.487-0.104,0.69-0.252c0.2-0.146,0.354-0.345,0.447-0.57c0.094-0.227,0.122-0.477,0.081-0.731l-0.405-2.355
		l1.724-1.674c0.176-0.174,0.299-0.393,0.358-0.63C26.561,18.925,26.553,18.675,26.473,18.421z M22.871,20.529
		c-0.207,0.201-0.303,0.492-0.253,0.775l0.34,1.975l-1.783-0.936c-0.254-0.133-0.559-0.133-0.813,0l-1.783,0.936l0.34-1.975
		c0.049-0.283-0.046-0.574-0.253-0.775l-1.438-1.396l1.989-0.287c0.285-0.041,0.531-0.221,0.659-0.479l0.893-1.803l0.894,1.803
		c0.128,0.258,0.374,0.438,0.659,0.479l1.988,0.287L22.871,20.529z'
        />
        <path
          fill={stroke}
          d='M12.537,17.82c-0.192-0.161-0.424-0.266-0.666-0.299l-2.378-0.345L8.43,15.031
		c-0.11-0.223-0.28-0.409-0.491-0.541c-0.422-0.261-0.979-0.264-1.406,0c-0.21,0.13-0.379,0.316-0.491,0.539l-1.063,2.147
		L2.606,17.52c-0.247,0.035-0.479,0.139-0.67,0.297c-0.19,0.16-0.332,0.369-0.412,0.611c-0.077,0.238-0.085,0.491-0.026,0.729
		c0.059,0.24,0.185,0.46,0.365,0.636l1.719,1.67l-0.408,2.365c-0.04,0.245-0.011,0.493,0.085,0.728
		c0.096,0.229,0.253,0.426,0.442,0.562c0.203,0.149,0.442,0.237,0.786,0.258c0.214,0,0.427-0.051,0.619-0.151l2.128-1.115
		l2.123,1.113c0.191,0.102,0.408,0.155,0.625,0.155c0.02,0,0.071-0.002,0.094-0.005c0.248-0.018,0.486-0.104,0.688-0.25
		c0.202-0.145,0.358-0.344,0.451-0.572c0.095-0.232,0.123-0.484,0.08-0.73l-0.405-2.356l1.723-1.674
		c0.175-0.175,0.3-0.392,0.36-0.636c0.059-0.24,0.05-0.491-0.027-0.727C12.869,18.189,12.726,17.979,12.537,17.82z M9.337,20.529
		c-0.207,0.201-0.301,0.49-0.252,0.775l0.339,1.975l-1.783-0.936c-0.255-0.133-0.558-0.133-0.813,0l-1.784,0.936l0.34-1.975
		c0.049-0.283-0.045-0.574-0.253-0.775l-1.438-1.396l1.99-0.287c0.285-0.041,0.531-0.221,0.659-0.479l0.892-1.803l0.893,1.803
		c0.128,0.258,0.374,0.438,0.659,0.479l1.989,0.287L9.337,20.529z'
        />
        <path
          fill={stroke}
          d='M16.746,13.709c0.02,0,0.072-0.002,0.095-0.005c0.248-0.017,0.487-0.103,0.687-0.248
		c0.201-0.144,0.357-0.344,0.453-0.577c0.097-0.235,0.123-0.491,0.078-0.727l-0.404-2.357l1.722-1.671
		c0.176-0.173,0.301-0.392,0.364-0.642c0.058-0.245,0.047-0.499-0.026-0.713c-0.075-0.241-0.219-0.454-0.413-0.617
		c-0.189-0.158-0.419-0.261-0.662-0.296l-2.38-0.345l-1.063-2.146c-0.108-0.222-0.278-0.41-0.494-0.543
		c-0.42-0.259-0.968-0.264-1.402,0.002c-0.21,0.13-0.379,0.316-0.491,0.54l-1.063,2.146L9.372,5.855
		c-0.247,0.034-0.479,0.137-0.67,0.297C8.51,6.312,8.368,6.523,8.291,6.759C8.213,6.997,8.204,7.25,8.264,7.492
		c0.06,0.242,0.186,0.462,0.364,0.634l1.719,1.67L9.94,12.161c-0.04,0.244-0.011,0.494,0.084,0.728
		c0.095,0.225,0.249,0.421,0.453,0.569c0.201,0.144,0.438,0.229,0.778,0.25c0.213,0,0.425-0.052,0.617-0.152L14,12.441l2.123,1.112
		C16.314,13.655,16.529,13.709,16.746,13.709z M13.593,10.678l-1.784,0.935l0.34-1.975c0.049-0.285-0.045-0.575-0.252-0.776
		L10.46,7.466l1.989-0.288c0.285-0.041,0.531-0.22,0.659-0.478L14,4.898l0.893,1.801c0.129,0.258,0.375,0.437,0.658,0.478
		l1.989,0.289l-1.437,1.396c-0.207,0.201-0.303,0.491-0.254,0.775l0.34,1.975l-1.783-0.934
		C14.151,10.544,13.848,10.544,13.593,10.678z'
        />
      </g>
    </svg>
  );
};

export default CollectionsIcon;
