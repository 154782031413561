import { LOCAL_IMAGES, MEDIA_CROP_SIZES } from 'appconstants';
import { AvatarNameIcon, BackIcon, CrossIcon, PlayCircle, PlayIcon } from 'assets/svgs';
import Image from 'components/Image';
import HeaderLayout from 'components/headerlayout';
import EffectCarousel from 'lib/effect-carousel';
import { forwardRef, useEffect, useRef } from 'react';
import { Swiper } from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/virtual';
import { v4 } from 'uuid';
import './effect-carousel.scss';

export type ISlideItem = Partial<IPost>;
// export type ISlideItem = {
//   url: string;
//   width?: number;
//   height?: number;
//   fallbackUrl?: string;
//   fallbackComponent?: ReactNode;
//   id?: number;
//   duration?: number;
//   name?: string;
//   type?: string;
// };
export type ISwiperSliderProps<T> = {
  container?: Partial<Swiper>;
  slide?: Partial<any>;
  slides: T[];
  renderItem?: (slideItem?: T) => void;
  onItemClick: (index: number) => void;
  className?: string;
  sliderId?: string;
  onClose?: () => void;
  currentSlide?: number;
  initializeSlides: number;
  mediaUser?: IMediaUser | undefined;
};

const SwiperSlider = (props: ISwiperSliderProps<ISlideItem>, ref: any) => {
  const {
    onClose,
    slide = {},
    slides = [],
    renderItem,
    sliderId,
    currentSlide,
    onItemClick,
    initializeSlides,
    mediaUser,
  } = props;
  const swiperElRef = useRef<any>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const isMounted = useRef<boolean>(false);
  const initialize = useRef(false);
  const sliderIdRef = useRef(sliderId || v4());

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;

      swiperElRef.current = new Swiper('.swiper', {
        modules: [Navigation, Pagination, EffectCarousel],
        effect: 'carousel',
        grabCursor: true,
        allowTouchMove: true,
        initialSlide: initializeSlides,
        slidesPerView: 'auto',
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.swiper-pagination',
        },
        on: {
          slideChange(swiper: any) {
            const sliderId = sliderIdRef.current;
            const swipercontainer = document.querySelector(`#slider_id${sliderId}`);
            const pIndex = swiper.previousRealIndex;
            if (swipercontainer) {
              const prevslide = swipercontainer.querySelector(`#slider_${pIndex}__${sliderId}`);
              const audioel = prevslide?.querySelector('audio');
              const videoel = prevslide?.querySelector('video');
              if (audioel) {
                audioel.pause();
              }
              if (videoel) {
                videoel.pause();
              }
            }
          },
        },
      });

      initialize.current = true;
      return () => {
        if (swiperElRef && !isMounted.current) {
          swiperElRef.current?.destroy();

          initialize.current = false;
        }
      };
    }
  }, [initializeSlides]);
  useEffect(() => {
    if (!swiperElRef.current) return;
    swiperElRef.current.slideTo(currentSlide, 200);
  }, [currentSlide]);
  const getSlides: any = () => {
    const sliderId = sliderIdRef.current;
    return slides?.map((sl: any, index: any) => {
      const {
        image_media_item,
        video_media_item,
        audio_media_item,
        media_type_id,
        image_width: wwidth = MEDIA_CROP_SIZES.POST.width,
        image_height: hheight = MEDIA_CROP_SIZES.POST.height,
        ...rest
      } = sl;
      const { url: thumb = LOCAL_IMAGES.suggestion_placeholder, name } = image_media_item || {};

      // const isImage = attrAccept({ name: sl.name, type: sl.type }, 'image/*');
      // const isAudio = attrAccept({ name: sl.name, type: sl.type }, 'audio/*');
      // const isVideo = attrAccept({ name: sl.name, type: sl.type }, 'video/*');
      const isImage = media_type_id === 'image';
      const isAudio = media_type_id === 'audio';
      const isVideo = media_type_id === 'video';

      if (renderItem) {
        return renderItem({ slide: sl, isImage, isVideo: false, isAudio: false } as any);
      }
      if (isVideo) {
        return (
          <div
            key={thumb + index}
            id={`slider_${index}__${sliderId}`}
            className='swiper-slide swipe-slide-video'
            onClick={() => onItemClick(index)}
          >
            <div className='swiper-carousel-animate-opacity'>
              <Image filters={MEDIA_CROP_SIZES.POST} src={thumb} alt={thumb} />
              <div className='swiper-play-btn'>
                <PlayIcon />
              </div>
            </div>
          </div>
        );
      }
      if (isAudio) {
        const { url, name = 'gallery_audio' } = audio_media_item;
        console.log({ audio_media_item });
        if (!url) return null;
        const player = audioRef?.current;
        const time = new Date((player?.duration || 0) * 1000).toISOString().substr(11, 8) || 0;
        return (
          <div
            key={url + index}
            id={`slider_${index}__${sliderId}`}
            className='swiper-slide  swipe-slide-audio'
            onClick={() => onItemClick(index)}
          >
            <div className='swiper-carousel-animate-opacity audio_background'>
              <Image filters={MEDIA_CROP_SIZES.POST} src={thumb} alt='assets/images/post1.png' />
              <div className='slide-content'>
                <audio src={url} controls className='audio_player' ref={audioRef} />

                <div className='audio_player_content align-bottom'>
                  <div className='audio_player_content__wrap'>
                    <div className='audio_player_content__left'>
                      <div className='player_button'>
                        <PlayCircle />
                      </div>
                      <div className='audio_title'>
                        <span className='title'>{name}</span>
                        <span className='username'>{`${mediaUser?.first_name} ${mediaUser?.last_name}`}</span>
                      </div>
                    </div>
                    <span className='audio_player_content_duration'>{time}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div
          key={thumb + index}
          id={`slider_${index}__${sliderId}`}
          className='swiper-slide  swipe-slide-image'
          onClick={() => onItemClick(index)}
        >
          <div className='swiper-carousel-animate-opacity'>
            <Image
              filters={{ width: wwidth, height: hheight }}
              src={thumb}
              alt={name}
              wrapperClasses='slider_img_placeholder '
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className='swiper' id={`slider_id${sliderIdRef.current}`}>
      <div className='swiper_actions'>
        <HeaderLayout>
          <div className='block-head'>
            <div className='header-left-items'>
              <div
                className='swiper-back'
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  swiperElRef.current?.destroy();
                  onClose?.();
                }}
              >
                <BackIcon />
              </div>
              <div className='header-profile-details'>
                <div className='profile-image'>
                  <Image
                    rounded
                    filters={MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300)}
                    src={mediaUser?.profile_thumb?.url}
                    alt={mediaUser?.profile_thumb?.url}
                  />
                </div>
                <div className='profile-name'>{`${mediaUser?.first_name} ${mediaUser?.last_name}`}</div>
              </div>
            </div>
            <div className='swiper-close'>
              <CrossIcon
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  swiperElRef.current?.destroy();
                  onClose?.();
                }}
              />
            </div>
          </div>
        </HeaderLayout>
      </div>
      <div className='swiper-wrapper'>{getSlides()}</div>
      {/* <div className='swiper-button-prev swiper-button'>
        <ChevronLeftIcon />
      </div>
      <div className='swiper-button-next swiper-button'>
        <ChevronRightIcon />
      </div> */}
      <div className='swiper-footer-logo'>
        <Image
          src={mediaUser?.profile_thumb?.url}
          fallbackComponent={<AvatarNameIcon text={mediaUser?.first_name || 'Inco'} />}
          alt='/assets/images/defaultIcon.png'
          rounded
        />
      </div>
    </div>
  );
};
export default forwardRef(SwiperSlider);
