const PlayCircle = (props: IConProps) => {
  const { width = 50, height = 50, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect x='1.5' y='1.5' width='47' height='47' rx='23.5' stroke={fill} strokeWidth='3' />
      <path d='M37.5 25.5L18.75 36.3253L18.75 14.6747L37.5 25.5Z' fill={fill} fillOpacity='0.9' />
    </svg>
  );
};

export default PlayCircle;
