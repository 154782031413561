const MuteIcon = (props: IConProps) => {
  const { width = 16, height = 16, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_4_1510)'>
        <path
          d='M6.23099 3.56867C6.37099 3.48267 6.51633 3.40467 6.66699 3.33333C6.66699 2.97971 6.80747 2.64057 7.05752 2.39052C7.30756 2.14048 7.6467 2 8.00033 2C8.35395 2 8.69309 2.14048 8.94313 2.39052C9.19318 2.64057 9.33366 2.97971 9.33366 3.33333C10.0993 3.69535 10.7519 4.25888 11.2217 4.96353C11.6915 5.66818 11.9606 6.48738 12.0003 7.33333V9.33333M11.3337 11.3333H2.66699C3.03028 11.1276 3.3402 10.8395 3.57178 10.4921C3.80337 10.1447 3.95016 9.7478 4.00033 9.33333V7.33333C4.04196 6.44552 4.33641 5.58809 4.84899 4.862'
          stroke='var(--link-hover)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6 11.3333V12C6 12.5304 6.21071 13.0391 6.58579 13.4142C6.96086 13.7893 7.46957 14 8 14C8.53043 14 9.03914 13.7893 9.41421 13.4142C9.78929 13.0391 10 12.5304 10 12V11.3333'
          stroke='var(--link-hover)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2 2L14 14'
          stroke='var(--link-hover)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_4_1510'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MuteIcon;
