import { AvatarNameIcon, ProfileTickIcon } from 'assets/svgs';
import classNames from 'classnames';

import { LOCAL_IMAGES, MEDIA_CROP_SIZES } from 'appconstants';
import AvatarStatus from 'components/AvatarImage';
import Image from 'components/Image';
import React from 'react';
import './coverprofile.scss';

interface Props {
  className?: string;
  img?: string;
  name?: string;
  onClick?: () => void;
  btnText?: string;
  coverImg?: string;
  username?: string;
  isProfileVerified?: boolean;
  isOnline?: boolean;
  dropdownMenu?: JSX.Element;
  coverFilters?: imageEditorProps;
  profileFilters?: imageEditorProps;
}

const ProfileCard: React.FC<Props> = (Props) => {
  const {
    className,
    img,
    name,
    coverImg,
    onClick,
    username,
    isProfileVerified,
    isOnline = true,
    dropdownMenu,
    profileFilters = MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300),
    coverFilters = MEDIA_CROP_SIZES.PROFILE_HEADER,
  } = Props;
  return (
    <div className={classNames(className, 'profile_card')}>
      <div onClick={() => onClick?.()} className='profile_card_wrap'>
        <div className='bg-img'>
          <Image
            filters={coverFilters}
            src={coverImg}
            fallbackUrl={LOCAL_IMAGES.suggestion_placeholder}
          />
        </div>
        <div className='suggested-user-details'>
          <div className='image-holder'>
            <AvatarStatus
              filters={profileFilters}
              isActive={isOnline}
              src={img}
              // fallbackUrl={'/assets/images/default-profile-img.svg'}
              fallbackComponent={<AvatarNameIcon text={name || 'Incongnito User'} />}
            />
          </div>
          <div className='text-holder'>
            <strong className='title'>
              <span className='suggested-user-name'>{name}</span>{' '}
              {isProfileVerified && <ProfileTickIcon width='12' height='12' fill='#fff' />}
            </strong>
            <div className='name-area'>
              {username ? <span className='user-name'>@{username}</span> : null}
            </div>
            {/* {btnText && (
              <Button size="small" shape="circle" onClick={() => onClick?.()}>
              {btnText}
              </Button>
            )} */}
          </div>
        </div>
      </div>
      <div className='action_btns'>{dropdownMenu}</div>
    </div>
  );
};

export default ProfileCard;
