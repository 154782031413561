import { RequestLoader } from 'components/Loader/SiteLoader';
import Modal from 'components/Modal';
import { useGlobalStore } from 'store/globals';

const GlobalModelOverlay = () => {
  const globalStore = useGlobalStore();

  return (
    <Modal
      shouldCloseOnOverlayClick={false}
      className={` global-modal`}
      showHeaderClose={false}
      isOpen={!!globalStore.isGlobalOverlyOpen}
      card-modal
      okText='TIP'
      showHeader
      showFooter={false}
    >
      <RequestLoader isLoading />
    </Modal>
  );
};

export default GlobalModelOverlay;
