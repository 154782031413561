import MobileMenu from 'components/UI/MobileMenu';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { Outlet } from 'react-router-dom';
import SidebarMenu from './SidebarMenu';
import './applayout.scss';

const AppLayout = () => {
  return (
    <div className='main-container h-100'>
      <div className='app_layout h-100'>
        <ResponsiveLayout width={991}>
          <SidebarMenu />
        </ResponsiveLayout>
        {/* <SidebarMenu>
          <Sidebar />
        </SidebarMenu> */}
        <div className='app-right-content'>
          <Outlet />
        </div>
        <ResponsiveLayout isMobile width={991}>
          <MobileMenu />
        </ResponsiveLayout>
      </div>
    </div>
  );
};

export default AppLayout;
