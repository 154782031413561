import { ConnectionIcon, LockIcon, PlaceholderImgIcon, PynkLogo, VideoIcon } from 'assets/svgs';
import Button from 'components/Button';
import UnlockModel from 'components/models/UnlockModel';
import useOpenClose from 'hooks/useOpenClose';
import { useSellerDetailStore } from 'store/stores/sellerSubscriptionDetail';
import SubcribeModel from 'pages/collections/components/model/SubcribeModel';
import './lockedpost.scss';
import { useClaimFreeTokensStore } from 'store/stores/ClaimFreeTokensStore';
import { useStoreBase } from 'store';
import { useState } from 'react';
interface ILockedPost {
  onSubscribe?: (...args: any) => Promise<any>;
  onUnlock?: (...args: any) => Promise<any>;
  post: IPost;
  isSubscribed?: boolean;
  isUnlockable?: boolean;
}
const LockedPost: React.FC<ILockedPost> = ({
  onSubscribe,
  post,
  onUnlock,
  isSubscribed,
  isUnlockable,
}: ILockedPost) => {
  const [isOpen, onOpen, onClose] = useOpenClose();
  const seller = useSellerDetailStore.useSeller();
  const claimFreeTokensStore = useClaimFreeTokensStore();
  const [isLoading, setIsLoading] = useState(false);
  const openClaimFreeTokensModal = () => claimFreeTokensStore.setIsOpen({ isOpen: true });
  const is_free_credits_used = useStoreBase().user?.['50_credit_used'];

  const [isUnlockOpen, onUnlockOpen, onUnlockClose] = useOpenClose();
  const handleUnlockClick = async () => {
    if (is_free_credits_used === 0) {
      openClaimFreeTokensModal();
    } else {
      try {
        setIsLoading(true);
        await onUnlock?.(post?.content_post_id);
        setIsLoading(false);
      } catch (error) {
        console.log({ error });
        setIsLoading(false);
      }
    }
  };
  return (
    <div className='locked-post-item'>
      {/* <div className='lock-bg-image'>
        <Image src='/assets/images/locked-bg-image.png' alt='lock-bg-image' />
      </div> */}
      <div className='locked-image-holder'>
        <LockIcon />
      </div>
      <div className='post-subscriber-block'>
        <ul className='post-subscriber-list'>
          {!!post?.image_item_count && (
            <li>
              <PlaceholderImgIcon />
              <span className='text'>{post?.image_item_count}</span>
            </li>
          )}
          {!!post?.video_item_count && (
            <li>
              <VideoIcon />
              <span className='text'>{post?.video_item_count}</span>
            </li>
          )}
          {!!post?.audio_item_count && (
            <li>
              <ConnectionIcon />
              <span className='text'>{post?.audio_item_count}</span>
            </li>
          )}
        </ul>
        {!isSubscribed ? (
          <Button
            block
            rounded
            loading={isLoading}
            onClick={() => {
              if (is_free_credits_used === 0) {
                openClaimFreeTokensModal();
              } else {
                onOpen();
              }
            }}
          >
            {`SUBSCRIBE TO SEE USER'S POSTS`}
          </Button>
        ) : isUnlockable ? (
          <Button block rounded onClick={handleUnlockClick}>
            UNLOCK POST{' '}
            <span className='btn-tag-item'>
              {post?.unlock_price} <PynkLogo />
            </span>
          </Button>
        ) : null}

        <SubcribeModel
          img={seller?.profile_thumb?.url}
          name={`${seller?.first_name} ${seller?.last_name}`}
          coverImg={seller?.profile_header?.url}
          username={seller?.user_name}
          isProfileVerified={true}
          isOnline={false}
          seller={seller}
          onClose={onClose}
          isOpen={isOpen}
          callback={async (time: IDuratuons) => {
            try {
              onClose();
              await onSubscribe?.(time);
            } catch (error) {
              onClose();
            }
          }}
        />
        <UnlockModel
          onClose={onUnlockClose}
          isOpen={isUnlockOpen}
          post={post}
          callback={async () => {
            try {
              onUnlockClose();
              await onUnlock?.(post?.content_post_id);
            } catch (error) {
              onUnlockClose();
            }
          }}
        />
      </div>
    </div>
  );
};

export default LockedPost;
