import { ILinkType } from '.';

type Props = ILinkType;

const Item = (props: Props) => {
  const { icon: Icon, title, counter } = props;
  return (
    <div className='sidebar_item'>
      {Icon ? (
        <span className='menu-icon'>
          <Icon /> {!!counter && <span className='counter-badge'>{counter}</span>}
        </span>
      ) : null}{' '}
      {title ? <span className='menu-title'>{title}</span> : null}
    </div>
  );
};

export default Item;
