/* eslint-disable no-throw-literal */
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import NewButton from 'components/Button';
import Scrollbar from 'components/Scrollbar';
import Form from 'components/forms/Form';
import Input from 'components/forms/Input';
import { useForm } from 'hooks/useHookForm';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { getCSSPropertyValue, isValidEmailSchema } from 'utils';
import { z } from 'zod';
import './CardForm.style.scss';
const cardNameSchema = z.object({
  cardName: z
    .string()
    .min(1, { message: 'Card Name is required' })
    .min(3, { message: 'Minimum 3 charactors long' }),
});
const emailSchema = z.object({
  email: z
    .string()
    .email('Please Provide a valid Email address')
    .refine(isValidEmailSchema, (value) => {
      return {
        message: `${value} is not a valid email`,
      };
    }),
});

const CardForm = (
  {
    visibility = true,
    showEmailField = false,
    disableEmail,
    elementsProps,
    onInputChange,
    submitHandler,
    isSubmitting,
  }: {
    functions: {
      onSave: (args: any) => Promise<any> | void;
    };
    submitHandler?: (values: Record<string, any>) => Promise<any> | void;
    isSubmitting?: boolean;
    [key: string]: any;
  },
  ref: any,
) => {
  const [cardName, setCardName] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const elements = useElements();
  const stripe = useStripe();
  const newSchemea = !showEmailField ? cardNameSchema : cardNameSchema.merge(emailSchema);
  type IFormValues = z.infer<typeof newSchemea>;
  const color = getCSSPropertyValue('--default-text-color');
  const stripeColor = {
    style: {
      base: {
        color: color as string,
        fontSize: '16px',
      },
      invalid: {
        color: '#eb1c26',
      },
    },
  };
  const form = useForm({
    schema: newSchemea,
    mode: 'onChange',
  });
  useEffect(() => {
    setLoading(!!isSubmitting);
  }, [isSubmitting]);

  const fetchStripeToken = async () => {
    if (!stripe || !elements) {
      return;
    }
    const cnumber = elements.getElement(CardNumberElement);
    if (!cnumber) {
      return;
    }

    return await stripe.createToken(cnumber, {
      name: form?.getValues()?.cardName,
    });
  };
  const restForm = () => {
    if (elements !== null) {
      setCardName('');
      const num = elements.getElement(CardNumberElement);
      num?.clear();
      const cvc = elements.getElement(CardCvcElement);
      cvc?.clear();
      const expiry = elements.getElement(CardExpiryElement);
      expiry?.clear();
    }
  };

  useImperativeHandle(
    ref,
    () => {
      return { restForm, fetchStripeToken };
    },
    [form.formState],
  );
  const handleSubmit = (values: IFormValues) => {
    submitHandler?.(values);
  };
  return (
    <Form
      form={form}
      id='first-billing-method-form'
      className='first-billing-method-form'
      method='POST'
      autoComplete='off'
      style={{ display: !!visibility ? 'block' : 'none' }}
      onSubmit={handleSubmit}
    >
      <Scrollbar autoHeight autoHeightMax={`calc(100vh - 140px)`}>
        <div className='modal-scroll-wrap pt-10'>
          <div className='input-wrap'>
            <Input
              hasLabel={false}
              placeholder='Name on Card'
              name='cardName'
              validations={[{ noMultipeSpace: true }, { type: 'alpha' }]}
              value={cardName}
            />
          </div>
          {showEmailField ? (
            <div className='input-wrap'>
              <Input
                style={{ paddingTop: 5, fontSize: '14px' }}
                hasLabel={false}
                placeholder='What is your email address?'
                id='email'
                name='email'
                type='email'
                disabled={disableEmail}
              />
            </div>
          ) : null}
          <div className='input-wrap'>
            <div className='text-input'>
              <CardNumberElement
                className='form-control'
                options={{
                  ...stripeColor,
                  placeholder: 'Card Number',
                }}
              />
            </div>
          </div>
          <div className='input-wrap cols'>
            <div className='text-input'>
              <CardExpiryElement
                className='form-control'
                options={{
                  ...stripeColor,
                }}
              />
            </div>
            <div className='text-input'>
              <CardCvcElement
                className='form-control'
                options={{
                  ...stripeColor,
                }}
              />{' '}
              <div className='img'>
                <img src='/assets/svgs/ico-card02.svg' alt='img description' />
              </div>
            </div>
          </div>
          {/* <div className='billing-card-field'>
            <div id='card-errors' role='alert'></div>
          </div> */}
        </div>
      </Scrollbar>
      {!elementsProps?.Save ? (
        <div className='modal-footer text-right small-gap'>
          <NewButton type='submit' disabled={loading} rounded size='small'>
            SAVE
          </NewButton>
        </div>
      ) : null}
      {elementsProps?.Save && <elementsProps.Save />}
    </Form>
  );
};

export default forwardRef(CardForm);
