const EmptyStarIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_1_129)'>
        <path
          d='M12 17.75L5.82802 20.995L7.00702 14.122L2.00702 9.25501L8.90702 8.25501L11.993 2.00201L15.079 8.25501L21.979 9.25501L16.979 14.122L18.158 20.995L12 17.75Z'
          stroke={stroke}
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default EmptyStarIcon;
