import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { ReactNode } from 'react';
import { resetters } from 'store';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

type State = {
  isOpen?: boolean;
  title?: ReactNode;
  onSuccessCallback?: (...args: any) => Promise<any> | void;
  onErrorCallback?: (...args: any) => Promise<any> | void;
};

type Actions = {
  setIsOpen(props?: { isOpen?: boolean; title?: ReactNode }): void;
  setModalState(props?: {
    onSuccessCallback?: (...args: any) => Promise<any> | void;
    onErrorCallback?: (...args: any) => Promise<any> | void;
  }): void;
  resetCardStore(): void;
};
export const useClaimFreeTokensStoreBase = create<State & Actions>()(
  devtools(
    immer<State & Actions>((set) => {
      resetters.push(() =>
        set({
          isOpen: false,
          onErrorCallback: undefined,
          onSuccessCallback: undefined,
          title: undefined,
        }),
      );
      return {
        isOpen: false,
        setIsOpen: (props) => {
          set((prev) => {
            prev.isOpen = props?.isOpen;

            prev.title = props?.title;
          });
        },
        setModalState: (props) => {
          set((prev) => {
            prev.onErrorCallback = props?.onErrorCallback;
            prev.onSuccessCallback = props?.onSuccessCallback;
          });
        },
        resetCardStore: () => {
          set((prev) => {
            prev.onErrorCallback = undefined;
            prev.onSuccessCallback = undefined;
            prev.title = undefined;
          });
        },
      };
    }),
  ),
);

export const useClaimFreeTokensStore = createSelectorHooks<State & Actions>(
  useClaimFreeTokensStoreBase,
);
