const SettingIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g>
        <path
          fill={stroke}
          d='M23.172,11.194c-0.172-0.042-0.335-0.125-0.471-0.242c-0.135-0.115-0.243-0.263-0.311-0.428
  c-0.068-0.163-0.098-0.344-0.084-0.522c0.014-0.177,0.07-0.352,0.162-0.503c0.551-0.906,0.577-1.988,0.068-2.895
  c-0.785-1.398-2.639-1.924-4.035-1.073c-0.152,0.094-0.327,0.15-0.592,0.167c-0.15,0-0.297-0.029-0.436-0.086
  c-0.164-0.068-0.313-0.176-0.429-0.311c-0.114-0.135-0.198-0.299-0.241-0.472C16.484,3.51,15.357,2.624,14,2.624
  c-1.357,0-2.484,0.887-2.804,2.205C11.022,5.544,10.145,5.923,9.5,5.531C8.098,4.678,6.254,5.203,5.466,6.604
  c-0.51,0.907-0.485,1.989,0.067,2.895c0.093,0.153,0.149,0.327,0.163,0.504c0.014,0.177-0.015,0.358-0.083,0.523
  c-0.068,0.165-0.176,0.313-0.311,0.429c-0.136,0.116-0.299,0.199-0.472,0.241C3.511,11.516,2.625,12.643,2.625,14
  c0,1.357,0.886,2.484,2.204,2.803c0.173,0.043,0.337,0.127,0.472,0.242c0.135,0.115,0.242,0.264,0.31,0.428
  c0.068,0.164,0.097,0.346,0.083,0.523c-0.014,0.177-0.07,0.352-0.163,0.503c-0.551,0.905-0.577,1.987-0.067,2.896
  c0.785,1.395,2.638,1.922,4.035,1.071c0.153-0.093,0.326-0.149,0.591-0.166c0.15,0,0.297,0.029,0.436,0.087
  c0.165,0.069,0.313,0.176,0.428,0.311c0.116,0.136,0.2,0.299,0.242,0.472c0.32,1.319,1.447,2.205,2.804,2.205
  c1.357,0,2.484-0.886,2.805-2.204c0.043-0.173,0.127-0.336,0.242-0.472c0.116-0.136,0.264-0.244,0.428-0.312
  c0.137-0.057,0.278-0.085,0.523-0.082c0.177,0.014,0.352,0.069,0.503,0.161c1.397,0.853,3.251,0.324,4.034-1.071
  c0.51-0.908,0.484-1.99-0.067-2.896c-0.093-0.15-0.149-0.326-0.163-0.503s0.016-0.358,0.084-0.522s0.176-0.313,0.311-0.429
  c0.136-0.114,0.299-0.198,0.472-0.241c1.319-0.32,2.205-1.447,2.205-2.805C25.376,12.641,24.49,11.514,23.172,11.194z
   M22.755,15.105c-0.438,0.106-0.853,0.317-1.194,0.611c-0.344,0.294-0.617,0.67-0.789,1.088c-0.174,0.418-0.246,0.877-0.211,1.327
  c0.033,0.451,0.178,0.894,0.412,1.279c0.303,0.497,0.152,0.92,0.036,1.127c-0.315,0.562-1.015,0.792-1.6,0.435
  c-0.386-0.233-0.828-0.377-1.277-0.41c-0.462-0.035-0.907,0.035-1.327,0.209c-0.418,0.174-0.795,0.447-1.089,0.791
  c-0.293,0.342-0.505,0.756-0.612,1.194c-0.155,0.64-0.671,0.867-1.104,0.867s-0.949-0.228-1.104-0.868
  c-0.107-0.438-0.319-0.852-0.613-1.195c-0.294-0.345-0.671-0.617-1.088-0.789c-0.353-0.146-0.724-0.221-1.105-0.221
  c-0.074,0-0.148,0.004-0.223,0.01c-0.451,0.033-0.893,0.178-1.279,0.412c-0.581,0.353-1.284,0.127-1.599-0.436
  c-0.116-0.207-0.267-0.63,0.036-1.126c0.235-0.386,0.378-0.828,0.413-1.279c0.035-0.45-0.038-0.909-0.211-1.327
  c-0.173-0.419-0.446-0.795-0.79-1.089c-0.342-0.293-0.755-0.505-1.194-0.612C4.603,14.948,4.375,14.432,4.375,14
  s0.228-0.949,0.869-1.106c0.439-0.106,0.853-0.318,1.196-0.612c0.344-0.294,0.617-0.67,0.79-1.088
  c0.172-0.418,0.246-0.877,0.211-1.327C7.405,9.418,7.263,8.975,7.027,8.588C6.724,8.091,6.875,7.669,6.991,7.461
  c0.316-0.562,1.018-0.79,1.6-0.434c0.455,0.277,0.974,0.423,1.5,0.423c1.336,0,2.49-0.907,2.806-2.206
  c0.155-0.64,0.673-0.868,1.104-0.868s0.948,0.228,1.104,0.87c0.106,0.44,0.317,0.853,0.61,1.195
  c0.294,0.344,0.671,0.617,1.089,0.79c0.422,0.174,0.877,0.245,1.327,0.211c0.45-0.036,0.892-0.178,1.279-0.414
  c0.583-0.354,1.284-0.127,1.599,0.436c0.117,0.207,0.268,0.629-0.037,1.128c-0.234,0.386-0.377,0.828-0.41,1.277
  c-0.035,0.452,0.037,0.91,0.209,1.327c0.174,0.419,0.447,0.795,0.791,1.089c0.342,0.294,0.756,0.506,1.194,0.612
  c0.64,0.155,0.867,0.673,0.867,1.104S23.396,14.949,22.755,15.105z'
        />
        <path
          fill={stroke}
          d='M14,9.625c-1.168,0-2.267,0.455-3.094,1.281C10.08,11.733,9.625,12.832,9.625,14
  c0,1.15,0.467,2.279,1.281,3.094c0.827,0.826,1.926,1.281,3.094,1.281c1.15,0,2.279-0.466,3.094-1.281
  c0.814-0.814,1.281-1.943,1.281-3.094c0-1.168-0.455-2.267-1.281-3.094C16.279,10.092,15.15,9.625,14,9.625z M15.855,15.855
  c-0.982,0.985-2.726,0.983-3.711,0c-0.489-0.488-0.769-1.164-0.769-1.855c0-0.701,0.273-1.36,0.769-1.856s1.155-0.769,1.856-0.769
  c0.691,0,1.367,0.28,1.855,0.769c0.498,0.496,0.771,1.155,0.771,1.856C16.626,14.689,16.346,15.367,15.855,15.855z'
        />
      </g>
    </svg>
  );
};

export default SettingIcon;
