import { PynkLogo, Tick, TickCircle } from 'assets/svgs';
import { Fragment } from 'react';
import Button from './Button';

const SubscriptionButton = ({
  show = true,
  label,
  price = 0,
  handleClick,
  size,
  icon,
  hasTick = false,
  className = '',
}: any) => {
  if (!show) return null;
  const IsFree = price === 'FREE';
  const Icon = icon?.priceIcon ? <>{icon?.priceIcon}</> : <>{!IsFree && <PynkLogo />}</>;
  return (
    <Button
      rounded
      block
      onClick={handleClick}
      className={`btn-subscribe ${className}`}
      size={size}
    >
      <Fragment>
        <span className='subscription-btn-text'>
          {hasTick && <Tick />}
          {label}
        </span>
        {price ? (
          <span className={`${IsFree ? 'text-only' : ''} btn-tag-item`}>
            {price} {Icon}
          </span>
        ) : (
          <TickCircle />
        )}
      </Fragment>
    </Button>
  );
};

export default SubscriptionButton;
