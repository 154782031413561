function OnlyClonesmallLogo(props: IConProps) {
  const {
    width = 50,
    height = 50,

    ...rest
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 50 50'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <rect width={width} height={height} rx={(width as number) / 2} fill='#00AFF0' />
      <path
        d='M19.6953 14.0004C13.7884 14.0004 9 18.9251 9 25.0003C9 31.0756 13.7884 36.0002 19.6953 36.0002C25.6023 36.0002 30.3906 31.0756 30.3906 25.0003C30.3906 18.9251 25.6023 14.0004 19.6953 14.0004ZM19.6953 28.3614C17.8905 28.3614 16.4273 26.8565 16.4273 25.0003C16.4273 23.144 17.8905 21.6392 19.6953 21.6392C21.5002 21.6392 22.9633 23.144 22.9633 25.0003C22.9633 26.8565 21.5002 28.3614 19.6953 28.3614Z'
        fill='#78D5F7'
      />
      <path
        d='M40.9676 14.0002H38.259H36.836H33.0127H30.9613H30.0229C28.5552 14.0002 27.2673 14.4404 26.16 15.4003C25.9963 15.5423 25.8446 15.6921 25.6989 15.846C24.8919 16.6966 24.3552 17.7254 24.0903 18.9445L23.6603 21.4509L22.4515 28.4989L22.2772 29.5144C22.2471 31.1059 22.7266 32.528 23.7206 33.7444C24.9052 35.1945 26.4373 35.9497 28.3177 35.9497H28.5869H32.4819H34.2H35.6898H38.364L38.3835 35.7557C38.426 35.4601 38.4499 35.1724 38.4593 34.89C38.5131 33.2879 38.0387 31.8953 37.0316 30.6621C35.8468 29.2122 34.3147 28.4989 32.4347 28.4989H29.9011L30.0117 27.8513L30.3506 25.867L31.1048 21.4509H31.6368H33.9204H34.6264C36.1068 21.4509 37.401 20.9503 38.5086 19.9898C39.6157 19.0299 40.3109 17.8178 40.5944 16.2948L40.9999 14H40.9676V14.0002Z'
        fill='white'
      />
    </svg>
  );
}

export default OnlyClonesmallLogo;
