import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { resetters } from 'store';
export type ICursorType =
  | 'feeds'
  | 'subscriptions'
  | 'collections'
  | 'seller-profile'
  | 'bookmarks';

interface IStore {
  feedCursor?: { date: string; postId: number };
  collectionsCursor?: { date: string; postId: number };
  bookmarkCursor?: { date: string; postId: number };
  subscriptionsCursor?: { date: string; postId: number };
  sellerCursor?: { date: string; postId: number };
  type?: ICursorType;
  isGlobalOverlyOpen?: boolean;
}
type IActionTypes = {
  resetStore: () => void;

  setCursor: (props: { date: string; postId: number }, type: ICursorType) => void;
  setIsGlobalOverlyOpen: (isOpen: boolean) => void;
};
const initialState: IStore | undefined = {
  feedCursor: undefined,
  collectionsCursor: undefined,
  bookmarkCursor: undefined,
  subscriptionsCursor: undefined,
  sellerCursor: undefined,
  type: undefined,
  isGlobalOverlyOpen: false,
};
export const useGlobalStoreBase = create(
  devtools(
    immer<IStore & IActionTypes>((set, get) => {
      resetters.push(() => set(initialState));
      return {
        ...initialState,
        // ...createLayoutSlice(set, get),
        resetStore: () => {
          set(() => initialState);
        },
        setIsGlobalOverlyOpen: (isOpen) => {
          set((state) => {
            state.isGlobalOverlyOpen = isOpen;
          });
        },

        setCursor: (props, type) => {
          set((state) => {
            if (type === 'collections') {
              state.collectionsCursor = props;
            }
            if (type === 'bookmarks') {
              state.bookmarkCursor = props;
            }
            if (type === 'seller-profile') {
              state.sellerCursor = props;
            }
            if (type === 'subscriptions') {
              state.subscriptionsCursor = props;
            }
            if (type === 'feeds') {
              state.feedCursor = props;
            }
          });
        },
      };
    }),
  ),
);

export const useGlobalStore = createSelectorHooks<IStore & IActionTypes>(useGlobalStoreBase);
