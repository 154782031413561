const MessagesIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g>
        <path
          fill={stroke}
          d='M18.666,9.625H9.333c-0.483,0-0.875,0.392-0.875,0.875s0.392,0.875,0.875,0.875h9.333
		c0.482,0,0.875-0.392,0.875-0.875S19.148,9.625,18.666,9.625z'
        />
        <path
          fill={stroke}
          d='M16.333,14.291h-7c-0.483,0-0.875,0.393-0.875,0.875s0.392,0.875,0.875,0.875h7
		c0.483,0,0.875-0.393,0.875-0.875S16.816,14.291,16.333,14.291z'
        />
        <path
          fill={stroke}
          d='M24.094,6.074C23.279,5.259,22.152,4.792,21,4.792H7c-1.168,0-2.267,0.455-3.094,1.282
		C3.08,6.9,2.625,7.999,2.625,9.167V18.5c0,1.152,0.467,2.279,1.281,3.094C4.733,22.42,5.832,22.875,7,22.875h3.137l3.244,3.244
		c0.171,0.171,0.395,0.256,0.619,0.256s0.448-0.085,0.619-0.256l3.242-3.244H21c1.152,0,2.279-0.467,3.094-1.281
		c0.814-0.816,1.281-1.943,1.281-3.094V9.167C25.375,7.999,24.92,6.901,24.094,6.074z M23.625,18.5c0,0.689-0.281,1.367-0.77,1.855
		c-0.488,0.49-1.166,0.77-1.855,0.77h-3.5c-0.232,0-0.455,0.092-0.619,0.256L14,24.264l-2.881-2.883
		c-0.164-0.164-0.387-0.256-0.619-0.256H7c-0.7,0-1.36-0.273-1.856-0.77c-0.489-0.488-0.769-1.164-0.769-1.855V9.167
		c0-0.702,0.273-1.361,0.769-1.856C5.64,6.815,6.299,6.542,7,6.542h14c0.691,0,1.367,0.28,1.855,0.769
		c0.496,0.496,0.77,1.155,0.77,1.856V18.5z'
        />
      </g>
    </svg>
  );
};

export default MessagesIcon;
