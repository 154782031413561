import { CoinSmall, IIcon, PlusIcon, PynkLogo } from 'assets/svgs';
import FiftyToken from 'assets/svgs/50Tokens';
import Button from 'components/Button';
import Modal from 'components/Modal';
import SubscriptionButton from 'components/SubscriptionButton';
import toaster from 'lib/toaster';
import AcountModel from 'models/Account';
import React, { Fragment, ReactNode } from 'react';
import { useStoreBase } from 'store';
import useSWRMutation from 'swr/mutation';
interface Props {
  isOpen: boolean;
  onClick: () => void;
  onClose: () => void;
  shouldCloseOnOverlayClick?: boolean;
  children?: ReactNode;
  title?: string;
  btnText?: string;
}

const LastStep: React.FC<Props> = ({
  isOpen,
  onClose,
  onClick,
  shouldCloseOnOverlayClick = false,

  children,
  title = 'CLAIM YOUR TOKENS',
  btnText = 'I AGREE',
}) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      className={`claim-free-tokens last-step confirmation-modal`}
      showHeaderClose={false}
      title={
        <div className='modal-header-wrap'>
          <CoinSmall />
          TOKENS ADDED !
        </div>
      }
      onOk={onClose}
      isOpen={isOpen}
      card-modal
      okText={'Close'}
      showHeader
      showFooter={false}
      showCancelBtn={false}
      onClose={() => {
        onClose();
      }}
    >
      <div className='modal-scroll-wrap' style={{ paddingTop: '9px' }}>
        <div className='header-text'>
          <h5 className='blue bold'>50 Tokens have been added to your account.</h5>
          <p className='sub-header'>You have 3 days to use them before your trial expires!</p>
          <br />
          <p className='text'>
            Please be patient with the Beta as we smooth things out a bit as we are still rough
            around the edges.
          </p>
          <br />
        </div>
        <div className='terms-container'>
          <div className='blue-padding' />
          <IIcon />
          <p>
            At the end of your trial, to ensure continuity of service, you will automatically be
            granted full site access and 200 Tokens every month as part of your monthly Gold
            Membership plan. You retain the option to cancel, upgrade, or purchase additional tokens
            at any time. Beta prices subject to change.
          </p>
        </div>
        <div className='text body'>
          <br />
          We are particularly interested in hearing your thoughts on what we could do better, or
          seeing if there are any new features you think we should add.
          <br />
          <br />
          Please do not hesitate to reach out and let us know ! <br />
          <span className='blue bold'>- The Team @OnlyClones</span>
        </div>

        <React.Fragment>
          <Fragment>
            <Button
              rounded
              block
              onClick={onClick}
              className={`btn-subscribe `}
              style={{
                border: 'none',
                padding: '22px',
                display: 'flex',
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <Fragment>
                <span
                  className='subscription-btn-text'
                  style={{
                    display: 'flex',
                    gap: '10px',
                    fontSize: '22px',
                  }}
                >
                  <span style={{ position: 'relative', top: 2 }}>TAKE ME TO THE BETA!</span>
                </span>
              </Fragment>
            </Button>
          </Fragment>
        </React.Fragment>
      </div>
    </Modal>
  );
};

export default LastStep;
