import { Controller, FieldValues, UseControllerProps, useFormContext } from 'react-hook-form';

import FocusInput from 'components/Input/focus-input';
import {
  DetailedHTMLProps,
  forwardRef,
  InputHTMLAttributes,
  MutableRefObject,
  ReactElement,
  ReactNode,
  Ref,
} from 'react';
import FieldError from './FieldError';
import FieldHelperText from './FieldHelperText';

type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
  label?: ReactNode;
  helperText?: ReactNode;
} & {
  materialDesign?: boolean;
  limit?: number;
  rows?: number;
  hasLimit?: boolean;
  hasLabel?: boolean;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  inputRef?: MutableRefObject<any>;
  prefixElement?: string | ReactElement;
  validations?: {
    type?: string;
    noMultipeSpace?: boolean;
    noSpace?: boolean;
  }[];
};
type GenericTextfieldProps<T extends FieldValues> = UseControllerProps<T> & InputProps;
const Input = <T extends FieldValues>(
  props: GenericTextfieldProps<T>,
  ref: Ref<HTMLInputElement>,
) => {
  const { name = '', id, helperText, ...rest } = props;
  const { control } = useFormContext();
  return (
    <div className='text-input'>
      <div className='input_container'>
        <Controller
          name={name}
          control={control}
          render={({ field: { ref: rref, ...rrest }, fieldState: { error } }) => {
            return (
              <div className='input_wrapper'>
                <FocusInput
                  {...rrest}
                  {...(rest as any)}
                  ref={ref || (rref as any)}
                  id={id || name}
                  error={error}
                />
              </div>
            );
          }}
        />
      </div>
      <FieldHelperText helperText={helperText} name={name} />
      <FieldError name={name} />
    </div>
  );
};

export default forwardRef(Input);
