import { useCallback, useEffect, useState } from 'react';

const useOnScreen = (ref: any, post: IPost, rootMargin = '0px') => {
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [onScreenPost, setOnScreenPost] = useState<IPost>();
  const handleOnScreenChange = useCallback(
    (newIsOnScreen: boolean) => {
      setIsOnScreen(newIsOnScreen);
    },
    [onScreenPost],
  );
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        handleOnScreenChange(entry.isIntersecting);
        setOnScreenPost(post);
      },
      {
        rootMargin,
        threshold: 0.5,
      },
    );

    const currentElement = ref?.current;

    if (currentElement) {
      observer?.observe(currentElement);
    }

    return () => {
      observer?.unobserve(currentElement);
    };
  }, []);

  return { isOnScreen, onScreenPost };
};

export default useOnScreen;
