const BellIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fill={stroke}
        d='M23.676,19.021c-0.476-0.289-0.888-0.683-1.195-1.144c-0.319-0.479-0.528-1.032-0.604-1.599l-0.001-3.486
	c-0.077-1.639-0.6-3.227-1.509-4.592c-0.816-1.225-1.917-2.229-3.205-2.929c-0.113-0.644-0.419-1.236-0.892-1.707
	c-1.211-1.212-3.327-1.212-4.537-0.001c-0.473,0.472-0.779,1.064-0.892,1.708c-1.288,0.7-2.39,1.705-3.206,2.929
	c-0.91,1.366-1.431,2.954-1.509,4.633v3.445c-0.077,0.57-0.285,1.119-0.603,1.598c-0.328,0.49-0.773,0.904-1.288,1.197
	c-0.346,0.195-0.515,0.6-0.414,0.984c0.101,0.383,0.449,0.65,0.846,0.65h4.958V21c0,1.15,0.467,2.279,1.281,3.094
	c0.827,0.826,1.926,1.281,3.094,1.281c1.15,0,2.279-0.467,3.096-1.281c0.813-0.816,1.28-1.943,1.28-3.094v-0.291h4.958
	c0.007,0,0.016,0,0.02,0c0.483,0,0.875-0.393,0.877-0.874C24.23,19.465,24,19.148,23.676,19.021z M16.626,21
	c0,0.689-0.28,1.367-0.771,1.855c-0.982,0.985-2.726,0.984-3.711,0c-0.489-0.488-0.769-1.164-0.769-1.855v-0.291h5.251V21z
	 M6.901,18.959c0.027-0.037,0.052-0.074,0.078-0.113c0.479-0.718,0.787-1.551,0.891-2.408c0.004-0.035,0.006-0.069,0.006-0.104
	l-0.001-3.459c0.062-1.321,0.482-2.601,1.216-3.703c0.734-1.101,1.754-1.981,2.948-2.544c0.306-0.145,0.501-0.453,0.501-0.791
	c0-0.39,0.152-0.756,0.428-1.032c0.55-0.55,1.511-0.551,2.062,0c0.277,0.275,0.429,0.641,0.429,1.031
	c0,0.338,0.194,0.646,0.501,0.791c1.195,0.565,2.215,1.446,2.949,2.547c0.734,1.1,1.154,2.381,1.216,3.662v3.5
	c0,0.035,0.002,0.069,0.007,0.104c0.104,0.855,0.412,1.688,0.891,2.408c0.024,0.037,0.052,0.074,0.076,0.111H6.901z'
      />
    </svg>
  );
};

export default BellIcon;
