import { MEMEBER_TYPE } from 'appconstants';
import { PlaceholderImgIcon } from 'assets/svgs';
import classNames from 'classnames';
import ProfileCard from 'components/CoverProfile';
import DiscriptionWrapper from 'components/ShowMoreText';
import useInView from 'hooks/useInView';
import useSocket from 'hooks/useSocket';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSellerDetailStore } from 'store/stores/sellerSubscriptionDetail';
import { fromNow } from 'utils/dayjsUtils';
import { IPostProps } from '.';
import LockedPost from './LockedPost';
import PostFooter from './PostFooter';
import PostHeader from './PostHeader';
import PostMedia from './PostMedia';
import './post.scss';
type IFreePost = IPostProps & { isFreeUnlock?: boolean };
const FreePost = React.forwardRef((props: IFreePost, ref: any) => {
  const {
    onMediaClick,
    user,
    className,
    post,
    isFooter = true,
    renderDropDown,
    isFreeUnlock = false,
    showbookmark,
    showProfileCard = false,
    onUserNameClick,
    onTipClick,
    filters,
    onLoadMoreComments,
    updatePost,
    actions,
    ...rest
  } = props;
  const navigate = useNavigate();
  return (
    <div className={classNames('post_wrapper', className)} ref={ref}>
      <div className='post_top_area'>
        <PostHeader
          user={{ ...user, seller_account_id: post?.seller_account_id }}
          createdAt={
            post?.release_date_time ? fromNow(post?.release_date_time as string) : undefined
            // !!post?.image_item_count ? (
            //   <li style={{ listStyle: 'none', display: 'flex', alignItems: 'center' }}>
            //     <PlaceholderImgIcon />
            //     <span className='text'>{post?.image_item_count}</span>
            //   </li>
            // ) : undefined
          }
          post={post}
          showbookmark={showbookmark}
          onFavoriteChange={props.onFavoriteChange}
          showActionButton
          onUserNameClick={onUserNameClick}
          // renderDropDown={renderDropDown}
        />
        <>
          {post?.post_message ? (
            <DiscriptionWrapper
              discription={post?.post_message}
              buttonTitle={{ showMore: 'Read more', showLess: 'Read less' }}
            />
          ) : null}
        </>
      </div>
      <PostMedia
        post={post}
        filters={filters}
        onMediaClick={(e: any) => {
          e.stopPropagation();
          e.preventDefault();
          onMediaClick?.(post, isFreeUnlock);
        }}
      />
      {showProfileCard ? (
        <div className='feeds-profile-card'>
          <ProfileCard
            coverImg={props?.post?.profile_header?.url || ''}
            img={props?.user?.profile_thumb?.url}
            isProfileVerified
            isOnline
            onClick={() => navigate(`/seller-profile/${props?.post?.seller_account_id}`)}
            dropdownMenu={props.profileDropdow}
            name={`${props?.user?.first_name} ${props?.user?.last_name}`}
            username={props?.user?.user_name}
          />
        </div>
      ) : null}
      {/* {isFooter ? (
        <div className='post_footer_wrap'>
          <PostFooter
            actions={actions}
            onTipClick={onTipClick}
            id={post?.content_post_id}
            showComments={props.showComments}
            onCommentIconClick={props.onCommentIconClick}
            onLoadMoreComments={onLoadMoreComments}
            post={post}
            showbookmark={showbookmark}
            onFavoriteChange={props.onFavoriteChange}
            onPostLikeChange={props.onPostLikeChange}
            updatePost={updatePost}
          />
        </div>
      ) : null} */}
    </div>
  );
});
export const SubscribeOrUnlockNormal = React.forwardRef((props: IPostProps, ref: any) => {
  const {
    user,
    className,
    post,
    renderDropDown,
    onSubscribe,
    showbookmark,

    onUnlock,
    onUserNameClick,
    onTipClick,
    onLoadMoreComments,
    onFavoriteChange,
    updatePost,
    isFooter = true,
    actions,
  } = props;
  const isSubscribed = user?.subscription_id !== null;
  const isUnlockable = !!post?.unlockable && !post.is_unlocked;

  return (
    <div className={classNames('post_wrapper', className)} ref={ref}>
      <div className='post_top_area'>
        <PostHeader
          user={{ ...user, seller_account_id: post?.seller_account_id }}
          createdAt={
            // post?.release_date_time ? fromNow(post?.release_date_time as string) : undefined
            !!post?.image_item_count ? (
              <li style={{ listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                <PlaceholderImgIcon />
                <span className='text'>{post?.image_item_count}</span>
              </li>
            ) : undefined
          }
          post={post}
          showbookmark={showbookmark}
          onFavoriteChange={props.onFavoriteChange}
          showActionButton
          renderDropDown={renderDropDown}
          onUserNameClick={onUserNameClick}
        />
        {post?.access_type !== MEMEBER_TYPE.MEMBER_ONLY && (
          <>
            {/* <div className='post_text'>{post?.post_title || ''}</div> */}
            {post?.post_message ? (
              <div
                className='post_text post_message'
                dangerouslySetInnerHTML={{ __html: post?.post_message || '' }}
              />
            ) : null}
          </>
        )}
      </div>
      <LockedPost
        post={post}
        onSubscribe={onSubscribe}
        onUnlock={onUnlock}
        isSubscribed={isSubscribed}
        isUnlockable={isUnlockable}
      />
      {/* {isFooter && (
        <div className='post_footer_wrap disable_footer_actions'>
          <PostFooter
            actions={actions}
            id={post?.content_post_id}
            post={post}
            onTipClick={onTipClick}
            onCommentIconClick={() => {
              // console.log('comment icon click');
            }}
            showComments={false}
            onPostLikeChange={props.onPostLikeChange}
            onLoadMoreComments={onLoadMoreComments}
            onFavoriteChange={onFavoriteChange}
            updatePost={updatePost}
          />
        </div>
      )} */}
    </div>
  );
});
const Post = forwardRef((props: IPostProps, ref: any) => {
  const [showComment, setShowComment] = useState<boolean>(props.showComments || false);
  const { user, post, isFeedView = false, isSubscribed: isSub = false, updatePost } = props;

  const setPostCounter = useSellerDetailStore().setPostCounter;
  const unViewdCount = useSellerDetailStore().unViewdCount;
  const refs = useRef<any>(null);
  const { isOnScreen, onScreenPost } = useInView(refs, post);
  const { send, subscribe, unsubscribe } = useSocket();
  useEffect(() => {
    if (isOnScreen && !onScreenPost?.has_viewed && isFeedView) {
      if (!!unViewdCount) {
        setPostCounter(unViewdCount - 1);

        updatePost?.({
          ...onScreenPost,
          has_viewed: 1,
        });
      }
      send(
        JSON.stringify({
          action: 'post',
          type: 'viewed',
          payload: { content_post_id: onScreenPost?.content_post_id },
        }),
      );
      subscribe('viewed', onScreenPost?.content_post_id + '', (data: any) => {
        if (data?.data) {
          updatePost?.({
            ...onScreenPost,
            has_viewed: data?.data?.has_viewed,
          });
        }
      });
    }
    return () => {
      unsubscribe('viewed', onScreenPost?.content_post_id + '');
    };
  }, [isOnScreen, onScreenPost?.content_post_id, onScreenPost?.has_viewed]);

  const isSubscribed = isSub || user?.subscription_id !== null;
  const isUnlockable = !!post?.unlockable && !post.is_unlocked;
  const isUnlocked = !!post?.unlockable && !!post.is_unlocked;
  if (!isSubscribed && !isFeedView) {
    return (
      <SubscribeOrUnlockNormal
        {...props}
        onCommentIconClick={setShowComment}
        showComments={showComment}
        ref={refs}
      />
    );
  }
  if (post?.access_type !== MEMEBER_TYPE.NON_MEMBER_ALL) {
    if (!isSubscribed && isUnlockable) {
      return (
        <FreePost
          {...props}
          isFreeUnlock={true}
          onCommentIconClick={setShowComment}
          showComments={showComment}
          ref={refs}
        />
      );
    }
    if (!isSubscribed && isUnlocked) {
      return (
        <FreePost
          ref={refs}
          {...props}
          onCommentIconClick={setShowComment}
          showComments={showComment}
        />
      );
    }
    if (!isSubscribed || isUnlockable) {
      return (
        <SubscribeOrUnlockNormal
          {...props}
          onCommentIconClick={setShowComment}
          showComments={showComment}
          ref={refs}
        />
      );
    }
  }

  return (
    <>
      <FreePost
        ref={refs}
        {...props}
        onCommentIconClick={setShowComment}
        showComments={showComment}
      />
    </>
  );
});

export default Post;
