const WifiIcon = (props: IConProps) => {
  const { width = 16, height = 16, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_1_2100)'>
        <path
          d='M12.2427 12.9093C13.0818 12.0702 13.6532 11.0011 13.8847 9.83723C14.1162 8.67335 13.9974 7.46695 13.5433 6.3706C13.0891 5.27425 12.3201 4.33718 11.3334 3.67789C10.3467 3.01861 9.18669 2.66672 8 2.66672C6.81332 2.66672 5.65328 3.01861 4.66659 3.67789C3.6799 4.33718 2.91086 5.27425 2.45673 6.3706C2.0026 7.46695 1.88378 8.67335 2.11529 9.83723C2.34679 11.0011 2.91823 12.0702 3.75734 12.9093'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.3573 11.024C10.8234 10.5578 11.1408 9.96384 11.2693 9.31726C11.3979 8.67069 11.3318 8.00051 11.0795 7.39147C10.8272 6.78244 10.3999 6.26189 9.8518 5.89566C9.30366 5.52943 8.65923 5.33395 7.99999 5.33395C7.34076 5.33395 6.69633 5.52943 6.14818 5.89566C5.60004 6.26189 5.17279 6.78244 4.92048 7.39147C4.66816 8.00051 4.6021 8.67069 4.73064 9.31726C4.85919 9.96384 5.17657 10.5578 5.64266 11.024'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.33334 8.66667C7.33334 8.84348 7.40358 9.01305 7.52861 9.13807C7.65363 9.2631 7.8232 9.33333 8.00001 9.33333C8.17682 9.33333 8.34639 9.2631 8.47141 9.13807C8.59644 9.01305 8.66668 8.84348 8.66668 8.66667C8.66668 8.48986 8.59644 8.32029 8.47141 8.19526C8.34639 8.07024 8.17682 8 8.00001 8C7.8232 8 7.65363 8.07024 7.52861 8.19526C7.40358 8.32029 7.33334 8.48986 7.33334 8.66667Z'
          stroke={stroke}
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_2100'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WifiIcon;
