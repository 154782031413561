const CreditsIcon = (props: IConProps) => {
  const { width = 16, height = 16, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_168_3885)'>
        <path
          d='M6.75 10.5C6.75 11.7427 8.7645 12.75 11.25 12.75C13.7355 12.75 15.75 11.7427 15.75 10.5C15.75 9.25723 13.7355 8.24998 11.25 8.24998C8.7645 8.24998 6.75 9.25723 6.75 10.5Z'
          stroke={stroke}
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.75 10.5V13.5C6.75 14.742 8.7645 15.75 11.25 15.75C13.7355 15.75 15.75 14.742 15.75 13.5V10.5'
          stroke={stroke}
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.25 4.5C2.25 5.304 3.108 6.0465 4.5 6.4485C5.892 6.8505 7.608 6.8505 9 6.4485C10.392 6.0465 11.25 5.304 11.25 4.5C11.25 3.696 10.392 2.9535 9 2.5515C7.608 2.1495 5.892 2.1495 4.5 2.5515C3.108 2.9535 2.25 3.696 2.25 4.5Z'
          stroke={stroke}
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.25 4.5V12C2.25 12.666 2.829 13.0875 3.75 13.5'
          stroke={stroke}
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.25 8.24998C2.25 8.91598 2.829 9.33748 3.75 9.74998'
          stroke={stroke}
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_168_3885'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CreditsIcon;
