import { HomePageRouteSK } from 'components/skeletons/HomePageFeedsSk';
import NotificatinsRouteSK from 'components/skeletons/NotificatinsRouteSK';
import { AuthIsSignedIn } from 'contexts/authContext';
import PrivateLayout from 'layouts/privatelayout';
import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Loadable } from 'routes';

const ChangeEmail = Loadable(lazy(() => import('pages/auth/ChangeEmail')));

const PrivateChat = Loadable(lazy(() => import('pages/PrivateChat')));
const ChangePassword = Loadable(lazy(() => import('pages/auth/ChangePassword')));
const SignOut = Loadable(lazy(() => import('pages/auth/SignOut')));
const Collections = Loadable(lazy(() => import('pages/collections')));
const Billings = Loadable(lazy(() => import('pages/membership')));
const CollectionRightView = Loadable(lazy(() => import('pages/collections/RightView')));
const BookMarkRightView = Loadable(lazy(() => import('pages/collections/BookMarkRightSide')));
const Home = Loadable(
  lazy(() => import('pages/home')),
  HomePageRouteSK,
);
const Credits = Loadable(lazy(() => import('pages/my-profile/pages/credits')));
const Profile = Loadable(lazy(() => import('pages/my-profile/pages/profile')));
const Membership = Loadable(lazy(() => import('pages/membership/index')));
const Notifications = Loadable(
  lazy(() => import('pages/notifications')),
  NotificatinsRouteSK,
);
const SellerProfile = Loadable(lazy(() => import('pages/seller-profile')));
const Password = Loadable(lazy(() => import('pages/settings/pages/password')));
const Account_Settings = Loadable(
  lazy(() => import('pages/settings/right-pages/account_settings')),
);
const SettingsSubNav = Loadable(lazy(() => import('pages/settings/index')));
const Subscriptions = Loadable(lazy(() => import('pages/subscriptions')));
const SubsRightView = Loadable(lazy(() => import('pages/subscriptions/subsRighView')));

import GlobalUserCheckslayout from 'layouts/GlobalUserCheckslayout';
import AppLayout from 'layouts/applayout';
import ScrollAppLayout from 'layouts/applayout/AppLayout';
import MyProfileWrapper from 'pages/my-profile/Account_profile_wallet_wrapper';

const Messages = Loadable(lazy(() => import('pages/messages')));
const BookMarkPostWrapper = Loadable(lazy(() => import('pages/SinglePost/bookmarkPostWrapper')));
const CollectionsPostWrapper = Loadable(
  lazy(() => import('pages/SinglePost/collectionsPostWrapper')),
);
const PendingProfile = Loadable(lazy(() => import('pages/pendingprofile')));
const SubscriptionsPostWrapper = Loadable(
  lazy(() => import('pages/SinglePost/subscriptionsPostWrapper')),
);
const SellerPostWrapper = Loadable(lazy(() => import('pages/SinglePost/sellerPostWrapper')));
const UnLockSellerPostView = Loadable(lazy(() => import('pages/SinglePost/UnLockSellerPostView')));
const FeedsPostWrapper = Loadable(lazy(() => import('pages/SinglePost/feedsPostWrapper')));

const MessageRightView = Loadable(lazy(() => import('pages/messages/messageRightSide')));
// const MessageRightView = Loadable(lazy(() => import('pages/messages/messageRightSidevirtual')));
const MediaGallery = Loadable(lazy(() => import('pages/messages/components/MediaGallery')));
const ChatHistory = Loadable(lazy(() => import('pages/messages/components/ChatHistory')));
// const DISCOVERROUTES: RouteObject = {
//   path: 'discover',
//   element: <DiscoverAppLayout />,
//   children: [{ index: true, element: <Discover /> }],
// };
// const MYPROFILEROUTES: RouteObject = {
//   path: '/my-profile',
//   element: <MyProfile />,
//   children: [
//     { path: 'profile', index: true, element: <Profile /> },
//     { path: 'credits', element: <Credits /> },
//     { path: 'prefrences', element: <Prefrences /> },
//     { path: 'account', element: <Account_Settings /> },
//     { path: 'account/change-password', index: true, element: <Password /> },
//     { path: 'account/change-email', index: true, element: <ChangeEmail /> },
//   ],
// };
const MESSAGESROUTES: RouteObject = {
  path: '/messages',
  element: <Messages />,
  children: [
    {
      path: ':id',
      element: <MessageRightView />,
      children: [
        { path: 'gallery', element: <MediaGallery /> },
        { path: 'chat-history', element: <ChatHistory /> },
      ],
    },
  ],
};
const SETTINGSROUTES: RouteObject = {
  path: '',
  element: <SettingsSubNav />,

  children: [
    {
      path: 'settings',
      element: <Outlet />,
      children: [
        { index: true, element: <Account_Settings /> },
        { path: 'change-password', element: <Password /> },
        { path: 'change-email', element: <ChangeEmail /> },
      ],
    },
    { path: 'my-wallet', element: <Credits /> },
    { path: 'my-account', element: <Profile /> },
    { path: 'my-membership', element: <Membership /> },
  ],
};
const SUBSCRIPTIONSROUTES: RouteObject = {
  path: 'subscriptions',
  element: <Subscriptions />,
  children: [{ path: ':id', element: <SubsRightView /> }],
};
const COLLECTIONSSROUTES: RouteObject = {
  path: 'collections',
  element: <Collections pathName='/collections' />,
  children: [{ path: ':id', element: <CollectionRightView /> }],
};
const BILLINGNSROUTES: RouteObject = {
  path: 'billings',
  element: <Billings pathName='/billings' />,
  children: [{ path: ':id', element: <CollectionRightView /> }],
};
const BOOKMARKROUTES: RouteObject[] = [
  {
    path: 'bookmarks',
    element: <Collections pathName='/bookmarks' />,
    children: [
      {
        path: ':id',
        element: <BookMarkRightView />,
      },
    ],
  },
];

export const privateRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthIsSignedIn>
        <GlobalUserCheckslayout>
          <Outlet />
        </GlobalUserCheckslayout>
      </AuthIsSignedIn>
    ),
    children: [
      {
        path: 'pending-profile',
        element: <PendingProfile className='pending-profile body-default-scroll' />,
      },
      {
        path: 'private-chat/:id/:sessionId',
        element: <PrivateChat className='chat-private' />,
      },
      {
        path: '/',
        element: <ScrollAppLayout className='body-default-scroll' />,
        children: [
          { index: true, element: <Home mode='discover' key={'discover'} /> },
          { path: 'post-view', element: <FeedsPostWrapper /> },
          { path: 'bookmarks/:id/post-view', element: <BookMarkPostWrapper /> },
          { path: 'collections/:id/post-view', element: <CollectionsPostWrapper /> },
          { path: 'subscriptions/:id/post-view', element: <SubscriptionsPostWrapper /> },
        ],
      },
      {
        path: '/post-view',
        element: <ScrollAppLayout className='body-default-scroll' />,
        children: [{ index: true, element: <FeedsPostWrapper /> }],
      },
      {
        path: '/notifications',
        element: <ScrollAppLayout className='body-default-scroll' />,
        children: [{ index: true, element: <Notifications /> }],
      },
      {
        path: '/seller-profile/:id',
        element: <ScrollAppLayout className='body-default-scroll' />,
        children: [
          { index: true, element: <SellerProfile /> },
          { path: 'post-view', element: <SellerPostWrapper /> },
          { path: 'unlock-view', element: <UnLockSellerPostView /> },
        ],
      },
      {
        path: '/',
        element: <ScrollAppLayout className='body-default-scroll' />,
        children: [COLLECTIONSSROUTES, BILLINGNSROUTES, SUBSCRIPTIONSROUTES, ...BOOKMARKROUTES],
      },
      {
        path: '/',
        element: <AppLayout />,
        children: [
          // { index: true, element: <Home /> },
          { path: 'changepassword', element: <ChangePassword /> },
          { path: 'signout', element: <SignOut /> },
          {
            path: '',
            element: <PrivateLayout />,
            children: [
              SETTINGSROUTES,
              MESSAGESROUTES,
              // SUBSCRIPTIONSROUTES,
              // { path: 'notifications', element: <Notifications /> },
              // { path: 'seller-profile/:id', element: <SellerProfile /> },
              // MYPROFILEROUTES,
              // DOUBLEROUTES,
            ],
          },
        ],
      },
    ],
  },
];
