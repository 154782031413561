import { PauseIcon, PlayIcon } from 'assets/svgs';
import { forwardRef, useRef, useState } from 'react';
import ReactPlayer, { ReactPlayerProps } from 'react-player';
import { OnProgressProps } from 'react-player/base';
import './index.scss';

// Only loads the YouTube player
type IVdideoProps = ReactPlayerProps & {
  className?: string;
  controls?: boolean;
};
const defaultAttrs = {
  controlsList: 'nodownload',
  onContextMenu: (e: any) => e?.preventDefault(),
};
const VideoPlay = (
  {
    url,
    mute,
    loop,
    playing,
    className = 'videoPlayer',
    attributes = {},
    controls = true,
    ...rest
  }: IVdideoProps,
  ref: any,
) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const playerRef = useRef<any>(null);
  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  const handleProgress = ({ played }: OnProgressProps) => {
    setProgress(played * 100);
  };
  const handleSeek = (seekTime: number) => {
    playerRef.current.seekTo(seekTime);
  };
  return (
    <div className={`rc-vidoe-player ${className}`}>
      <ReactPlayer
        ref={(ref as any) || playerRef}
        url={url}
        muted={mute}
        controls={controls}
        className={'react-player'}
        width='100%'
        height='100%'
        playing={isPlaying}
        loop={loop}
        config={{
          file: {
            attributes: { ...defaultAttrs, ...attributes },
          },
        }}
        onProgress={handleProgress}
        {...rest}
      />
      <div className={`${isPlaying ? 'video-pause-icon' : 'video-play-icon'} custom-video-control`}>
        {!isPlaying ? (
          <PlayIcon onClick={handlePlayPause} />
        ) : (
          <PauseIcon onClick={handlePlayPause} />
        )}
      </div>
      <div
        className='custom-video-progress-bar-container'
        onClick={(e) => {
          const progressBar = e.currentTarget as HTMLDivElement;
          const boundingRect = progressBar.getBoundingClientRect();
          const offsetX = e.clientX - boundingRect.left;
          const progressBarWidth = boundingRect.width;
          const seekTime = (offsetX / progressBarWidth) * playerRef.current.getDuration();
          handleSeek(seekTime);
        }}
      >
        <div className='custom-video-progress-bar' style={{ width: `${progress}%` }}></div>
      </div>
      <div className='video-time'></div>
      {/* Other controls */}
    </div>
  );
};

export default forwardRef(VideoPlay);
