const Comments = (props: IConProps) => {
  const { width = 24, height = 24, stroke = 'currentColor', ...rest } = props;
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_101_777)'>
        <path
          d='M11 0C4.925 0 0 4.925 0 11C0 17.075 4.925 22 11 22C17.075 22 22 17.075 22 11C22 4.925 17.075 0 11 0ZM11 20C6.029 20 2 15.971 2 11C2 6.029 6.029 2 11 2C15.971 2 20 6.029 20 11C20 15.971 15.971 20 11 20Z'
          fill='#04B3F3'
        />
        <path
          d='M10.8262 13C11.9307 13 12.8262 12.1046 12.8262 11C12.8262 9.89543 11.9307 9 10.8262 9C9.7216 9 8.82617 9.89543 8.82617 11C8.82617 12.1046 9.7216 13 10.8262 13Z'
          fill='#04B3F3'
        />
        <path
          d='M15.689 12.0028C15.335 11.9918 14.982 12.1058 14.712 12.3458C14.648 12.4068 14.432 12.7518 14.317 12.9538C14.059 13.4168 13.718 13.8158 13.303 14.1418C12.239 14.9778 10.841 15.2118 9.56003 14.7688C8.84603 14.5208 8.22103 14.0768 7.75403 13.4818C6.40703 11.7708 6.70303 9.28076 8.41403 7.93376C9.39603 7.16376 10.648 6.90176 11.859 7.21576C12.997 7.43676 13.869 8.48776 14.109 8.80576C14.607 9.32376 15.431 9.35576 15.976 8.86776C16.431 8.46076 16.558 7.79576 16.283 7.25076C16.242 7.16776 16.031 6.90276 15.984 6.85476C14.673 5.26476 12.76 4.43976 10.838 4.43976C9.40403 4.43976 7.96503 4.89876 6.76803 5.84176C6.45603 6.08976 6.16402 6.36676 5.90202 6.66376C3.78602 9.05876 3.68602 12.6198 5.66502 15.1338C6.75803 16.5218 8.32702 17.4028 10.082 17.6118C11.834 17.8188 13.568 17.3318 14.957 16.2388C15.289 15.9748 15.602 15.6758 15.884 15.3498C16.479 14.7208 16.811 13.9868 16.813 13.9788C16.979 13.5708 17.084 12.9678 16.624 12.4538C16.382 12.1838 16.051 12.0228 15.689 12.0028Z'
          fill='#04B3F3'
        />
      </g>
      <defs>
        <clipPath id='clip0_101_777'>
          <rect width='22' height='22' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Comments;
