import { useEffect, useRef, useState } from 'react';

const DiscriptionWrapper = ({
  discription = '',
  buttonTitle = { showMore: 'More info', showLess: 'Less info' },
}: {
  discription?: string;
  buttonTitle?: { showMore?: string; showLess?: string };
}) => {
  const descriptionRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [hasMoreThanTwoLines, setHasMoreThanTwoLines] = useState(false);

  useEffect(() => {
    const descriptionElement = descriptionRef.current;
    if (descriptionElement) {
      const lineHeight = parseInt(getComputedStyle(descriptionElement).lineHeight, 10);
      const { scrollHeight } = descriptionElement;
      const hasMoreLines = scrollHeight / lineHeight > 2;
      if (hasMoreLines) {
        setShowMore(true);
      }
      setHasMoreThanTwoLines(hasMoreLines);
    }
  }, []);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={`user-bio-text ${showMore ? 'show-all' : ''}`}>
      <div ref={descriptionRef} className='description-wrapper'>
        <p>
          {showMore ? (
            <div
              className='post_text post_message'
              dangerouslySetInnerHTML={{ __html: `${discription.substring(0, 140)}...` || '' }}
            />
          ) : (
            <div
              className='post_text post_message'
              dangerouslySetInnerHTML={{ __html: discription || '' }}
            />
          )}
        </p>
      </div>
      {hasMoreThanTwoLines && (
        <span className='more-info' onClick={toggleShowMore}>
          {showMore ? buttonTitle.showMore : buttonTitle.showLess}
        </span>
      )}
    </div>
  );
};
export default DiscriptionWrapper;
