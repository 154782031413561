import { useEffect, useState } from 'react';
import { getLocalStorage, setLocalStorage } from 'utils';

const useTheme = () => {
  const [isDarkMode, setIsDark] = useState(
    getLocalStorage('onlyclone_theme', false) === 'dark_mode',
  );
  useEffect(() => {
    const isdarkMode = getLocalStorage('onlyclone_theme', false) === 'dark_mode';
    setIsDark(!!isdarkMode);
    const bodyEl = document.querySelector('.react_site');
    if (bodyEl) {
      const themeMode = !!isdarkMode ? 'dark_mode' : 'light_mode';
      bodyEl.setAttribute('id', themeMode);
    }
  }, []);

  const onToggleTheme = () => {
    const bodyEl = document.querySelector('.react_site');
    if (bodyEl) {
      const themeMode = !isDarkMode ? 'dark_mode' : 'light_mode';
      bodyEl.setAttribute('id', themeMode);
      setIsDark(!isDarkMode);
      setLocalStorage('onlyclone_theme', themeMode, false);
    }
  };
  return {
    onToggleTheme,
    isDarkMode,
  };
};

export default useTheme;
