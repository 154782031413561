import { MEDIA_CROP_SIZES } from 'appconstants';
import { AvatarNameIcon, BookmarkIcon, ProfileTickIcon } from 'assets/svgs';
import Image from 'components/Image';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { IPostProps } from '..';
import './postheader.scss';

type Props = {
  user: IPostProps['user'];
  showActionButton?: boolean;
  createdAt?: ReactNode;
  renderDropDown?: JSX.Element;
  onUserNameClick?: (user: IPostProps['user']) => void;
  post?: IPost;

  showbookmark?: boolean;

  onFavoriteChange?: (props: { id: number; isFavorite: boolean; post?: IPost }) => void;
};
// const items = [
//   {
//     text: 'open link in new tab',
//     onClick: (e: string) => window.open(e, '_blank'),
//     divider: false,
//   },
//   {
//     text: 'open link in new window',
//     onClick: (e: string) => window.open(e, '_blank', 'width=600,height=400'),
//     divider: false,
//   },
//   {
//     text: 'open link in incognito window',
//     onClick: (e: string) => window.open(e, '_blank', 'incognito=yes'),
//     divider: true,
//   },

//   {
//     text: 'copy link address',
//     onClick: (url: string) => {
//       const currentUrl = window.location.href;
//       const tempInput = document.createElement('input');
//       tempInput.value = currentUrl + url;
//       document.body.appendChild(tempInput);
//       tempInput.select();
//       document.execCommand('copy');
//       document.body.removeChild(tempInput);
//     },
//     divider: false,
//   },
// ];
const PostHeader = (props: Props) => {
  const {
    user,
    createdAt,
    showActionButton = false,
    post,
    showbookmark = true,

    onFavoriteChange,

    renderDropDown: RenderDropDown,
    onUserNameClick,
  } = props;
  // const { handleContextMenu, isShown, anchorPoint } = useContextMenu();
  const navigate = useNavigate();
  const title = user?.first_name + ' ' + user?.last_name;
  return (
    <div className='post_header'>
      <div className='post_header__personInfo'>
        <div className='post_header_image'>
          <Image
            filters={MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300)}
            rounded
            src={user?.profile_thumb?.url}
            fallbackComponent={
              <AvatarNameIcon text={(user?.first_name as string) || 'Incognito User'} />
            }
            height={50}
            width={50}
          />
        </div>
        <div className='post_person_details'>
          <a
            href={`/seller-profile/${user?.seller_account_id}`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onUserNameClick?.(user);
            }}
            className='post_user_details'

            // onContextMenu={handleContextMenu}
          >
            <span className='name'>{title ? title : 'Incognito User'}</span>{' '}
            {user?.last_name ? (
              <span className='post_verified_badge'>
                <ProfileTickIcon />
              </span>
            ) : null}
          </a>
          <a
            className='username'
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onUserNameClick?.(user);
            }}
          >
            @{user?.user_name}
          </a>
        </div>
      </div>
      <div className='post_header__details'>
        {/* {createdAt ? <span className='time'>{createdAt}</span> : null}
        {showActionButton ? RenderDropDown : null} */}
        {showbookmark && (
          <div className={`${!!post?.in_bookmark ? 'star-image-liked' : ''} star-image`}>
            <BookmarkIcon
              fill={!!post?.in_bookmark ? 'var(--primary-light)' : 'none'}
              onClick={() => {
                onFavoriteChange &&
                  onFavoriteChange({
                    post: post,
                    id: post?.seller_account_id || 0,
                    isFavorite: !post?.in_bookmark,
                  });
              }}
            />
            {/* <EmptyStarIcon
                fill={!!post?.in_collection ? 'var(--primary-light)' : 'none'}
                onClick={() => {
                  onFavoriteChange &&
                    onFavoriteChange({
                      id: post?.seller_account_id || 0,
                      isFavorite: !Boolean(post?.in_collection),
                    });
                }}
              /> */}
          </div>
        )}
      </div>
      {/* <ContextMenu items={items as any} isShow={isShown} anchorPoint={anchorPoint} user={user} /> */}
    </div>
  );
};

export default PostHeader;
