const EditIcon = (props: IConProps) => {
  const { width = 16, height = 16, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M2.66667 13.3333H5.33334L12.3333 6.33333C12.687 5.97971 12.8856 5.5001 12.8856 5C12.8856 4.4999 12.687 4.02029 12.3333 3.66666C11.9797 3.31304 11.5001 3.11438 11 3.11438C10.4999 3.11438 10.0203 3.31304 9.66667 3.66666L2.66667 10.6667V13.3333Z'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9 4.33331L11.6667 6.99998'
        stroke={stroke}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default EditIcon;
