const BookmarkIcon = (props: IConProps) => {
  const {
    width = 24,
    height = 24,
    stroke = 'currentColor',
    fill = 'currentColor',
    ...rest
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_127_9058)'>
        <path
          d='M7 3H17C17.5304 3 18.0391 3.21071 18.4142 3.58579C18.7893 3.96086 19 4.46957 19 5V20L12 17L5 20V5C5 4.46957 5.21071 3.96086 5.58579 3.58579C5.96086 3.21071 6.46957 3 7 3Z'
          fill={fill}
          stroke={stroke}
          strokeWidth='2.25'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_127_9058'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default BookmarkIcon;
