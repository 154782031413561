'use client';

import { ReactNode } from 'react';
import { useMediaQuery } from 'react-responsive';
type Props = {
  children: ReactNode;
  isMobile?: boolean;

  width?: number;
};

const ResponsiveLayout = (props: Props) => {
  const { isMobile = false, children, width = 1040 } = props;
  const isMobileView = useMediaQuery({ query: `(max-width: ${width}px)` });

  if (isMobile) {
    return <>{isMobileView ? children : null}</>;
  }
  return <>{!isMobileView ? children : null}</>;
};

export default ResponsiveLayout;
