const ProfileIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_2_50)'>
        <path
          d='M3.5 14C3.5 15.3789 3.77159 16.7443 4.29926 18.0182C4.82694 19.2921 5.60036 20.4496 6.57538 21.4246C7.55039 22.3996 8.70791 23.1731 9.98182 23.7007C11.2557 24.2284 12.6211 24.5 14 24.5C15.3789 24.5 16.7443 24.2284 18.0182 23.7007C19.2921 23.1731 20.4496 22.3996 21.4246 21.4246C22.3996 20.4496 23.1731 19.2921 23.7007 18.0182C24.2284 16.7443 24.5 15.3789 24.5 14C24.5 12.6211 24.2284 11.2557 23.7007 9.98182C23.1731 8.70791 22.3996 7.55039 21.4246 6.57538C20.4496 5.60036 19.2921 4.82694 18.0182 4.29927C16.7443 3.77159 15.3789 3.5 14 3.5C12.6211 3.5 11.2557 3.77159 9.98182 4.29927C8.70791 4.82694 7.55039 5.60036 6.57538 6.57538C5.60036 7.55039 4.82694 8.70791 4.29926 9.98182C3.77159 11.2557 3.5 12.6211 3.5 14Z'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.5 11.6666C10.5 12.5949 10.8687 13.4851 11.5251 14.1415C12.1815 14.7979 13.0717 15.1666 14 15.1666C14.9283 15.1666 15.8185 14.7979 16.4749 14.1415C17.1313 13.4851 17.5 12.5949 17.5 11.6666C17.5 10.7384 17.1313 9.84813 16.4749 9.19175C15.8185 8.53538 14.9283 8.16663 14 8.16663C13.0717 8.16663 12.1815 8.53538 11.5251 9.19175C10.8687 9.84813 10.5 10.7384 10.5 11.6666Z'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.196 21.9905C7.48476 21.0294 8.07563 20.187 8.88095 19.5883C9.68628 18.9895 10.6632 18.6663 11.6667 18.6666H16.3333C17.3381 18.6663 18.3162 18.9903 19.1221 19.5904C19.928 20.1905 20.5187 21.0347 20.8063 21.9975'
          stroke={stroke}
          strokeWidth='1.75'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2_50'>
          <rect width='28' height='28' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ProfileIcon;
