import { AvatarNameIcon, ProfileTickIcon, Tick } from 'assets/svgs';

import { LOCAL_IMAGES, MEDIA_CROP_SIZES, SUBSDURATION } from 'appconstants';
import classNames from 'classnames';
import AvatarStatus from 'components/AvatarImage';
import Image from 'components/Image';
import { RequestLoader } from 'components/Loader/SiteLoader';
import Modal from 'components/Modal';
import Scrollbar from 'components/Scrollbar';
import SubscriptionButton from 'components/SubscriptionButton';
import { ApiModels } from 'queries/apiModelMapping';
import useCreateItem from 'queries/useCreateItem';
import { useEffect, useState } from 'react';
import { objectKeys } from 'utils';
import './modal.scss';
type IDuratuon = 'weekly' | 'monthly' | 'yearly' | 'lifetime';
type Props = {
  className?: string;
  img?: string;
  name?: string;
  coverImg?: string;
  onClick?: () => void;
  username?: string;
  isProfileVerified?: boolean;
  isOnline?: boolean;
  isOpen: boolean;
  onClose?: () => void;
  subsDuration?: IDuratuon; // Change this type to match your actual type
  seller?: any; // Change this type to match your actual type
  callback?: (e?: any) => void;
};
function SubcribeModel(props: Props) {
  const {
    className,
    img,
    name,
    coverImg,
    onClick,
    username,
    isProfileVerified,
    isOnline = true,
    isOpen,
    onClose,
    subsDuration: subs,
    seller,
    callback,
  } = props;
  const subscribe = useCreateItem({
    modelName: ApiModels.Subscriptions,
    queryKey: seller?.id,
  });
  const [subsDuration, setSubsDuration] = useState<IDuratuon | undefined>(subs);
  useEffect(() => {
    if (subs) {
      setSubsDuration(subs);
    }
  }, [subs]);

  // const handleClick = (d: IDuratuon) => {
  //   setSubsDuration(d);
  // };
  const handleClick = async (duration?: IDuratuon) => {
    await callback?.(duration || subsDuration);
  };
  const getSubscButton = () => {
    const keys = objectKeys(SUBSDURATION);
    return (
      <>
        {keys.map((ele) => {
          const isEnable = `${ele}_enable`;
          const price = `${ele}_price`;
          return (
            <SubscriptionButton
              hasTick={true}
              key={ele}
              show={!!seller?.[isEnable]}
              label={`${ele.toUpperCase()} SUBSCRIPTION`}
              price={`${seller?.[price] || 'FREE'}`}
              handleClick={() => handleClick(ele)}
            />
          );
        })}
      </>
    );
  };
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      className={`${className} profile-card-modal`}
      showHeaderClose={false}
      // title={<div className='modal-header-wrap'>SUBSCRIPTION</div>}
      isOpen={isOpen}
      card-modal
      showHeader
      okText='SUBSCRIBE'
      onOk={() => callback?.(subsDuration)}
      isDisabled={!subsDuration}
      showFooter={false}
      onClose={onClose}
    >
      <Scrollbar autoHeight autoHeightMax={`calc(100vh - 140px)`}>
        <div
          className={classNames(className, 'profile_favourite_card')}
          onClick={() => onClick?.()}
        >
          <div className='profile_favourite_card__bg-img'>
            <Image
              filters={MEDIA_CROP_SIZES.PROFILE_HEADER_SCALE()}
              src={coverImg}
              fallbackUrl={LOCAL_IMAGES.suggestion_placeholder}
            />
            {isOnline && <span className='profile_favourite_card__available'>Available now</span>}
          </div>
          <div className='profile_favourite_card__details-wrap'>
            <div className='profile_favourite_card__details'>
              <div className='profile_favourite_card__user-image'>
                <AvatarStatus
                  isActive={isOnline}
                  src={img}
                  fallbackComponent={<AvatarNameIcon text={name || 'Incongnito User'} />}
                />
              </div>
              <div className='profile_favourite_card__text-holder'>
                <strong className='profile_favourite_card__title'>
                  <span className='profile_favourite_card__title_text'>{name}</span>{' '}
                  {isProfileVerified && <ProfileTickIcon width='12' height='12' fill='#fff' />}
                </strong>
                <div className='profile_favourite_card__name-area'>
                  {username ? (
                    <span className='profile_favourite_card__user-name'>@{username}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <strong className='profile-card-modal__title'>SUBSCRIBE AND GET THESE BENEFITS:</strong>
            <ul className='profile-card-modal__list'>
              <li>
                <Tick />
                Full access to this {`user's`} content
              </li>
              <li>
                <Tick />
                Direct essage with this user
              </li>
              <li>
                <Tick />
                Cancel your subscription at any timet
              </li>
            </ul>

            {!!subs ? (
              <SubscriptionButton
                show={!!seller?.[`${subs}_enable`]}
                label={`${subs.toUpperCase()} SUBSCRIPTION`}
                price={`${seller?.[`${subs}_price`] || 'FREE'}`}
                hasTick={true}
                handleClick={handleClick}
              />
            ) : (
              getSubscButton()
            )}
          </div>
        </div>
        <RequestLoader isLoading={subscribe.isMutating} />
      </Scrollbar>
    </Modal>
  );
}

export default SubcribeModel;
