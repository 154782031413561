const ConnectionIcon = (props: IConProps) => {
  const { width = 16, height = 16, ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_1_1264)'>
        <path
          d='M4 6.6667C3.99943 5.94729 4.1652 5.23749 4.48435 4.59274C4.8035 3.94799 5.2674 3.38577 5.8398 2.94998C6.4122 2.51419 7.0776 2.21663 7.78403 2.08057C8.49046 1.9445 9.21878 1.9736 9.9121 2.1656C10.6054 2.35759 11.245 2.70729 11.7808 3.18736C12.3166 3.66743 12.7341 4.26488 13.0008 4.93303C13.2675 5.60118 13.3761 6.32195 13.3182 7.03903C13.2602 7.7561 13.0372 8.45006 12.6667 9.0667C12.2876 9.57207 11.8387 10.021 11.3333 10.4C10.7425 10.9566 10.2865 11.6406 10 12.4C9.79312 12.791 9.50236 13.1314 9.14853 13.3968C8.7947 13.6622 8.38655 13.8461 7.95333 13.9352C7.52011 14.0244 7.07252 14.0167 6.64262 13.9127C6.21272 13.8086 5.81115 13.6108 5.46667 13.3334'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.66665 6.66662C6.66265 6.33203 6.74266 6.00178 6.89936 5.70612C7.05605 5.41046 7.28443 5.15884 7.56357 4.97431C7.8427 4.78978 8.16368 4.67823 8.49709 4.64989C8.8305 4.62154 9.1657 4.67731 9.47198 4.81207C9.77826 4.94683 10.0458 5.15629 10.2502 5.42125C10.4546 5.68622 10.5892 5.99822 10.6417 6.32869C10.6942 6.65916 10.663 6.99752 10.5509 7.3128C10.4388 7.62807 10.2494 7.91018 9.99998 8.13328'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_1264'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ConnectionIcon;
