// import SlickSlider from 'components/3d-slider';
import { AuthIsNotSignedIn } from 'contexts/authContext';
import AuthWrapper from 'pages/auth/AuthWrapper';
import { lazy } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { Loadable } from 'routes';
const ForgotPassword = Loadable(lazy(() => import('pages/auth/ForgetPassword')));
const RequestCode = Loadable(lazy(() => import('pages/auth/RequestCode')));
const VerifyCode = Loadable(lazy(() => import('pages/auth/VerifyCode')));
// const Password = Loadable(lazy(() => import('pages/auth/Password')));
// const Password = Loadable(lazy(() => import('pages/test/Password/Password')));

const SignIn = Loadable(lazy(() => import('pages/auth/SignIn')));
const SignUp = Loadable(lazy(() => import('pages/auth/SignUp')));
const Landing = Loadable(lazy(() => import('pages/landing/index')));
const Password = Loadable(lazy(() => import('pages/landing/CreatePassword')));

const CloneYourself = Loadable(lazy(() => import('pages/auth/CloneYourself')));
export const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: (
      <AuthIsNotSignedIn>
        {/* <Scrollbar className='authpage-scroll'> */}
        <AuthWrapper />
        {/* </Scrollbar> */}
      </AuthIsNotSignedIn>
    ),
    children: [
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/signup',
        element: <SignUp />,
      },

      {
        path: '/requestcode',
        element: <RequestCode />,
      },
      {
        path: '/forgotpassword',
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: '/verify',
    element: (
      <AuthIsNotSignedIn>
        <Outlet />
      </AuthIsNotSignedIn>
    ),
    children: [{ index: true, element: <Password /> }],
  },

  {
    path: '/07e785aedace0962f53b411dc4019773dd8ef9cc',
    element: (
      <AuthIsNotSignedIn>
        <Outlet />
      </AuthIsNotSignedIn>
    ),
    children: [{ index: true, element: <Landing /> }],
  },
  {
    path: '/',
    element: (
      <AuthIsNotSignedIn>
        <Outlet />
      </AuthIsNotSignedIn>
    ),
    children: [
      {
        path: '/clone-auth',
        element: <CloneYourself />,
      },
    ],
  },
];
