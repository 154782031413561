import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { AppLikesMap } from 'appconstants';
import { createSelectorHooks } from 'auto-zustand-selectors-hook';
type IStripeResponse = Partial<IStripeCard>[];
interface IStore {
  likeTypes: IAppLikeTypes[];
  stripeCards: IStripeResponse;
  user?: Partial<IUser>;

  availableCredits?: number;
}
export const resetters: (() => void)[] = [];
type IActionTypes = {
  setLikeTypes: (props: IAppLikeTypes[]) => void;
  setCards: (props: IStripeResponse) => void;
  setUser: (user: Partial<IUser>) => void;
  setPartialUser: (user: Partial<IUser>) => void;
  setCredit: (credit: number) => void;
  resetStore: () => void;
};
const initialState: IStore | undefined = {
  likeTypes: AppLikesMap as any,
  stripeCards: [],
  availableCredits: 0,
  user: undefined,
};
export const useStoreBase = create(
  devtools(
    immer<IStore & IActionTypes>((set, get) => {
      resetters.push(() => set(initialState));
      const store = {
        ...initialState,
        // ...createLayoutSlice(set, get),
        resetStore: () => {
          set(() => initialState);
        },
        setLikeTypes: (props: IAppLikeTypes[]) => {
          set((state) => {
            state.likeTypes = props;
          });
        },
        setCards: (props: IStripeResponse) => {
          set((state) => {
            state.stripeCards = props;
          });
        },
        setUser: (props: Partial<IUser>) => {
          set((state) => {
            state.user = props;
            state.availableCredits = props.available_balance;
          });
        },
        setPartialUser: (props: Partial<IUser>) => {
          set((state) => {
            state.user = { ...state.user, ...props };
          });
        },
        setCredit: (credit: number) => {
          set((prev) => {
            prev.availableCredits = credit;
            if (prev.user) {
              prev.user.available_balance = credit;
            }
          });
        },
      };
      return store;
    }),
  ),
);
export const resetAllSlices = () => resetters.forEach((resetter) => resetter());
export const useStore = createSelectorHooks<IStore & IActionTypes>(useStoreBase);
