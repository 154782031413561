import { OnlyClonesmallLogo } from 'assets/svgs';
import Sidebar from 'components/UI/Sidebar';
import { Link } from 'react-router-dom';

const SidebarMenu = () => {
  return (
    <Sidebar>
      {/* <strong className='site-logo'> */}
      <Link to='/' className='side-logo'>
        <OnlyClonesmallLogo className='' />
        {/* <PynkAiLogo /> */}
      </Link>
      {/* </strong> */}
    </Sidebar>
  );
};

export default SidebarMenu;
