import Image from 'components/Image';
import React from 'react';

import { LOCAL_IMAGES, MEDIA_CROP_SIZES } from 'appconstants';
import './avatar.scss';
interface Props {
  className?: string;
  src?: string;
  isActive?: boolean;
  fallbackUrl?: string;
  filters?: imageEditorProps;
  fallbackComponent?: JSX.Element | null;
  cencerodUrl?: string | JSX.Element | null;
}

const AvatarStatus: React.FC<Props> = (props) => {
  const {
    className,
    src,
    isActive,
    fallbackUrl,
    filters = MEDIA_CROP_SIZES.PROFILE_THUMB_SCALE(300),
    fallbackComponent = null,
    cencerodUrl,
  } = props;
  const newsrc = src || (!!fallbackComponent ? '' : LOCAL_IMAGES.suggestion_placeholder);
  const fbUrl = !!fallbackComponent
    ? undefined
    : fallbackUrl || LOCAL_IMAGES.suggestion_placeholder;
  return (
    <div className={`${className} user-image`}>
      <Image
        cencerodUrl={cencerodUrl}
        filters={filters}
        src={newsrc}
        fallbackUrl={fbUrl}
        fallbackComponent={fallbackComponent}
        rounded
      />
      {isActive && <span className='status' style={{ backgroundColor: '#06D6A0' }}></span>}
    </div>
  );
};

export default AvatarStatus;
