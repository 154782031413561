import { BellIcon, HomeIcon, MessagesIcon, PynkLogo } from 'assets/svgs';
import classNames from 'classnames';
import AppNavLink from 'components/AppNavLink';
import ResponsiveLayout from 'layouts/ResponsiveLayout';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import Sidebar from '../Sidebar';
import Item from '../Sidebar/Item';
import MobileMenuHeader from './components/MenuHeader';
import './mobilemenu.scss';
const LINKS = [
  { title: 'HOME', icon: HomeIcon, path: '/', counter: 4 },
  { title: 'Notifications', icon: BellIcon, path: '/notifications' },
  // { title: 'More', icon: PynkLogo, path: '/' },
  { title: 'Messages', icon: MessagesIcon, path: '/messages' },
];
const MobileMenu = ({ onlyAvatar = false }: { onlyAvatar?: boolean }) => {
  const [isopen, setIsSideBarOpen] = useState(false);
  const navigate = useNavigate();
  const onProfileImageClick = () => {
    setIsSideBarOpen(false);
    navigate('/settings');
  };
  return (
    <>
      <ResponsiveLayout width={991} isMobile>
        <Sidebar
          className={classNames({
            mobile_sidebar_close: !isopen,
            mobile_sidebar_open: isopen,
          })}
        >
          <MobileMenuHeader
            onClose={() => setIsSideBarOpen(false)}
            onProfileImageClick={onProfileImageClick}
            name='Gat Parker'
            username='gat_parker'
          />
        </Sidebar>
      </ResponsiveLayout>
      <div
        className={classNames('mobile-menu-wrapper app_bottom_nav', {
          mobileMenu: true,
          mobile_sidebar_open: isopen,
        })}
      >
        <ul className='mobile-menu-list'>
          {!onlyAvatar && (
            <>
              {LINKS.map((link) => {
                const { title, ...rest } = link;
                return (
                  <li key={link.path} className='list-item'>
                    <AppNavLink to={link.path} activeClassName='active_nav_item'>
                      <Item {...rest} />
                    </AppNavLink>
                  </li>
                );
              })}
            </>
          )}
          <li className='list-item' onClick={() => setIsSideBarOpen(!isopen)}>
            <div className='menu-user-profile-image'>
              <PynkLogo />
            </div>
          </li>
        </ul>
        {/* <div className="sidebar-extra-content">
        <Button block rounded>New Post</Button>
      </div> */}
      </div>
    </>
  );
};

export default MobileMenu;
