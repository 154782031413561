import { PynkLogo } from 'assets/svgs';

import React, { useEffect, useState } from 'react';

import { RequestLoader } from 'components/Loader/SiteLoader';
import Model from 'components/Modal';
import Scrollbar from 'components/Scrollbar';
import { ApiModels } from 'queries/apiModelMapping';
import './tokenmodel.scss';
// import classNames from 'classnames';
// import Checkbox from 'components/Checkbox';
import classNames from 'classnames';
import Checkbox from 'components/Checkbox';
// import FocusInput from 'components/Input/focus-input';
import AddCard from 'components/AddCardModal/AddCard';
import useStripeHooks from 'hooks/useStripeHooks';
import toaster from 'lib/toaster';
import useCreateItem from 'queries/useCreateItem';
import useListItemsM from 'queries/useListItemsM';
import { useStoreBase } from 'store';
import { useCardsStore } from 'store/stores/AddCardStore';
import { useSubscriptionPackagesStore } from 'store/stores/SubscriptionPackagesStore';

const Credits = () => {
  const cardsStore = useCardsStore();
  const title = cardsStore.title;
  const [isAddingCredits, setIsAddingCredits] = useState(true);
  const subscriptionPackagesStore = useSubscriptionPackagesStore();
  const setSubscriptionPackages = subscriptionPackagesStore.setSubscriptionPackages;
  const setIsPackagesLoading = subscriptionPackagesStore.setIsLoading;
  const [isActive, setIsActive] = useState<ITokens>();
  const [status, setStatus] = useState<'progress' | 'complete' | 'error' | ''>('');
  const [creditsTokens, setCreditsTokens] = useState<ITokens[]>([]);

  const { onConfirmPaymentIntent } = useStripeHooks();
  const cartListTrigger = useListItemsM({
    modelName: ApiModels.Stripe,
    queryKey: 'user_stripe_cards',
  });
  const fetchListItems = useListItemsM({
    modelName: ApiModels.Tokens,
    queryKey: 'users_tokens_lists',
  });
  const cardList = useStoreBase()?.stripeCards || [];
  const setCards = useStoreBase()?.setCards || [];
  const availableCredits = useStoreBase()?.availableCredits || 0;
  const setCredit = useStoreBase()?.setCredit;
  const setUser = useStoreBase()?.setPartialUser;
  const createCredit = useCreateItem({
    modelName: ApiModels.AddCredits,
    queryKey: 'add-credits',
  });

  const createPaymentIntent = useCreateItem({
    modelName: ApiModels.Stripe,
    queryKey: 'create_intent',
  });

  useEffect(() => {
    setStatus('');
    fetchListItems
      .trigger({
        options: {},
      })
      .then((data) => {
        if (data?.data) {
          setIsActive(data.data[0]);
          const filteredPackages = data.data?.filter((item) => item.is_monthly !== 1);

          setCreditsTokens(filteredPackages || []);
          setSubscriptionPackages(data.data || []);
          setIsAddingCredits(!data.data.length);
        }
      })
      .catch((e) => {
        setIsPackagesLoading(false);
        console.log({ e });
      });
    return () => {
      setCreditsTokens([]);
    };
  }, []);
  const onHandleAddCredits = (id: string | number) => {
    setIsAddingCredits(true);

    setStatus('progress');
    createPaymentIntent
      .trigger({
        data: {
          token_package_id: id,
        } as any,
        options: {
          path: 'payment-intent',
        },
      })
      .then((paymentIntent: any) => {
        onConfirmPaymentIntent({ secret: paymentIntent })
          .then((res) => {
            createCredit
              .trigger({
                data: {
                  payment_intent_id: res?.paymentIntent.id,
                },
              })
              .then(() => {
                setIsAddingCredits(false);
                const updatedAmount = isActive?.token_count || 0;
                toaster.success('Credits added successfully!');
                setCredit(updatedAmount + availableCredits);
                // setIsActive({ credits: value, price: isActive.price, id: '1' });
                setStatus('complete');
                cardsStore.onSuccessCallback?.();
                cardsStore.resetCardStore();
              })
              .catch((e) => {
                toaster.error('Error in adding credits!');
                cardsStore.resetCardStore();
                setIsAddingCredits(false);
                setStatus('error');
                cardsStore.onErrorCallback?.();
              });
          })
          .catch((e) => {
            setIsAddingCredits(false);
            cardsStore.resetCardStore();

            setStatus('error');
            cardsStore.onErrorCallback?.();
            toaster.error(e?.message ? e.message : 'Error in adding credits!');
          });
      })
      .catch((e) => {
        toaster.error('Error in adding credits!');
        cardsStore.resetCardStore();
        setIsAddingCredits(false);
        cardsStore.onErrorCallback?.();
        setStatus('error');
      });
  };
  const handleStrippaymentMethod = (args: any) => {
    setStatus('progress');
    const newCard = { ...args, isPrimary: true };

    const newCardList = [...cardList];
    const primaryCardIndex = newCardList.findIndex((c) => c.isPrimary);
    if (primaryCardIndex !== -1) {
      newCardList[primaryCardIndex] = { ...newCardList[primaryCardIndex], isPrimary: false };
    }
    setCards([...(newCardList || []), newCard]);
    if (newCard.customer) {
      setUser({ stripe_customer_id: newCard.customer });
    }
    onHandleAddCredits(isActive?.id as number);
    toaster.success('Card and tokens added successfully!');
  };

  if (cartListTrigger.isMutating) return <RequestLoader isLoading={cartListTrigger.isMutating} />;
  const onCreditsClick = (e: any) => {
    setIsActive(e);
  };

  const handleClose = () => {
    setStatus('');
    cardsStore.resetCardStore();
  };
  const handleStripError = (a: any) => {
    toaster.error('Error in adding card!');
    setStatus('');
  };
  const TokensComponent = () => {
    // if (!cardList.length) {
    //   return null;
    // }

    return (
      <>
        {!isAddingCredits && !status && (
          <React.Fragment>
            <div className='token-heading-area'>
              <p>Add more tokens to unlock posts, give gifts and to unlock private chats.</p>
              <span className='token-heading-title'>TOKEN PURCHASE OPTIONS</span>
            </div>
            <div className='token-purchase-list'>
              {creditsTokens?.map((ele, i) => {
                return (
                  <div
                    key={ele?.id + i}
                    onClick={() => onCreditsClick?.(ele)}
                    className={classNames('credits-list-item', {
                      active: ele?.id === isActive?.id,
                    })}
                  >
                    <div className='credits-list-item__left'>
                      <PynkLogo />
                      <span className='credits-list-item__title'>{ele?.token_count || ''}</span>
                    </div>
                    <div className='credits-list-item__right'>
                      <span className='credits-list-item__title'>${ele?.Price || ''}.00</span>
                      <Checkbox isActive={isActive?.id === ele?.id} value={ele?.id} />
                    </div>
                  </div>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </>
    );
  };
  return (
    <Model
      isOpen={!!cardsStore.isOpenTokenModel}
      showHeader
      okText='SUBMIT'
      title={
        <div className='modal-header-wrap has-icon has-border'>
          {/* <PynkLogo /> {!!cardList.length ? 'ADD TOKENS' : 'Add Card and Tokens!'} */}
          <PynkLogo /> {title || 'This feature requires tokens to access'}
        </div>
      }
      showHeaderClose={false}
      // showFooter={!!cardList.length}
      showFooter={!!cardList.length}
      isDisabled={isAddingCredits}
      showSaveBtn={isAddingCredits}
      onClose={() => {
        // setStatus('');
        // setIsActive(creditsTokens[0]);
        cardsStore.resetCardStore();
      }}
      onOk={() => (!!cardList.length ? onHandleAddCredits(isActive?.id as number) : {})}
      className={`credits-modal`}
    >
      {!!cardsStore.isOpenTokenModel ? (
        <div className='credits-modal-scroll-holder'>
          <Scrollbar>
            <div className='modal-scroll-wrap'>
              {!!status && (
                <React.Fragment>
                  {status === 'progress' ? (
                    <div className='progress credit-info-message'>
                      <div className='credit-info-message_wrap'>
                        Your order is being processed..
                        <div className='circular-loader_wrap'>
                          <span className='dots-loader' />
                        </div>
                      </div>
                    </div>
                  ) : status === 'complete' ? (
                    <div className='complete credit-info-message'>
                      <div className='credit-info-message_wrap'>
                        Success! {isActive?.token_count} tokens have been added to your account.
                      </div>
                    </div>
                  ) : (
                    <div className='error credit-info-message'>
                      <div className='credit-info-message_wrap'>
                        Card is not valid. Please update your info and try again.
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
              {TokensComponent()}
              {!cardList?.length && !status ? (
                <>
                  <div className='token-heading-area' style={{ marginTop: '1rem' }}>
                    <span className='token-heading-title'>Add Card</span>
                  </div>
                  <AddCard
                    onBeforeSubmit={(a: any) => {
                      console.log({ a });
                      // setStatus('progress');
                    }}
                    onSubmit={handleStrippaymentMethod}
                    onError={handleStripError}
                    onCancel={handleClose}
                  />
                </>
              ) : null}
            </div>
          </Scrollbar>
        </div>
      ) : null}
    </Model>
  );
};

export default Credits;
