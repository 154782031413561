import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from 'config';
import { useCallback } from 'react';

const stripePromise = loadStripe(STRIPE_KEY === undefined ? '' : STRIPE_KEY);

const getStripe = async () => {
  try {
    return await stripePromise;
  } catch (error) {
    return null;
  }
};
const useStripeHooks = () => {
  const onConfirmPaymentIntent = useCallback(
    async ({
      secret,
      onSuccessCallback,
      onErrorCallback,
    }: {
      secret: Record<string, any>;
      onSuccessCallback?: (...args: any) => void;
      onErrorCallback?: (...args: any) => void;
    }) => {
      const stripe = await getStripe();
      return await stripe?.confirmCardPayment(secret.client_secret).then((res) => {
        if (res.error) {
          onErrorCallback?.(res);
          throw new Error(res.error.message);
        }
        onSuccessCallback?.(res);
        return res;
      });
    },
    [],
  );

  return { onConfirmPaymentIntent, stripe: getStripe() };
};

export default useStripeHooks;
