const VerticalDotsIcon = (props: IConProps) => {
  const { width = 4, height = 16, fill = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 4 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        d='M0,14.1C0,12.9,0.9,12,2.1,12c1.1,0,2.1,0.9,2.1,2.1c0,1.1-0.9,2.1-2.1,2.1C0.9,16.1,0,15.2,0,14.1z'
        fill={fill}
      />
      <path
        d='M0,8.1C0,6.9,0.9,6,2.1,6c1.1,0,2.1,0.9,2.1,2.1c0,1.1-0.9,2.1-2.1,2.1C0.9,10.1,0,9.2,0,8.1z'
        fill={fill}
      />
      <path
        d='M0,2.1C0,0.9,0.9,0,2.1,0c1.1,0,2.1,0.9,2.1,2.1S3.2,4.1,2.1,4.1C0.9,4.1,0,3.2,0,2.1z'
        fill={fill}
      />
    </svg>
  );
};

export default VerticalDotsIcon;
