import { useWindowVirtualizer, VirtualItem, VirtualizerOptions } from '@tanstack/react-virtual';

type Props = Partial<VirtualizerOptions<any, any>> & {
  getVitems(items: VirtualItem[], virtualizer: ReturnType<typeof useWindowVirtualizer>): any;
};

const WindowVirtualizeItems = (props: Props) => {
  const {
    count = 0,
    estimateSize = (index: number) => 1330,
    overscan = 3,
    getVitems,
    ...rest
  } = props;
  const virtualizer = useWindowVirtualizer({
    count,
    estimateSize,
    overscan,
    ...rest,
    // scrollMargin: parentOffsetRef.current,
  });

  const items = virtualizer.getVirtualItems();
  const height = virtualizer.getTotalSize();
  // console.log('height', height);
  return (
    <div
      style={{
        height: `${height > 0 ? height : 0}px`,
        width: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          transform: `translateY(${
            (items[0]?.start || 0) - virtualizer?.options?.scrollMargin || 0
          }px)`,
        }}
      >
        {getVitems(items, virtualizer)}
      </div>
    </div>
  );
};

export default WindowVirtualizeItems;
