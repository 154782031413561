const GiftIcon = (props: IConProps) => {
  const { width = 22, height = 22, stroke = 'currentColor', ...rest } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <g clipPath='url(#clip0_818_158)'>
        <path
          d='M2.75 8.25001C2.75 8.0069 2.84658 7.77374 3.01849 7.60183C3.19039 7.42992 3.42355 7.33334 3.66667 7.33334H18.3333C18.5764 7.33334 18.8096 7.42992 18.9815 7.60183C19.1534 7.77374 19.25 8.0069 19.25 8.25001V10.0833C19.25 10.3265 19.1534 10.5596 18.9815 10.7315C18.8096 10.9034 18.5764 11 18.3333 11H3.66667C3.42355 11 3.19039 10.9034 3.01849 10.7315C2.84658 10.5596 2.75 10.3265 2.75 10.0833V8.25001Z'
          stroke='var(--default-text-color)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 7.33334V19.25'
          stroke='var(--default-text-color)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.4167 11V17.4167C17.4167 17.9029 17.2235 18.3692 16.8797 18.713C16.5359 19.0568 16.0696 19.25 15.5833 19.25H6.41666C5.93043 19.25 5.46412 19.0568 5.1203 18.713C4.77648 18.3692 4.58333 17.9029 4.58333 17.4167V11'
          stroke='var(--default-text-color)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.87499 7.33334C6.26721 7.33334 5.68431 7.09189 5.25454 6.66212C4.82477 6.23235 4.58333 5.64946 4.58333 5.04167C4.58333 4.43388 4.82477 3.85099 5.25454 3.42122C5.68431 2.99145 6.26721 2.75 6.87499 2.75C7.75929 2.7346 8.62585 3.16366 9.36167 3.98123C10.0975 4.79881 10.6684 5.96696 11 7.33334C11.3316 5.96696 11.9025 4.79881 12.6383 3.98123C13.3741 3.16366 14.2407 2.7346 15.125 2.75C15.7328 2.75 16.3157 2.99145 16.7454 3.42122C17.1752 3.85099 17.4167 4.43388 17.4167 5.04167C17.4167 5.64946 17.1752 6.23235 16.7454 6.66212C16.3157 7.09189 15.7328 7.33334 15.125 7.33334'
          stroke='var(--default-text-color)'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
};

export default GiftIcon;
