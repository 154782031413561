import Post from 'components/Post/Post';
import WindowVirtualizeItems from 'components/WindowVirtualizeItems';
import React from 'react';

type Props = {
  className?: string;
  children: React.ReactNode;
};
const data = [
  {
    content_post_id: 159,
    seller_account_id: 3,
    template_id: 12,

    access_type: 'non_member_all',

    has_viewed: 1,
    user_account_id: '27',
    unlockable: 0,
    unlock_price: 0,
    template_seller_account_id: 3,
    linked_gallery_id: '1',
    post_message:
      'Freshman at University of New Haven. First time out on my own. Excited to meet new people and learn more about all the things that this world has to offer.',
    post_title: 'Latest',
    media_gallery_item_id: 1,
    media_galleries_id: '1',
    sort_order: 1,
    is_cover: 1,
    all_media_items: [
      {
        image_media_item: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post1.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
        is_cover: 1,
        preview_image: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post1.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
      },
    ],
    image_media_item: {
      id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
      url: '/assets/images/signin_posts/post1.jpg',
      name: '1.jpg',
      size: 175282,
      type: 'image/jpeg',
      status: 'completed',
      originalFile: {
        path: '1.jpg',
      },
      uploadingProgress: 100,
    },
    media_type_id: 'image',
    media_duration: null,
    user_name: 'maddiepie',
    first_name: 'Madison',
    last_name: 'Ross',
    profile_thumb: {
      id: '6683d7e4-3039-4885-9afc-b4ee9d2326fc',
      url: '/assets/svgs/oc_profpic.svg',
      name: '2.jpg',
      size: 193866,
      type: 'image/jpeg',
      status: 'completed',
      originalFile: {
        path: '2.jpg',
      },
      uploadingProgress: 100,
    },
    profile_header: {
      id: 'e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6',
      url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/3-e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6.jpg',
      name: '3.jpg',
      size: 329034,
      type: 'image/jpeg',
      status: 'completed',
      uploadingProgress: 100,
    },
    image_item_count: 3,
    audio_item_count: 0,
    video_item_count: 0,
    free_like_count: 0,
    heart_like_count: 0,
    pink_flower_like_count: 0,
    bell_like_count: 0,
    red_flower_like_count: 0,
    gift_bag_like_count: 0,
    is_unlocked: 0,
    total_tip: -55,
    in_collection: 2,
    in_bookmark: 1,
    total_comments: 2,
    image_width: 640,
    image_height: 600,
  },
  {
    content_post_id: 1234,
    seller_account_id: 123,
    template_id: 1233,

    access_type: 'non_member_all',

    has_viewed: 1,
    user_account_id: '27',
    unlockable: 0,
    unlock_price: 0,
    template_seller_account_id: 3,
    linked_gallery_id: '1',
    post_message:
      'Post-apocalyptic wanderer seeking a partner to traverse the neon-lit streets of our cybernetic dreams. If you are into Cyberpunk cosplay and reading sci-fi, I’m the synth for you.',
    post_title: 'Latest',
    media_gallery_item_id: 2,
    media_galleries_id: '2',
    sort_order: 1,
    is_cover: 1,
    all_media_items: [
      {
        image_media_item: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post2.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
        is_cover: 1,
        preview_image: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post2.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
      },
    ],
    image_media_item: {
      id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
      url: '/assets/images/signin_posts/post2.jpg',
      name: '1.jpg',
      size: 175282,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    media_type_id: 'image',
    media_duration: null,
    user_name: 'brokeneclipse',
    first_name: 'Ellie',
    last_name: 'Carter',
    profile_thumb: {
      id: '6683d7e4-3039-4885-9afc-b4ee9d2326fc',
      url: '/assets/svgs/oc_profpic.svg',
      name: '2.jpg',
      size: 193866,
      type: 'image/jpeg',
      status: 'completed',
      originalFile: {
        path: '2.jpg',
      },
      uploadingProgress: 100,
    },
    profile_header: {
      id: 'e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6',
      url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/3-e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6.jpg',
      name: '3.jpg',
      size: 329034,
      type: 'image/jpeg',
      status: 'completed',
      uploadingProgress: 100,
    },
    image_item_count: 3,
    audio_item_count: 0,
    video_item_count: 0,
    free_like_count: 0,
    heart_like_count: 0,
    pink_flower_like_count: 0,
    bell_like_count: 0,
    red_flower_like_count: 0,
    gift_bag_like_count: 0,
    is_unlocked: 0,
    total_tip: -55,
    in_collection: 2,
    in_bookmark: 1,
    total_comments: 2,
    image_width: 640,
    image_height: 600,
  },
  {
    content_post_id: 534,
    seller_account_id: 1353423,
    template_id: 34,

    access_type: 'non_member_all',

    has_viewed: 1,
    user_account_id: '27',
    unlockable: 0,
    unlock_price: 0,
    template_seller_account_id: 3,
    linked_gallery_id: '1',
    post_message:
      'Yoga instructor working on my certification in Tantric Healing. Lover of travel and life. Currently spending time in Costa Rica on a journey of personal healing.',
    post_title: 'Latest',
    media_gallery_item_id: 353,
    media_galleries_id: '3434',
    sort_order: 1,
    is_cover: 1,
    all_media_items: [
      {
        image_media_item: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post3.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
        is_cover: 1,
        preview_image: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post3.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
      },
    ],
    image_media_item: {
      id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
      url: '/assets/images/signin_posts/post3.jpg',
      name: '1.jpg',
      size: 175282,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    media_type_id: 'image',
    media_duration: null,
    user_name: 'unity',
    first_name: 'Aurora',
    last_name: 'G.',
    profile_thumb: {
      id: '6683d7e4-3039-4885-9afc-b4ee9d2326fc',
      url: '/assets/svgs/oc_profpic.svg',
      name: '2.jpg',
      size: 193866,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    profile_header: {
      id: 'e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6',
      url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/3-e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6.jpg',
      name: '3.jpg',
      size: 329034,
      type: 'image/jpeg',
      status: 'completed',
      uploadingProgress: 100,
    },
    image_item_count: 3,
    audio_item_count: 0,
    video_item_count: 0,
    free_like_count: 0,
    heart_like_count: 0,
    pink_flower_like_count: 0,
    bell_like_count: 0,
    red_flower_like_count: 0,
    gift_bag_like_count: 0,
    is_unlocked: 0,
    total_tip: -55,
    in_collection: 2,
    in_bookmark: 1,
    total_comments: 2,
    image_width: 640,
    image_height: 600,
  },
  {
    content_post_id: 4645,
    seller_account_id: 454,
    template_id: 645,

    access_type: 'non_member_all',

    has_viewed: 1,
    user_account_id: '27',
    unlockable: 0,
    unlock_price: 0,
    template_seller_account_id: 3,
    linked_gallery_id: '1',
    post_message:
      'I am a Fortnight player and a big fan of classic metal. Learning to play the guitar but I suck. 😉  Hit me up if you’re down to play ! 🎮 🍑 ❤️',
    post_title: 'Latest',
    media_gallery_item_id: 353,
    media_galleries_id: '3434',
    sort_order: 1,
    is_cover: 1,
    all_media_items: [
      {
        image_media_item: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post4.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
        is_cover: 1,
        preview_image: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6284',
          url: '/assets/images/signin_posts/post4.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
      },
    ],
    image_media_item: {
      id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
      url: '/assets/images/signin_posts/post4.jpg',
      name: '1.jpg',
      size: 175282,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    media_type_id: 'image',
    media_duration: null,
    user_name: 'rubyrocks',
    first_name: 'Ruby',
    last_name: 'Hayes',
    profile_thumb: {
      id: '6683d7e4-3039-4885-9afc-b4ee9d2326fc',
      url: '/assets/svgs/oc_profpic.svg',
      name: '2.jpg',
      size: 193866,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    profile_header: {
      id: 'e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6',
      url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/3-e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6.jpg',
      name: '3.jpg',
      size: 329034,
      type: 'image/jpeg',
      status: 'completed',
      uploadingProgress: 100,
    },
    image_item_count: 3,
    audio_item_count: 0,
    video_item_count: 0,
    free_like_count: 0,
    heart_like_count: 0,
    pink_flower_like_count: 0,
    bell_like_count: 0,
    red_flower_like_count: 0,
    gift_bag_like_count: 0,
    is_unlocked: 0,
    total_tip: -55,
    in_collection: 2,
    in_bookmark: 1,
    total_comments: 2,
    image_width: 640,
    image_height: 600,
  },
  {
    content_post_id: 645,
    seller_account_id: 4645,
    template_id: 45,

    access_type: 'non_member_all',

    has_viewed: 1,
    user_account_id: '27',
    unlockable: 0,
    unlock_price: 0,
    template_seller_account_id: 3,
    linked_gallery_id: '1',
    post_message:
      'Not sure why I am doing this. My family doesn’t know I have this account. Daddy wants me to marry someone from the country club, but I want to see what else is out there.',
    post_title: 'Latest',
    media_gallery_item_id: 353,
    media_galleries_id: '3434',
    sort_order: 1,
    is_cover: 1,
    all_media_items: [
      {
        image_media_item: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
          url: '/assets/images/signin_posts/post5.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
        is_cover: 1,
        preview_image: {
          id: 'f0ce1e51-50f0-435a-b646-582c6bfc6285',
          url: '/assets/images/signin_posts/post5.jpg',
          name: '1.jpg',
          size: 175282,
          type: 'image/jpeg',
          status: 'completed',
          originalFile: {
            path: '1.jpg',
          },
          uploadingProgress: 100,
        },
      },
    ],
    image_media_item: {
      id: 'f0ce1e51-50f0-435a-b646-582c6bfc6280',
      url: '/assets/images/signin_posts/post5.jpg',
      name: '1.jpg',
      size: 175282,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    media_type_id: 'image',
    media_duration: null,
    user_name: 'trinki',
    first_name: 'Isla',
    last_name: 'Khan',
    profile_thumb: {
      id: '6683d7e4-3039-4885-9afc-b4ee9d2326fc',
      url: '/assets/svgs/oc_profpic.svg',
      name: '2.jpg',
      size: 193866,
      type: 'image/jpeg',
      status: 'completed',

      uploadingProgress: 100,
    },
    profile_header: {
      id: 'e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6',
      url: 'https://d2wc528o6oicrv.cloudfront.net/pynk6/uploads/3-e2c61b9d-c9f1-4275-ab6e-0191ca35d7f6.jpg',
      name: '3.jpg',
      size: 329034,
      type: 'image/jpeg',
      status: 'completed',
      uploadingProgress: 100,
    },
    image_item_count: 3,
    audio_item_count: 0,
    video_item_count: 0,
    free_like_count: 0,
    heart_like_count: 0,
    pink_flower_like_count: 0,
    bell_like_count: 0,
    red_flower_like_count: 0,
    gift_bag_like_count: 0,
    is_unlocked: 0,
    total_tip: -55,
    in_collection: 2,
    in_bookmark: 1,
    total_comments: 2,
    image_width: 640,
    image_height: 600,
  },
];

const index = ({ children }: Props) => {
  // const [visiblePosts, setVisiblePosts] = useState(2);
  // const handleShowMoreClick = () => {
  //   setVisiblePosts(40);
  // };
  const signinposts = data.map((item, index) => {
    return {
      ...item,
      id: index,
    };
  });
  return (
    <div className='sign_page h-100'>
      {children ? children : <div style={{ maxWidth: '50%' }}></div>}
      <div className='signup_scroll_wrapper h-100'>
        {/* <Scrollbar ref={parentRef}> */}
        <div className='signup_scroll_container'>
          <span className='block-heading'>Latest featured posts</span>
          <WindowVirtualizeItems
            count={signinposts.length}
            getVitems={(items, virtualizer) => {
              return items.map((virtualRow) => {
                const post = signinposts[virtualRow.index];

                if (!post) {
                  return null;
                }
                const { profile_thumb } = post;
                return (
                  <div
                    className='auth_section_posts'
                    key={virtualRow.key}
                    data-index={virtualRow.index}
                    ref={virtualizer.measureElement}
                  >
                    <Post
                      actions={{ allowComments: false }}
                      key={post?.content_post_id}
                      isFeedView={true}
                      isSubscribed={true}
                      // filters={{ width: 640, height: 520 }}
                      showProfileCard={!post.user_account_id}
                      // onSubscribe={conformModel}
                      isFooter={false}
                      user={{
                        last_name: post.last_name,
                        profile_thumb,
                        first_name: post.first_name,
                        user_name: post.user_name,
                      }}
                      post={post as any}
                      showActionButton
                    />
                  </div>
                );
              });
            }}
          />
        </div>
        {/* </Scrollbar> */}
        {/* {visiblePosts <= 2 && (
          <div className='text-center post-button-center'>
            <Button outline rounded onClick={handleShowMoreClick}>
              SHOW MORE
            </Button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default index;
