import { ReactNode } from 'react';
import './index.scss';
type Props = {
  children: ReactNode;
  className?: string;
};

const SkeletonWrapper = (props: Props) => {
  const { children, className = '' } = props;
  return <div className={className + ' skeletonWrapper'}>{children}</div>;
};

export default SkeletonWrapper;
