const HomeIcon = (props: IConProps) => {
  const { width = 28, height = 28, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <path
        fill={stroke}
        d='M25.119,13.381l-10.5-10.5c-0.343-0.342-0.896-0.342-1.238,0l-10.5,10.5c-0.251,0.25-0.325,0.627-0.19,0.954
	c0.136,0.327,0.455,0.54,0.809,0.54h1.458v7.291c0,0.857,0.334,1.665,0.94,2.27c0.606,0.605,1.412,0.939,2.269,0.939h11.666
	c0.844,0,1.671-0.343,2.271-0.939c0.604-0.606,0.938-1.412,0.938-2.27v-7.291H24.5c0.354,0,0.673-0.213,0.809-0.54
	C25.443,14.007,25.369,13.631,25.119,13.381z M16.625,23.625h-5.25V17.5c0-0.389,0.152-0.756,0.427-1.031
	c0.275-0.277,0.641-0.428,1.031-0.428h2.333c0.384,0,0.76,0.156,1.031,0.428c0.273,0.271,0.428,0.647,0.428,1.031V23.625z
	 M20.863,23.197c-0.271,0.273-0.646,0.428-1.029,0.428h-1.459V17.5c0-0.857-0.334-1.664-0.939-2.27
	c-0.606-0.605-1.412-0.939-2.27-0.939h-2.333c-0.857,0-1.663,0.334-2.268,0.939c-0.606,0.605-0.94,1.412-0.94,2.27v6.125H8.167
	c-0.39,0-0.756-0.152-1.032-0.428c-0.275-0.275-0.427-0.641-0.427-1.031V14c0-0.483-0.392-0.875-0.875-0.875H5.612L14,4.737
	l8.389,8.388h-0.223c-0.482,0-0.875,0.392-0.875,0.875c0,0.002,0.001,0.003,0.001,0.005v8.161
	C21.292,22.55,21.137,22.926,20.863,23.197z'
      />
    </svg>
  );
};

export default HomeIcon;
