const Lock = (props: IConProps) => {
  const { width = 30, height = 30, stroke = 'currentColor', ...rest } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1_50)'>
        <path
          d='M15 3.75C24 3.75 26.25 6 26.25 15C26.25 24 24 26.25 15 26.25C6 26.25 3.75 24 3.75 15C3.75 6 6 3.75 15 3.75Z'
          stroke={stroke}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 15C10 14.6685 10.1317 14.3505 10.3661 14.1161C10.6005 13.8817 10.9185 13.75 11.25 13.75H18.75C19.0815 13.75 19.3995 13.8817 19.6339 14.1161C19.8683 14.3505 20 14.6685 20 15V18.75C20 19.0815 19.8683 19.3995 19.6339 19.6339C19.3995 19.8683 19.0815 20 18.75 20H11.25C10.9185 20 10.6005 19.8683 10.3661 19.6339C10.1317 19.3995 10 19.0815 10 18.75V15Z'
          stroke={stroke}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.5 13.75V11.25C12.5 10.587 12.7634 9.95107 13.2322 9.48223C13.7011 9.01339 14.337 8.75 15 8.75C15.663 8.75 16.2989 9.01339 16.7678 9.48223C17.2366 9.95107 17.5 10.587 17.5 11.25V13.75'
          stroke={stroke}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1_50'>
          <rect width='30' height='30' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Lock;
