import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

import { createSelectorHooks } from 'auto-zustand-selectors-hook';
import { resetters } from 'store';
type IGallary = {
  type?: 'post' | 'message';
  galleryId?: number;
  mediaId?: number;
  // Added postId
  postId?: number;
  callback?: (...args: any) => void;
  mediaUser?: IMediaUser;
  mediaItems?: any[];
  isOpen?: boolean;
};
type State = {
  galleryId?: number;
  mediaId?: number;
  // Added postId
  postId?: number;
  type?: 'post' | 'message';
  mediaUser?: IMediaUser;
  mediaItems?: IPost[];
  callback?: (...args: any) => void;
  setGalleryId: (props: IGallary) => void;
  resetGallery: () => void;
  isOpen?: boolean;
};

const usePostSlider = create<State>()(
  devtools(
    persist(
      immer<State>((set) => {
        resetters.push(() =>
          set(
            {
              galleryId: undefined,
              mediaId: undefined,
              callback: undefined,
              mediaUser: undefined,
              mediaItems: [],
              isOpen: false,
            },
            true,
          ),
        );
        return {
          galleryId: undefined,
          mediaId: undefined,
          mediaItems: [],
          isOpen: false,
          setGalleryId: (props) => {
            set({ ...props });
          },

          resetGallery: () => {
            set({
              mediaId: undefined,
              galleryId: undefined,
              type: undefined,
              callback: undefined,
              mediaUser: undefined,
              mediaItems: [],
              isOpen: false,
            });
          },
        };
      }),
      {
        name: 'gallery',
        storage: createJSONStorage(() => sessionStorage),
        partialize: (state) => ({
          gallery: {
            galleryId: state?.galleryId,
            mediaId: state?.mediaId,
            callback: state?.callback,
            mediaUser: state?.mediaUser,
            isOpen: state.isOpen,
          },
        }),
      },
    ),
  ),
);

export const usePostSwiperSlider = createSelectorHooks<State>(usePostSlider);
